import { GetDefaultHttpOptions } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import {
    AllowedUserActionOnScreening,
    ICreatePublicTrustNominationRequest,
    IPublicTrust,
    IPublicTrustResults,
    UploadPublicTrustColumns,
} from 'components/screening/public-trust/public-trust-screening-result';
import { IFiltersContext } from 'contexts/filters-context';
import { ScreeningRequestTypes, StateName } from 'components/screening/common/common-constants';
import { IEmployee } from 'clients/employee-client';
import config from 'environments/environment';
import {
    SuitabilityAgencies,
    SuitabilityLevels,
    SuitabilityLevelsV2,
} from 'components/personnel-profile/suitability/profile-suitability-types';

const nominationConfig = config.nominationServiceConfig;
class PublicTrustScreeningClient {
    static get publictrustBaseUrl(): string {
        return `${nominationConfig.baseUrl}${nominationConfig.publicTrustEndpoint}`;
    }

    static async getPublicTrustScreeningById(
        authContext: IAuthContext,
        id: string,
    ): Promise<IPublicTrust> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/${id}`;
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Public Trust Record with the specified ID: ${id} was not found`;
            default:
                throw result;
        }
    }

    static async getPagedScreenings(
        authContext: IAuthContext,
        filter: IFiltersContext,
        continuationToken?: string,
    ): Promise<IPublicTrustResults> {
        const urlParams = new URLSearchParams();
        urlParams.append('resultCount', nominationConfig.screeningsResultCount.toString());
        if (filter.userType) {
            urlParams.append('userType', filter.userType);
        }
        if (filter.personnelId) {
            urlParams.append('personnelId', filter.personnelId);
        }
        return this._getScreenings(authContext, urlParams, continuationToken);
    }

    /*
    Changed return from this._getScreenings() to PublicTrustScreeningClient._getScreenings() because in personnel-profile-page.tsx,
    the personnel-profile-screening-table component receives a queryMethod parameter which is of type Function.
    */
    static async getPagedScreeningsByEmployee(
        authContext: IAuthContext,
        employee: IEmployee,
        pageSize: number,
        userType?: string,
        continuationToken?: string,
    ): Promise<IPublicTrustResults> {
        const urlParams = new URLSearchParams();
        urlParams.append('resultCount', `${pageSize}`);
        urlParams.append('personnelId', employee.id);

        if (userType) {
            urlParams.append('userType', userType);
        }

        return PublicTrustScreeningClient._getScreenings(authContext, urlParams, continuationToken);
    }

    private static async _getScreenings(
        authContext: IAuthContext,
        urlParam: URLSearchParams,
        continuationToken?: string,
    ): Promise<IPublicTrustResults> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url = `${this.publictrustBaseUrl}?${urlParam}`;
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Public Trust Records was not found`;
            default:
                throw result;
        }
    }

    static async getScreeningRecordExcelDataByRequests(
        authContext: IAuthContext,
        excelRequests: IScreeningRecordPublicTrustExcelRequest[],
        userType?: string,
        isMyOrg?: boolean,
        correlationId?: string,
    ): Promise<IScreeningRecordPublicTrustExcelResponse[]> {
        const urlParams = new URLSearchParams();
        if (userType) {
            urlParams.append('userType', userType);
        }
        if (isMyOrg) {
            urlParams.append('isMyOrg', `${isMyOrg}`);
        }
        if (!!correlationId) {
            urlParams.append('correlationId', correlationId);
        }
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(excelRequests);

        const url = `${
            this.publictrustBaseUrl
        }/screeningrecordpublictrustexceldata?${urlParams.toString()}`;

        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Public Trust Records was not found`;
            default:
                throw result;
        }
    }

    static async updateScreening(
        authContext: IAuthContext,
        screening: IPublicTrust,
    ): Promise<IPublicTrust> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/${screening.id}/Update`;
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(screening);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to update a Public Trust Records`;
            default:
                throw result;
        }
    }

    static async nomineeApproveRejectNomination(
        authContext: IAuthContext,
        screeningId: string,
        nomineeApproved: boolean,
    ): Promise<IPublicTrust> {
        return PublicTrustScreeningClient._userActionUpdateScreeningRecord(
            authContext,
            screeningId,
            nomineeApproved
                ? AllowedUserActionOnScreening.NomineeApprove
                : AllowedUserActionOnScreening.NomineeReject,
        );
    }

    static async contractOwnerApproveRejectNomination(
        authContext: IAuthContext,
        screeningId: string,
        contractOwnerApproved: boolean,
    ): Promise<IPublicTrust> {
        return PublicTrustScreeningClient._userActionUpdateScreeningRecord(
            authContext,
            screeningId,
            contractOwnerApproved
                ? AllowedUserActionOnScreening.ContractOwnerAccept
                : AllowedUserActionOnScreening.ContractOwnerDeny,
        );
    }

    private static async _userActionUpdateScreeningRecord(
        authContext: IAuthContext,
        screeningId: string,
        userAction: AllowedUserActionOnScreening,
    ): Promise<IPublicTrust> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/${screeningId}/${userAction}`;
        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            default:
                throw await result.text();
        }
    }

    static async createNomination(
        authContext: IAuthContext,
        request: ICreatePublicTrustNominationRequest,
        agency: string,
    ): Promise<IPublicTrust> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/${agency}`;
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(request);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 201:
                return result.json();
            default:
                throw await result.text();
        }
    }

    static async getUserTypes(authContext: IAuthContext): Promise<string[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/usertypes`;
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to get User Type`;
            default:
                throw result;
        }
    }

    static async updateProcessOwner(
        authContext: IAuthContext,
        publicTrustScreeningId: string,
        processOwnerId: string | undefined,
    ): Promise<IPublicTrust> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const newProcessOwnerId = processOwnerId ? processOwnerId : 'unset';
        const url = `${this.publictrustBaseUrl}/${publicTrustScreeningId}/processowner/${newProcessOwnerId}`;
        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to update Process owner`;
            default:
                throw result;
        }
    }

    static async updateScreeningState(
        authContext: IAuthContext,
        id: string,
        stateName: StateName,
    ): Promise<IPublicTrust> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/${id}/ChangeState/${stateName}`;
        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to update Screening stateName to ${stateName}`;
            default:
                throw result;
        }
    }

    static async uploadPublicTrustRecords(
        authContext: IAuthContext,
        request: IScreeningRecordPublicTrustUploadRequest[],
    ): Promise<string | undefined> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.publictrustBaseUrl}/upload`;

        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(request);

        const result = await fetch(url, httpOptions);

        switch (result.status) {
            case 200:
                return result.json();
            case 204:
                return;
            default:
                const text = await result.text();
                console.error(text);
                let json;
                try {
                    json = JSON.parse(text);
                } catch {}
                throw new Error(json?.message || result);
        }
    }
}

export default PublicTrustScreeningClient;

// TODO consolidate UploadPublicTrustRecordResult and UploadEquipmentRecordResult and UploadSuitabilityRecordResult into one common type
// This type can be replaced with type BulkUploadResult from upload-utils.
export type UploadPublicTrustRecordResult = {
    // This record has many more fields, but at this moment (Jun,15,2023)
    // the code only needs to access "Upload status".
    [UploadPublicTrustColumns.uploadStatus]: string;
};

// TODO consolidate UploadStatusEnum and EquipmentUploadStatusEnum and UploadSuitabilityRecordResult into one common enum
export type UploadStatusEnum = 'Success' | 'Failed' | 'Skipped';

export interface IScreeningRecordPublicTrustUploadRequest {
    businessJustification: string;
    contractId: string;
    id?: string;
    isPIVRequired?: boolean;
    personnelId: string;
    processOwnerId?: string;
    publicTrustAgency: SuitabilityAgencies;
    requestType: ScreeningRequestTypes;
    suitabilityLevel: SuitabilityLevelsV2;
    stateName: StateName;
    uploadStatus?: UploadStatusEnum;
}

export interface IScreeningRecordPublicTrustExcelRequest {
    screeningRecordId: string;
    nominatedById?: string;
    personnelId: string;
    ProcessOwnerId?: string;
}

export interface IScreeningRecordPublicTrustExcelResponse {
    action: string;
    contractId?: string;
    contractCustomer: string;
    contractProjectName: string;
    employeeStatus: string;
    employeeTitle: string;
    geographicLocation: string;
    l1: string;
    l2: string;
    l3: string;
    l4: string;
    l5: string;
    l6: string;
    nominatedBy: string;
    nominatedOn: number;
    officeLocation: string;
    organization: string;
    personnelAlias: string;
    personnelFirstName: string;
    personnelLastName: string;
    personnelMiddleName?: string;
    personnelId: string;
    personStatusCode: string;
    processOwner: string;
    reportsTo: string;
    requestAgency: SuitabilityAgencies;
    suitabilityLevel: SuitabilityLevels;
    requestType: ScreeningRequestTypes;
    screeningRecordId: string;
    status: StateName;
    statusChangedOn: number;
    subStatus: string;
    terminationDateUTCMilliseconds: number;
}
