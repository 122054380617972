import React from 'react';
import { TableCell } from 'components/common/table';
// eslint-disable-next-line no-restricted-imports -- for @fluentui/react/Link import.
import { ActionButton, IColumn, Stack, Link } from '@fluentui/react';
import { xLargeMaxWidthCoeff, IconNames } from 'assets/constants/global-constants';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import { IVisitorProfile } from 'clients/visitor-client';
import VisitorProfileModalActionButton, {
    VisitorProfileModeEnum,
} from 'components/visitors/visitor-profile-modal-action-button';
import { lastColumnStylesButton } from 'assets/styles/list-styles';
import { visitorProfileUrl } from 'components/visitors/breadcrumbs';
import EmployeeBasicHoverCard from 'components/common/employee/employee-basic-hover-card';
import { ISortableColumns } from 'utils/sort-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';

interface IEligibilitysColumns extends ISortableColumns {
    canModifyVisitorProfile: boolean;
    onUpdateVisitorRecord: (visitor: IVisitorProfile) => void;
}

export enum VisitorsColumnNames {
    visitorId = 'Visitor Id',
    firstName = 'First Name',
    middleName = 'Middle Name',
    lastName = 'Last Name',
    email = 'Email',
    company = 'Company',
    title = 'Title',
    sponsor = 'Sponsor',
    actions = 'Actions',
}

export function TableColumnsVisitors(params: IEligibilitysColumns): IColumn[] {
    const isCoreGuestsEnabled = useFeatureFlag(FeatureFlagKeys.guestsCore).enabled;
    const columnWidths = {
        itemNumber: 20,
        visitorID: 40,
        firstName: 50,
        middleName: 90,
        lastName: 60,
        email: 100,
        company: 80,
        title: 80,
        sponsor: 180,
        actions: 120,
    };

    const columns: IColumn[] = [
        {
            key: VisitorsColumnNames.visitorId,
            name: VisitorsColumnNames.visitorId,
            ariaLabel: VisitorsColumnNames.visitorId,
            minWidth: columnWidths.visitorID,
            maxWidth: columnWidths.visitorID * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === VisitorsColumnNames.visitorId,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.visitorId);
            },
            onRender: (row: IVisitorProfile): JSX.Element => (
                <TableCell>
                    <EllipsisTextCss text={row.id} />
                </TableCell>
            ),
        },
        {
            key: VisitorsColumnNames.firstName,
            name: VisitorsColumnNames.firstName,
            ariaLabel: VisitorsColumnNames.firstName,
            minWidth: columnWidths.firstName,
            maxWidth: columnWidths.firstName * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === VisitorsColumnNames.firstName,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.firstName);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.firstName} />
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.middleName,
            name: VisitorsColumnNames.middleName,
            ariaLabel: VisitorsColumnNames.middleName,
            minWidth: columnWidths.middleName,
            maxWidth: columnWidths.middleName,
            isSorted: params.sortColumn === VisitorsColumnNames.middleName,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.middleName);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.middleName} />
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.lastName,
            name: VisitorsColumnNames.lastName,
            ariaLabel: VisitorsColumnNames.lastName,
            minWidth: columnWidths.lastName,
            maxWidth: columnWidths.lastName * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === VisitorsColumnNames.lastName,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.lastName);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.lastName} />
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.email,
            name: VisitorsColumnNames.email,
            ariaLabel: VisitorsColumnNames.email,
            minWidth: columnWidths.email,
            maxWidth: columnWidths.email * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === VisitorsColumnNames.email,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.email);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.email} />
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.company,
            name: VisitorsColumnNames.company,
            ariaLabel: VisitorsColumnNames.company,
            minWidth: columnWidths.company,
            maxWidth: columnWidths.company * xLargeMaxWidthCoeff,
            isSorted: params.sortColumn === VisitorsColumnNames.company,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.company);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.company} />
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.title,
            name: VisitorsColumnNames.title,
            ariaLabel: VisitorsColumnNames.title,
            minWidth: columnWidths.title,
            maxWidth: undefined,
            isSorted: params.sortColumn === VisitorsColumnNames.title,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.title);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisTextCss text={row.title} />
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.sponsor,
            name: VisitorsColumnNames.sponsor,
            ariaLabel: VisitorsColumnNames.sponsor,
            minWidth: columnWidths.sponsor,
            maxWidth: params.canModifyVisitorProfile ? undefined : columnWidths.sponsor,
            isSorted: params.sortColumn === VisitorsColumnNames.sponsor,
            isSortedDescending: !params.sortAscending,
            onColumnClick: (): void => {
                params.sortColumnHandler(VisitorsColumnNames.sponsor);
            },
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        {isCoreGuestsEnabled ? (
                            <CoreEmployeeHoverCardFromPrincipalId principalId={row.sponsor} />
                        ) : (
                            <EmployeeBasicHoverCard personnelId={row.sponsor} />
                        )}
                    </TableCell>
                );
            },
        },
        {
            key: VisitorsColumnNames.actions,
            name: VisitorsColumnNames.actions,
            ariaLabel: VisitorsColumnNames.actions,
            minWidth: columnWidths.actions,
            maxWidth: columnWidths.actions,
            ...lastColumnStylesButton,
            onRender: (row: IVisitorProfile): JSX.Element => {
                return (
                    <TableCell>
                        <Stack horizontal>
                            <Link href={visitorProfileUrl(row.id)}>
                                <ActionButton iconProps={{ iconName: IconNames.OpenInNewTab }}>
                                    View
                                </ActionButton>
                            </Link>
                            {params.canModifyVisitorProfile && (
                                <VisitorProfileModalActionButton
                                    mode={VisitorProfileModeEnum.update}
                                    visitor={row}
                                    onVisitorUpdated={params.onUpdateVisitorRecord}
                                />
                            )}
                        </Stack>
                    </TableCell>
                );
            },
        },
    ];

    return columns;
}
