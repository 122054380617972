import React, { ReactNode } from 'react';
import { mergeStyleSets, FontSizes } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import EllipsisText from 'components/common/ellipsis-text';
import { faintBlue } from 'assets/constants/global-colors';
import { etiquetteTextColor, etiquetteTextColorDark } from 'assets/styles/global-styles';

interface IEtiquette {
    label: string;
    backgroundColor: string;
    maxLength?: number;
    useDarkTextColor?: boolean;
}

export interface IContainerWithEtiquettesProps {
    leftEtiquetteLabel: string;
    rightEtiquette?: IEtiquette;
    secondRightEtiquette?: IEtiquette;
    bottomInfo?: React.ReactNode;
    bottomLeftInfo?: React.ReactNode;
    children?: ReactNode;
}

const ContainerWithEtiquettes: React.FunctionComponent<IContainerWithEtiquettesProps> = ({
    leftEtiquetteLabel,
    rightEtiquette,
    secondRightEtiquette,
    bottomInfo,
    bottomLeftInfo,
    children,
}) => {
    const styles = mergeStyleSets({
        main: {
            backgroundColor: NeutralColors.white,
            transition: 'box-shadow 300ms ease 0s',
            boxShadow:
                'rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px',
            position: 'relative',
            borderRadius: 2,
            padding: 15,
        },
        legend: {
            fontWeight: 500,
            fontSize: FontSizes.medium,
            position: 'absolute',
            left: '10px',
            top: '-10px',
        },
        etiquetteContainer: {
            position: 'absolute',
            right: '7px',
            top: '-10px',
        },
        rightLegend: {
            padding: '4px 7px 4px',
            marginRight: secondRightEtiquette ? '7px' : 'unset',
            color: rightEtiquette?.useDarkTextColor ? etiquetteTextColorDark : etiquetteTextColor,
            fontSize: 13,
            borderRadius: 1.5,
            backgroundColor: rightEtiquette?.backgroundColor,
        },
        secondRightLegend: {
            padding: '4px 7px 4px',
            color: secondRightEtiquette?.useDarkTextColor
                ? etiquetteTextColorDark
                : etiquetteTextColor,
            fontSize: 13,
            borderRadius: 1.5,
            backgroundColor: secondRightEtiquette?.backgroundColor,
        },
        // TODO: consolidate cardActions and cardAction styling in one file, this duplicated from the 'employee-card.tsx' file
        cardActions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '50px',
            padding: '15px',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.07)', //requested by the design team
        },
        cardAction: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            selectors: {
                '& a': {
                    color: 'rgb(50, 49, 48)',
                },
                '& a:hover': {
                    color: faintBlue,
                },
            },
        },
    });
    return (
        // set padding to 0 if bottomInfo is provided
        <div className={styles.main} style={bottomInfo || bottomLeftInfo ? { padding: '0' } : {}}>
            <span className={styles.legend}>{leftEtiquetteLabel}</span>
            <span className={styles.etiquetteContainer}>
                {rightEtiquette ? (
                    <span className={styles.rightLegend}>
                        <EllipsisText
                            text={rightEtiquette.label}
                            textLengthBeforeEllipsis={rightEtiquette.maxLength ?? 50}
                        />
                    </span>
                ) : (
                    ''
                )}
                {secondRightEtiquette ? (
                    <span className={styles.secondRightLegend}>
                        <EllipsisText
                            text={secondRightEtiquette.label}
                            textLengthBeforeEllipsis={secondRightEtiquette.maxLength ?? 50}
                        />
                    </span>
                ) : (
                    ''
                )}
            </span>
            {children}
            {bottomInfo || bottomLeftInfo ? (
                <div className={styles.cardActions}>
                    <div className={styles.cardAction}>{bottomLeftInfo}</div>
                    <div className={styles.cardAction}>{bottomInfo}</div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
export default ContainerWithEtiquettes;
