import React, { useContext, useEffect, useState } from 'react';
import {
    ActionButton,
    Checkbox,
    Icon,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
    DatePicker,
    IContextualMenuProps,
    TooltipHost,
    Dialog,
    DialogFooter,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react';
import EmployeeClient, {
    IEmployeeEditableInfo,
    IEmployeeEditableRequest,
    IEmployeeWithEditableData,
    SearchEmployeeStatus,
    AllEmployeeEditableFields,
    MaskedEmployeeEditableFields,
} from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import { dateToDateStringFormat } from 'utils/time-utils';
import EllipsisText from 'components/common/ellipsis-text';
import { ITheme, SharedColors } from '@fluentui/theme';
import { UserContext } from 'contexts/user-context';
import { IconNames } from 'assets/constants/global-constants';
import { doNothing } from 'utils/misc-utils';
import { isGUID } from 'utils/string-utils';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import { fluentTooltipStyleCalloutProps } from 'assets/styles/global-styles';

export type UpdateKeyValueType = <K extends keyof IEmployeeEditableInfo>(
    key: K,
    value: IEmployeeEditableInfo[K],
) => void;

const DISPLAY_TIMEOUT = 120000;
const DEFAULT_DISPLAY_VALUE = 'No data to display';

export interface EditableEmployeeDataProps {
    employee: IEmployeeWithEditableData;
    editableData: IEmployeeEditableInfo | undefined;
    secureField: AllEmployeeEditableFields;
    placeholder?: string;
    regex?: string | RegExp;
    errorMessage?: string;
    dialogTitle: string;
    dialogLabel: string;
    type: 'Text' | 'Date' | 'Checkbox';
    updateKeyValue: UpdateKeyValueType;
    defaultMask: string;
    defaultDisplayValue?: string;
    isEditDisabled?: boolean;
}

export default function EditableEmployeeData(props: EditableEmployeeDataProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [value, setValue] = useState<string | undefined>(getPropsEditableData());
    const [displayedValue, setDisplayedValue] = useState<string | undefined>(
        getPropsEditableData(),
    );
    const [errorMsg, setErrorMsg] = useState<JSX.Element>();
    const [isEditDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [canShowValue, setCanShowValue] = useState<boolean>(false);
    const [displayTimer, setDisplayTimer] = useState<NodeJS.Timeout | null>(null);
    const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState<boolean>(false);

    async function openEditDialog(): Promise<void> {
        setErrorMsg(undefined);
        await getEmployeeDataField();
        setCanShowValue(true);
        resetDisplayTimer();
        setEditDialogOpen(true);
        setIsPrimaryButtonDisabled(false);
    }

    function isPrivilegedUser(): boolean {
        return (
            userContext.hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
            userContext.hasPublicTrustUserType(UsGovScreeningUserType.NST)
        );
    }

    function isMaskedField(): boolean {
        return Object.values(MaskedEmployeeEditableFields).includes(
            (props.secureField as unknown) as MaskedEmployeeEditableFields,
        );
    }

    function isDataProvidedByHR(): boolean {
        if (props.employee.editablePropertiesProvidedByHR) {
            return (
                props.employee.editablePropertiesProvidedByHR.findIndex(
                    (item) => props.secureField.toLowerCase() === item.toLowerCase(),
                ) > -1
            );
        }
        return false;
    }

    function closeEditDialog(): void {
        setErrorMsg(undefined);
        setEditDialogOpen(false);
    }

    useEffect(() => {
        if (props.editableData && props.secureField) {
            setValue(getPropsEditableData());
            setDisplayedValue(getPropsEditableData());
        }
    }, [props.editableData, props.secureField]);

    async function updateKeyValue(): Promise<void> {
        if (!props.regex || !value || value?.match(props.regex)) {
            try {
                if (
                    !value &&
                    (props.secureField === AllEmployeeEditableFields.firstName ||
                        props.secureField === AllEmployeeEditableFields.lastName ||
                        props.secureField === AllEmployeeEditableFields.nationalIdNumber ||
                        props.secureField === AllEmployeeEditableFields.personalEmail)
                ) {
                    setErrorMsg(<span>You must have a value for {props.dialogLabel} </span>);
                } else {
                    let results = [];

                    if (
                        props.secureField === AllEmployeeEditableFields.nationalIdNumber ||
                        props.secureField === AllEmployeeEditableFields.personalEmail
                    ) {
                        results = await EmployeeClient.searchPrehires(value!, authContext);
                    }

                    if (results.length > 0) {
                        setErrorMsg(
                            <span>
                                Duplicate entry. An employee profile with that {props.dialogLabel}{' '}
                                already exists
                            </span>,
                        );
                    } else {
                        const employeeEditableRequest: IEmployeeEditableRequest = {
                            id: props.employee.data?.id,
                            alias: props.employee.data.alias,
                            requestBy: userContext.employeeRecord.id,
                            employeeStatus: props.employee.employeeStatus
                                ? props.employee.employeeStatus
                                : SearchEmployeeStatus.active,
                            employeeEditableInfo: { [props.secureField]: value },
                        };

                        const editableFieldsFromUpsert = await EmployeeClient.updateEditableEmployeeData(
                            authContext,
                            employeeEditableRequest,
                        );

                        if (editableFieldsFromUpsert.id) {
                            props.updateKeyValue(props.secureField, value);
                            await getEmployeeDataField();
                            setCanShowValue(true);
                            resetDisplayTimer();

                            closeEditDialog();
                        } else {
                            setIsPrimaryButtonDisabled(true);
                            setErrorMsg(
                                <span>
                                    This cleared workforce user has the restricted profile attribute
                                    limiting employee data management.
                                    <p>
                                        <strong>Questions?</strong> Contact the Personnel team at
                                        <a href='mailto:personnel_pm@microsoft.com'>
                                            personnel_pm@microsoft.com
                                        </a>
                                    </p>
                                </span>,
                            );
                        }
                    }
                }
            } catch (e) {
                setErrorMsg(<span>Error trying to update {props.dialogLabel}</span>);
            }
        } else {
            setErrorMsg(<span>{props.errorMessage}</span>);
        }
    }

    function getPropsEditableData(): string | undefined {
        if (props.editableData) {
            return props.editableData[props.secureField]
                ? `${props.editableData[props.secureField]}`
                : props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE;
        }
        return props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE;
    }

    function getDisplayedValue(): string | undefined {
        if (isMaskedField()) {
            if (canShowValue) {
                return displayedValue ?? props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE;
            } else {
                return props.defaultMask;
            }
        }
        return displayedValue ?? props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE;
    }

    function displayEditInput(): JSX.Element {
        switch (props.type) {
            case 'Text':
                return (
                    <TextField
                        styles={{
                            root: { marginBottom: '12px' },
                            fieldGroup: {
                                border: '1px solid rgba(209, 209, 209, 1)',
                                borderRadius: '4px',
                            },
                        }}
                        ariaLabel={props.dialogLabel}
                        defaultValue={
                            value === (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE)
                                ? undefined
                                : value
                        }
                        placeholder={props.placeholder}
                        onChange={(event, newValue): void => {
                            setValue(newValue);
                            resetDisplayTimer();
                        }}
                    />
                );
            case 'Date':
                return (
                    <DatePicker
                        ariaLabel={props.dialogLabel}
                        value={
                            value !== undefined &&
                            value !== (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE)
                                ? new Date(value)
                                : undefined
                        }
                        placeholder={props.placeholder}
                        allowTextInput={true}
                        onSelectDate={(date): void => {
                            setValue(dateToDateStringFormat(date));
                            resetDisplayTimer();
                        }}
                    />
                );
            case 'Checkbox':
                return (
                    <Checkbox
                        ariaLabel={props.dialogLabel}
                        onChange={(ev, checked: boolean | undefined): void => {
                            setValue(`${checked}`);
                            resetDisplayTimer();
                        }}
                        checked={isTrue(value)}
                    />
                );
        }
    }

    const getEmployeeDataField = async (): Promise<void> => {
        if (isDataProvidedByHR()) {
            setDisplayedValue(value);
        } else {
            const editableEmployeeResult = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                authContext,
                props.employee.data.id,
                [props.secureField],
            );
            const val = editableEmployeeResult.employeeEditableInfo[props.secureField]
                ? `${editableEmployeeResult.employeeEditableInfo[props.secureField]}`
                : props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE;
            setValue(val);
            setDisplayedValue(val);
        }
    };

    const resetDisplayTimer = (): void => {
        if (displayTimer) {
            clearTimeout(displayTimer);
        }

        const newEditTimer = setTimeout(() => {
            setCanShowValue(false);
            setEditDialogOpen(false);
        }, DISPLAY_TIMEOUT);
        setDisplayTimer(newEditTimer);
    };

    useEffect(() => {
        // Cleanup display timer on component unmount
        return () => {
            if (displayTimer) {
                clearTimeout(displayTimer);
            }
        };
    }, [displayTimer]);

    function displayEditDialog(): JSX.Element {
        const contextualMenuItems: IContextualMenuProps = {
            items: [],
        };

        if (isMaskedField()) {
            contextualMenuItems.items.push({
                key: 'View',
                text: 'View',
                iconProps: { iconName: IconNames.View },
                onClick: (): void => {
                    (async (): Promise<void> => {
                        await getEmployeeDataField();
                        setCanShowValue(true);
                        resetDisplayTimer();
                    })().catch((error) => {
                        doNothing();
                    });
                },
            });
        }

        if (!props.isEditDisabled && isPrivilegedUser()) {
            contextualMenuItems.items.push({
                key: 'edit',
                text: 'Edit',
                iconProps: { iconName: IconNames.EditNoFill },
                onClick: (): void => {
                    openEditDialog();
                },
                disabled: isDataProvidedByHR(),
            });
        }

        const showActionsButton = isGUID(props.employee.data.id) || isMaskedField();

        return (
            <>
                {showActionsButton && (
                    <TooltipHost
                        directionalHint={2}
                        calloutProps={mergedCalloutProps}
                        styles={{ root: { width: 25 } }}
                        content='Actions'>
                        <ActionButton
                            iconProps={{ iconName: 'More' }}
                            ariaLabel='Actions'
                            menuProps={{
                                items: contextualMenuItems.items,
                                calloutProps: {
                                    styles: { root: { transform: 'translate(-90px) !important' } },
                                    calloutWidth: 114,
                                },
                            }}
                            styles={{
                                iconHovered: { color: 'black', fontWeight: 'bold' },
                                menuIcon: { display: 'none' },
                                icon: { color: 'black' },
                                root: { height: '20px' },
                            }}
                            className={styles.contextMenu}
                        />
                    </TooltipHost>
                )}

                {isEditDialogOpen && (
                    <Dialog
                        hidden={!isEditDialogOpen}
                        dialogContentProps={{
                            onDismiss: closeEditDialog,
                            title: props.dialogTitle,
                            showCloseButton: true,
                        }}
                        modalProps={{
                            styles: {
                                main: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: props.type !== 'Checkbox' ? '600px !important' : 'auto',
                                    maxWidth: '600px !important',
                                    borderRadius: '8px',
                                    background: 'var(--Light-Background-Background-1, #FFF)',
                                    boxShadow:
                                        '0px 32px 64px 0px rgba(0, 0, 0, 0.24), 0px 0px 8px 0px rgba(0, 0, 0, 0.20)',
                                },
                            },
                            className: `${styles.dialogTitleStyle}`,
                        }}>
                        <Stack>
                            <ActionButton
                                // The purpose of this button is to catch the initial propagated onClick event
                                // when the 'edit' button is clicked to open up this modal.
                                // Otherwise the first stack item's onChange event will trigger e.g. in this case
                                // the dropdown will select and display the top item from the dropdown selection
                                style={{ maxHeight: 0, maxWidth: 0 }}
                                hidden={true}
                                onClick={(event): void => {
                                    event.stopPropagation();
                                }}
                            />
                            <Stack.Item>{displayEditInput()}</Stack.Item>
                        </Stack>

                        {errorMsg && (
                            <Stack.Item>
                                <div style={{ marginTop: '5px' }}></div>
                                <MessageBar
                                    messageBarType={MessageBarType.error}
                                    isMultiline={true}
                                    dismissButtonAriaLabel='Close'>
                                    {errorMsg}
                                </MessageBar>
                            </Stack.Item>
                        )}
                        <DialogFooter
                            styles={{
                                actions: {
                                    marginTop: '8px',
                                },
                            }}>
                            <PrimaryButton
                                text={'Update'}
                                styles={{
                                    root: {
                                        height: '32px',
                                        width: '96px',
                                        minWidth: '0px',
                                        borderRadius: '4px',
                                    },
                                }}
                                onClick={updateKeyValue}
                                disabled={isPrimaryButtonDisabled}
                            />

                            <DefaultButton
                                text='Cancel'
                                styles={{
                                    root: {
                                        height: '32px',
                                        width: '96px',
                                        minWidth: '0px',
                                        borderRadius: '4px',
                                        border: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                                        background:
                                            '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                                    },
                                }}
                                onClick={closeEditDialog}
                            />
                        </DialogFooter>
                    </Dialog>
                )}
            </>
        );
    }

    function displayValueAsText(): JSX.Element {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: '175px',
                        color: `${
                            (getDisplayedValue() === DEFAULT_DISPLAY_VALUE && canShowValue) ||
                            (getDisplayedValue() === DEFAULT_DISPLAY_VALUE && !isMaskedField())
                                ? '#BDBDBD'
                                : 'black'
                        }`,
                    }}>
                    <EllipsisText
                        text={getDisplayedValue()}
                        textLengthBeforeEllipsis={
                            value === (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE) ? 20 : 16
                        }
                        tooltipCalloutProps={fluentTooltipStyleCalloutProps}
                    />
                </div>
                {displayEditDialog()}
            </div>
        );
    }

    function displayValueAsTextForDates(): JSX.Element {
        const getText = (): string | undefined => {
            const valueToDisplay = getDisplayedValue();
            const date = new Date(valueToDisplay as string);
            if (isMaskedField()) {
                if (canShowValue) {
                    return isNaN(date.getTime())
                        ? props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE
                        : date.toDateString();
                } else {
                    return props.defaultMask;
                }
            } else {
                return isNaN(date.getTime())
                    ? props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE
                    : date.toDateString();
            }
        };
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: '175px',
                        color: `${
                            (getDisplayedValue() ===
                                (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE) &&
                                canShowValue) ||
                            (getDisplayedValue() ===
                                (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE) &&
                                !isMaskedField())
                                ? '#BDBDBD'
                                : 'black'
                        }`,
                    }}>
                    <EllipsisText
                        text={getText()}
                        textLengthBeforeEllipsis={
                            value === (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE) ? 20 : 16
                        }
                        tooltipCalloutProps={fluentTooltipStyleCalloutProps}
                    />
                </div>
                {displayEditDialog()}
            </div>
        );
    }

    function displayValueAsCheckBox(): JSX.Element {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '175px' }}>
                    {(isMaskedField() && canShowValue) || !isMaskedField() ? (
                        <div className={styles.multivalue}>
                            <div>{renderCheckBox()}</div>
                            <div>
                                <span
                                    style={{
                                        opacity: isTrue(getDisplayedValue()) ? undefined : 0.5,
                                    }}>
                                    {props.dialogLabel}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <EllipsisText
                            text={props.defaultMask}
                            textLengthBeforeEllipsis={
                                value === (props.defaultDisplayValue ?? DEFAULT_DISPLAY_VALUE)
                                    ? 20
                                    : 16
                            }
                            tooltipCalloutProps={fluentTooltipStyleCalloutProps}
                        />
                    )}
                </div>
                {displayEditDialog()}
            </div>
        );
    }

    // Resolve styles safely
    const resolvedStyles =
        typeof fluentTooltipStyleCalloutProps.styles === 'function'
            ? fluentTooltipStyleCalloutProps.styles({ theme: {} as ITheme }) // Call function with default props
            : fluentTooltipStyleCalloutProps.styles || {}; // Fallback to an empty object

    // Merge styles
    const mergedCalloutProps = {
        ...fluentTooltipStyleCalloutProps,
        styles: {
            ...resolvedStyles,
            root: {
                ...(typeof resolvedStyles?.root === 'object' ? resolvedStyles.root : {}), // Safely spread the existing `root` styles
                width: '64px',
                height: '28px',
                transform: 'translate(5px)',
            },
        },
    };

    const styles = mergeStyleSets({
        multivalue: {
            display: 'flex',
            alignItems: 'center',
            selectors: {
                '& div:nth-child(2)': {
                    marginLeft: 10,
                },
            },
        },
        contextMenu: {
            '& .ms-ContextualMenu': {
                minWidth: '1px !important',
                width: '100px !important',
            },
        },
        dialogTitleStyle: {
            '.ms-Dialog-title': {
                padding: '24px 46px 8px 24px',
            },
        },
    });

    function isTrue(val: string | undefined): boolean {
        return /true/i.test(`${val}`);
    }

    function renderCheckBox(): JSX.Element {
        const checkboxStyles = {
            root: {
                fontSize: 18,
                color: isTrue(getDisplayedValue()) ? SharedColors.cyanBlue10 : undefined,
                opacity: isTrue(getDisplayedValue()) ? undefined : 0.5,
            },
        };
        return isTrue(getDisplayedValue()) ? (
            <Icon styles={checkboxStyles} iconName='CheckboxCompositeReversed' />
        ) : (
            <Icon styles={checkboxStyles} iconName='Checkbox' />
        );
    }

    switch (props.type) {
        case 'Checkbox':
            return displayValueAsCheckBox();
        case 'Date':
            return displayValueAsTextForDates();
        case 'Text':
        default:
            return displayValueAsText();
    }
}
