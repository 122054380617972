import React, { useContext, useEffect, useMemo, useState } from 'react';
import { mergeStyleSets, FontWeights, Separator, Icon } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import {
    IEmployee,
    IEmployeeEditableInfo,
    IEmployeeWithEditableData,
    AllEmployeeEditableFields,
} from 'clients/employee-client';
import { isGUID } from 'utils/string-utils';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import EllipsisText from 'components/common/ellipsis-text';
import {
    getJobTitleOrDefault,
    getEmployeeTypeLabel,
    getRestrictedProfileLabel,
} from 'components/common/employee/internal-employee-utils';
import EditableEmployeeData, {
    UpdateKeyValueType,
} from 'components/common/employee/editable-employee-data';
import { UserContext } from 'contexts/user-context';
import { REGEX_EMAIL, REGEX_PHONE, REGEX_SSN } from 'utils/misc-utils';
import { noDataText } from 'assets/constants/global-constants';
import {
    UserEducationMessage,
    UserEducationModal,
} from 'components/personnel-profile/common/profile-user-education';
import { fluentTooltipStyleCalloutProps } from 'assets/styles/global-styles';
import DataFieldPlaceholder from 'components/common/data-field-placeholder';
export interface EmployeeDataProps {
    employee: IEmployeeWithEditableData;
    allowEditableEmployeeData?: boolean;
    updateEmployeeData: (newEmployeeData?: IEmployeeWithEditableData) => void;
    isClearedWorkforceView?: boolean;
    isRestrictedProfile: boolean;
}

//TODO: Find a way to render this component without a fix width on the first column
export function EmployeeData(props: EmployeeDataProps): JSX.Element {
    const userContext = useContext(UserContext);
    const [isUserEducationModalOpen, setIsUserEducationModalOpen] = useState(false);

    const [editableEmployeeInfo, setEditableEmployeeInfo] = useState<
        IEmployeeEditableInfo | undefined
    >();
    const [editableDataProvidedByHR, setEditableDataProvidedByHR] = useState<string[] | undefined>(
        props.employee.editablePropertiesProvidedByHR,
    );

    const containerProps = useMemo(() => {
        if (props.isClearedWorkforceView) {
            return {
                leftEtiquetteLabel: 'Employee data',
            };
        }

        return {
            leftEtiquetteLabel: 'Employee data',
            rightEtiquette: getEmployeeTypeLabel(
                props.employee.data,
                props.employee.employeeStatus,
            ),
            secondRightEtiquette: getRestrictedProfileLabel(props.isRestrictedProfile),
        };
    }, [props.isClearedWorkforceView, props.employee, props.isRestrictedProfile]);

    useEffect(() => {
        if (props.allowEditableEmployeeData === true && props.employee) {
            setEditableEmployeeInfo(props.employee.data);
            setEditableDataProvidedByHR(props.employee.editablePropertiesProvidedByHR);
        }
    }, [props.allowEditableEmployeeData, props.employee]);

    function isDataProvidedByHR(secureField: AllEmployeeEditableFields): boolean {
        if (editableDataProvidedByHR) {
            return (
                editableDataProvidedByHR.findIndex(
                    (item) => secureField.toLowerCase() === item.toLowerCase(),
                ) > -1
            );
        }
        return false;
    }

    const updateEditableEmployeeData: UpdateKeyValueType = (key, value) => {
        if (editableEmployeeInfo) {
            const newEditableEmployeeData = { ...editableEmployeeInfo };
            newEditableEmployeeData[key] = value;
            setEditableEmployeeInfo(newEditableEmployeeData);

            // if profile is not a FTE and the updated editable value needs to trigger the parent component to re-render
            // via the updateEmployeeDate. This is to make the employee card info to match the newly updated editable value
            if (isGUID(props.employee.data?.id)) {
                const newEmployeeData = {
                    data: { ...props.employee.data, ...newEditableEmployeeData },
                    employeeStatus: props.employee.employeeStatus,
                    editablePropertiesProvidedByHR: props.employee.editablePropertiesProvidedByHR,
                };
                props.updateEmployeeData(newEmployeeData);
            }
        }
    };

    function allowShowElseShow(allowShow: JSX.Element, elseShow: JSX.Element): JSX.Element {
        if (
            editableEmployeeInfo &&
            props.allowEditableEmployeeData &&
            userContext.isNSTUserType()
        ) {
            return allowShow;
        }
        return elseShow;
    }

    function showNonEdit(secureField: AllEmployeeEditableFields): JSX.Element {
        return (
            <>
                {editableEmployeeInfo && editableEmployeeInfo[secureField]
                    ? editableEmployeeInfo[secureField]
                    : ''}
            </>
        );
    }

    return (
        <ContainerWithEtiquettes
            bottomLeftInfo={
                props.isClearedWorkforceView && (
                    <UserEducationMessage>
                        <span style={{ fontWeight: 600 }}>
                            Need to update your info?{' '}
                            <span
                                onClick={() => setIsUserEducationModalOpen(true)}
                                style={{ color: 'rgba(15, 108, 189, 1)', fontWeight: 700 }}>
                                Learn more
                            </span>
                        </span>
                    </UserEducationMessage>
                )
            }
            {...containerProps}>
            <UserEducationModal
                onDismiss={() => setIsUserEducationModalOpen(false)}
                isDialogOpen={isUserEducationModalOpen}
            />
            <div className={styles.employeeData}>
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>First name</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.firstName}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit first name'}
                                dialogTitle={'Edit first name'}
                                dialogLabel={'first Name'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Middle name</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.middleName}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit middle name'}
                                    dialogTitle={'Edit middle name'}
                                    dialogLabel={'middle Name'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Last name</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.lastName}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit last name'}
                                dialogTitle={'Edit last name'}
                                dialogLabel={'last Name'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Suffix</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.suffix}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit suffix'}
                                    dialogTitle={'Edit suffix'}
                                    dialogLabel={'suffix'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Maiden name</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.maidenName}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit maiden name'}
                                    dialogTitle={'Edit maiden name'}
                                    dialogLabel={'maiden Name'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataLine}>
                    <div className={styles.key}>Microsoft Id</div>
                    <div className={styles.value}>
                        <EditableEmployeeData
                            employee={props.employee}
                            secureField={AllEmployeeEditableFields.microsoftId}
                            editableData={editableEmployeeInfo}
                            placeholder={'Microsoft Id'}
                            dialogTitle={'Edit Microsoft Id'}
                            dialogLabel={'Microsoft Id'}
                            type={'Text'}
                            updateKeyValue={updateEditableEmployeeData}
                            defaultMask='XXXXXXX'
                            defaultDisplayValue={props.employee.data.id}
                            isEditDisabled={props.isClearedWorkforceView}
                        />
                    </div>
                </div>
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>US citizenship</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.isUSCitizen}
                                editableData={editableEmployeeInfo}
                                dialogTitle={'Edit US citizenship'}
                                dialogLabel={'Verified'}
                                type={'Checkbox'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>

                    <div className={styles.dataLine}>
                        <div className={styles.key}>SSN</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.nationalIdNumber}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'###-##-####'}
                                    regex={REGEX_SSN}
                                    errorMessage={
                                        'Must be a nine digit number in the format ###-##-####'
                                    }
                                    dialogTitle={'Edit social security number'}
                                    dialogLabel={'social security number'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXX-XX-XXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Birth place</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.birthPlace}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit city, state, and country'}
                                    dialogTitle={'Edit birth place'}
                                    dialogLabel={'birth place'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXX, XX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Birth date</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <>
                                    <EditableEmployeeData
                                        employee={props.employee}
                                        secureField={AllEmployeeEditableFields.birthDate}
                                        editableData={editableEmployeeInfo}
                                        placeholder={'Select birth date'}
                                        dialogTitle={'Edit birth date'}
                                        dialogLabel={'birth date'}
                                        type={'Date'}
                                        updateKeyValue={updateEditableEmployeeData}
                                        defaultMask='XX XX XXXX'
                                        isEditDisabled={props.isClearedWorkforceView}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Passport number</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.passportId}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit passport number'}
                                    dialogTitle={'Edit passport number'}
                                    dialogLabel={'passport number'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Passport issue date</div>
                        <div className={styles.value}>
                            <>
                                {props.isRestrictedProfile ? (
                                    <DataFieldPlaceholder />
                                ) : (
                                    <EditableEmployeeData
                                        employee={props.employee}
                                        secureField={AllEmployeeEditableFields.passportIssueDate}
                                        editableData={editableEmployeeInfo}
                                        placeholder={'Select passport issue date'}
                                        dialogTitle={'Edit passport issue date'}
                                        dialogLabel={'passport issue date'}
                                        type={'Date'}
                                        updateKeyValue={updateEditableEmployeeData}
                                        defaultMask='XX XX XXXX'
                                        isEditDisabled={props.isClearedWorkforceView}
                                    />
                                )}
                            </>
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Passport expiration date</div>
                        <div className={styles.value}>
                            <>
                                {props.isRestrictedProfile ? (
                                    <DataFieldPlaceholder />
                                ) : (
                                    <EditableEmployeeData
                                        employee={props.employee}
                                        secureField={
                                            AllEmployeeEditableFields.passportExpirationDate
                                        }
                                        editableData={editableEmployeeInfo}
                                        placeholder={'Select passport expiration date'}
                                        dialogTitle={'Edit passport expiration date'}
                                        dialogLabel={'passport expiration date'}
                                        type={'Date'}
                                        updateKeyValue={updateEditableEmployeeData}
                                        defaultMask='XX XX XXXX'
                                        isEditDisabled={props.isClearedWorkforceView}
                                    />
                                )}
                            </>
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>KMP</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.isKmp}
                                editableData={editableEmployeeInfo}
                                dialogTitle={'Edit KMP'}
                                dialogLabel={'Designated'}
                                type={'Checkbox'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Company</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.employeeType}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit company'}
                                    dialogTitle={'Edit company'}
                                    dialogLabel={'company'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Company code</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.companyCode}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit company code'}
                                dialogTitle={'Edit company code'}
                                dialogLabel={'company code'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Cost center</div>
                        <div className={styles.value}>
                            {props.employee.data.costCenterCode &&
                            props.employee.data.department &&
                            isDataProvidedByHR(AllEmployeeEditableFields.costCenterCode) ? (
                                <span className={styles.costCenter}>
                                    <EllipsisText
                                        text={`${props.employee.data.costCenterCode} - ${props.employee.data.department}`}
                                        textLengthBeforeEllipsis={25}
                                        tooltipCalloutProps={fluentTooltipStyleCalloutProps}
                                    />
                                </span>
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.costCenterCode}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit cost center'}
                                    dialogTitle={'Edit cost center'}
                                    dialogLabel={'cost center'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXX - XXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>PCN</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.positionNumber}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit position control number'}
                                dialogTitle={'Edit position control number'}
                                dialogLabel={'position control number'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Start date</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.startDate}
                                editableData={editableEmployeeInfo}
                                placeholder={'Select start date'}
                                dialogTitle={'Edit start date'}
                                dialogLabel={'start date'}
                                type={'Date'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXX XXX XX XXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>End date</div>
                        <div className={styles.value}>
                            {!isGUID(props.employee?.data?.id) ? (
                                props.employee?.data.terminationDate ? (
                                    new Date(props.employee.data.terminationDate).toDateString()
                                ) : (
                                    noDataText
                                )
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.terminationDate}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Select end date'}
                                    dialogTitle={'Edit end date'}
                                    dialogLabel={'end date'}
                                    type={'Date'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXX XXX XX XXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Profession</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.profession}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit profession'}
                                dialogTitle={'Edit profession'}
                                dialogLabel={'profession'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Discipline</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.discipline}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit discipline'}
                                dialogTitle={'Edit discipline'}
                                dialogLabel={'discipline'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Title</div>
                        <div className={styles.value}>
                            {!isGUID(props.employee.data?.id) ? (
                                getJobTitleOrDefault(props.employee.data as IEmployee).toUpperCase()
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.standardTitle}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit title'}
                                    dialogTitle={'Edit title'}
                                    dialogLabel={'title'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>City</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.locationCity}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit city'}
                                dialogTitle={'Edit city'}
                                dialogLabel={'city'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>State</div>
                        <div className={styles.value}>
                            <EditableEmployeeData
                                employee={props.employee}
                                secureField={AllEmployeeEditableFields.locationAreaDetail}
                                editableData={editableEmployeeInfo}
                                placeholder={'Edit state'}
                                dialogTitle={'Edit state'}
                                dialogLabel={'state'}
                                type={'Text'}
                                updateKeyValue={updateEditableEmployeeData}
                                defaultMask='XXXXXXXXXX'
                                isEditDisabled={props.isClearedWorkforceView}
                            />
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Country</div>
                        <div className={styles.value}>
                            {props.employee.data.country &&
                            props.employee.data.locationAreaCode &&
                            isDataProvidedByHR(AllEmployeeEditableFields.locationCountry) ? (
                                `${props.employee.data.country} (${props.employee.data.locationAreaCode})`
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.locationCountry}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit country'}
                                    dialogTitle={'Edit country'}
                                    dialogLabel={'country'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <Separator styles={separatorStyles} />
                <div className={styles.dataGroup}>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Work phone</div>
                        <div className={styles.value}>
                            {props.employee.data.businessPhones &&
                            props.employee.data.businessPhones.length > 0 &&
                            isDataProvidedByHR(AllEmployeeEditableFields.workPhone)
                                ? props.employee.data.businessPhones
                                      .filter(
                                          (value: string, index: number, self: string[]) =>
                                              self.indexOf(value) === index,
                                      )
                                      .map((x) => {
                                          return <div key={x}>{x}</div>;
                                      })
                                : allowShowElseShow(
                                      props.isRestrictedProfile ? (
                                          <DataFieldPlaceholder />
                                      ) : (
                                          <EditableEmployeeData
                                              employee={props.employee}
                                              secureField={AllEmployeeEditableFields.workPhone}
                                              editableData={editableEmployeeInfo}
                                              placeholder={'Edit work phone'}
                                              dialogTitle={'Edit work phone'}
                                              dialogLabel={'work phone'}
                                              regex={REGEX_PHONE}
                                              errorMessage={'Must be a valid phone number format'}
                                              type={'Text'}
                                              updateKeyValue={updateEditableEmployeeData}
                                              defaultMask='XXX-XXX-XXXX'
                                              isEditDisabled={props.isClearedWorkforceView}
                                          />
                                      ),
                                      showNonEdit(AllEmployeeEditableFields.workPhone),
                                  )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Work email</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.workEmail}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit work email'}
                                    dialogTitle={'Edit work email'}
                                    dialogLabel={'work email'}
                                    regex={REGEX_EMAIL}
                                    errorMessage={'Must be a valid email format'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXX@XXXXXXX.XXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home phone</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.homePhone}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit home phone'}
                                    dialogTitle={'Edit home phone'}
                                    dialogLabel={'home phone'}
                                    regex={REGEX_PHONE}
                                    errorMessage={'Must be a valid phone number format'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXX-XXX-XXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Personal cell phone</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.personalCellPhone}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit personal cell phone'}
                                    dialogTitle={'Edit personal cell phone'}
                                    dialogLabel={'personal cell phone'}
                                    regex={REGEX_PHONE}
                                    type={'Text'}
                                    errorMessage={'Must be a valid phone number format'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXX-XXX-XXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Personal email</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.personalEmail}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit personal email'}
                                    dialogTitle={'Edit personal email'}
                                    dialogLabel={'personal email'}
                                    regex={REGEX_EMAIL}
                                    type={'Text'}
                                    errorMessage={'Must be a valid email format'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXX@XXXX.XXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>

                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home address</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.homeAddress}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit home address'}
                                    dialogTitle={'Edit home address'}
                                    dialogLabel={'home address'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXX XXXXXXXX XXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home city</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.homeCity}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit home city'}
                                    dialogTitle={'Edit home city'}
                                    dialogLabel={'home city'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home state</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.homeState}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit home state'}
                                    dialogTitle={'Edit home state'}
                                    dialogLabel={'home state'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home ZIP</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.homeZip}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit home zone improvement plan code'}
                                    dialogTitle={'Edit home zone improvement plan code'}
                                    dialogLabel={'home zone improvement plan code'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXXXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.dataLine}>
                        <div className={styles.key}>Home country</div>
                        <div className={styles.value}>
                            {props.isRestrictedProfile ? (
                                <DataFieldPlaceholder />
                            ) : (
                                <EditableEmployeeData
                                    employee={props.employee}
                                    secureField={AllEmployeeEditableFields.homeCountry}
                                    editableData={editableEmployeeInfo}
                                    placeholder={'Edit home country'}
                                    dialogTitle={'Edit home country'}
                                    dialogLabel={'home country'}
                                    type={'Text'}
                                    updateKeyValue={updateEditableEmployeeData}
                                    defaultMask='XXX'
                                    isEditDisabled={props.isClearedWorkforceView}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </ContainerWithEtiquettes>
    );
}

function isTrue(checked: boolean | string | undefined): boolean {
    return /true/i.test(`${checked}`);
}

function displayCheckBox(checked: boolean | string | undefined): JSX.Element {
    const isChecked = isTrue(checked);
    const checkboxStyles = {
        root: {
            fontSize: 18,
            color: isChecked ? SharedColors.cyanBlue10 : undefined,
            opacity: isChecked ? undefined : 0.5,
        },
    };
    return isChecked ? (
        <Icon styles={checkboxStyles} iconName='CheckboxCompositeReversed' />
    ) : (
        <Icon styles={checkboxStyles} iconName='Checkbox' />
    );
}

const separatorStyles = {
    root: {
        selectors: {
            '&::before': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                opacity: 0.7,
            },
        },
    },
};

const styles = mergeStyleSets({
    employeeData: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
    },
    dataLine: {
        display: 'flex',
        alignItems: 'center',
        selectors: {
            '&:not(:last-child):not(:first-child)': {
                marginTop: 7,
                marginBottom: 7,
            },
        },
    },
    key: {
        width: '150px',
        fontWeight: FontWeights.semibold,
    },
    value: {
        paddingLeft: '10px',
        width: '200px',
    },
    dataGroup: {
        paddingTop: 2,
        paddingBottom: 2,
    },
    multivalue: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '10px',
        selectors: {
            '& div:nth-child(2)': {
                marginLeft: 10,
            },
        },
    },
    manager: {
        fontWeight: FontWeights.semibold,
    },
    title: {
        paddingLeft: '10px',
        width: '140px',
    },
    costCenter: {
        whiteSpace: 'pre',
    },
});
export default EmployeeData;
