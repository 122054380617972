import React from 'react';
import { TooltipHost, ITooltipHostStyles, TooltipDelay, ICalloutProps } from '@fluentui/react';

export interface EllipsisTextProps {
    text: string | undefined;
    textLengthBeforeEllipsis: number;
    tooltipDelay?: TooltipDelay;
    tooltipCalloutProps?: ICalloutProps;
}

export default function EllipsisText(props: EllipsisTextProps): JSX.Element {
    if (!props.text) {
        return <></>;
    }

    const hasEllipsis = props.text.length > props.textLengthBeforeEllipsis;
    const displayText = hasEllipsis
        ? props.text.substr(0, props.textLengthBeforeEllipsis) + '...'
        : props.text;

    if (hasEllipsis) {
        return (
            <TooltipHost
                content={props.text}
                delay={props.tooltipDelay ?? TooltipDelay.zero}
                calloutProps={props.tooltipCalloutProps}
                styles={
                    {
                        root: {
                            selectors: {
                                ':first-child': {
                                    marginRight: '10px',
                                },
                            },
                        },
                    } as Partial<ITooltipHostStyles>
                }>
                <span>{displayText}</span>
            </TooltipHost>
        );
    } else {
        return <span>{displayText}</span>;
    }
}
