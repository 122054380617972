import EmployeeClient, {
    IBasicEmployee,
    IEmployee,
    PublicEmployeeEditableFields,
    SearchEmployeeStatus,
} from 'clients/employee-client';
import GraphClient from 'clients/graph-client';
import { AuthContext, IAuthContext } from 'contexts/auth-context';
import { useContext, useEffect, useState } from 'react';
import { useIsMounted } from 'utils/misc-hooks';
import { debounceAsync, generalIsMountedCode } from 'utils/misc-utils';
import {
    fetchOrUseCachePictures,
    getDisplayNameOrDefault,
} from 'components/common/employee/employee-utils';
import { isGUID } from 'utils/string-utils';
import { IPersonaProps, SharedColors } from '@fluentui/react';
import { transformEmailToPersona, transformEmployeeToPersona } from 'utils/internal-persona-utils';
import { CacheContext } from 'contexts/cache-context';

interface IUseEmployeeInfoProps {
    authContext: IAuthContext;
    id: string | undefined;
}

export const useEmployeeInfo = (props: IUseEmployeeInfoProps): IEmployee | undefined => {
    const [employee, setEmployee] = useState<IEmployee>();

    const fetchEmployeeInfo = async (isMountedFunc: () => boolean): Promise<void> => {
        if (!props.id) {
            return;
        }
        try {
            const employeeVar = await EmployeeClient.getEmployeeByAliasOrId(
                props.authContext,
                props.id,
            );
            if (isMountedFunc()) setEmployee(employeeVar);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchEmployeeInfo);
    }, [props.id]);

    return employee;
};

export const useEmployeeBasicInfo = (props: IUseEmployeeInfoProps): IBasicEmployee | undefined => {
    const [employee, setEmployee] = useState<IBasicEmployee>();

    const fetchBasicEmployeeInfo = async (isMountedFunc: () => boolean): Promise<void> => {
        if (!props.id) {
            return;
        }
        try {
            const employeeVar = await EmployeeClient.getBasicEmployeesById(props.authContext, [
                props.id,
            ]);
            if (isMountedFunc()) setEmployee(employeeVar[0]);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        return generalIsMountedCode(fetchBasicEmployeeInfo);
    }, [props.id]);

    return employee;
};

interface IUseEmployeeBasicDataProps {
    authContext: IAuthContext;
    personnelId?: string;
    alias?: string;
    oid?: string;
    employeeBasicData?: IBasicEmployee;
}

type UseEmployeeBasicDataType = {
    prehireData: {
        personnelId: string;
        displayName: string;
    };
    employeeBasicData: IBasicEmployee | undefined;
};

export const useEmployeeBasicOrPrehireData = (
    props: IUseEmployeeBasicDataProps,
): UseEmployeeBasicDataType => {
    const isMounted = useIsMounted();
    const [employeeBasicData, setEmployeeBasicData] = useState<IBasicEmployee>();
    const [prehireData, setPrehireData] = useState({
        personnelId: '',
        displayName: '',
    });

    const fetchEmployeeBasicData = async (): Promise<void> => {
        try {
            let basicResult: IBasicEmployee[];
            if (props.personnelId) {
                basicResult = await EmployeeClient.getBasicEmployeesById(props.authContext, [
                    props.personnelId,
                ]);
            } else if (props.alias) {
                basicResult = await EmployeeClient.getBasicEmployeesByAlias(props.authContext, [
                    props.alias as string,
                ]);
            } else {
                const result = await GraphClient.getUserByOidQuery(
                    props.authContext,
                    props.oid!,
                    '$select=department,jobTitle,displayName,mailNickname,userPrincipalName,officeLocation,id,onPremisesSamAccountName,surname,onPremisesDomainName&$expand=manager($select=displayName,userPrincipalName,onPremisesSamAccountName)',
                );
                const basicEmployee: IBasicEmployee[] = [
                    {
                        id: result?.id ?? '',
                        oid: props.oid ?? '',
                        givenName: result?.givenName ?? '',
                        middleName: '',
                        surname: result?.surname ?? '',
                        displayName: result?.displayName ?? '',
                        mail: result?.mail ?? '',
                        userPrincipalName: result?.userPrincipalName ?? '',
                        jobTitle: result?.jobTitle ?? '',
                        department: result?.department ?? '',
                        onPremisesSamAccountName: result?.onPremisesSamAccountName ?? '',
                        isActiveEmployee: true,
                        reportsToEmailName: result?.manager?.userPrincipalName ?? '',
                        managerName: result?.manager?.displayName ?? '',
                        onPremisesDomainName: result?.onPremisesDomainName ?? '',
                        officeLocation: result?.officeLocation ?? '',
                        managerAlias: result?.manager?.onPremisesSamAccountName ?? '',
                    },
                ];

                basicResult = basicEmployee;
            }
            if (isMounted()) {
                setEmployeeBasicData(basicResult[0]);
            }
        } catch (e) {
            console.error('Error fetching employee basic data');
            console.error(e);
            setEmployeeBasicData(undefined);
        }
    };

    const fetchPrehireEmployeeData = async (): Promise<void> => {
        try {
            const editableEmployeeResponse = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                props.authContext,
                props.personnelId!,
                Object.values(PublicEmployeeEditableFields),
            );
            const localDisplayName = getDisplayNameOrDefault(
                {
                    firstName: editableEmployeeResponse?.employeeEditableInfo?.firstName,
                    lastName: editableEmployeeResponse?.employeeEditableInfo?.lastName,
                },
                editableEmployeeResponse?.id,
            );
            const fullDisplayName = `${localDisplayName} (${
                editableEmployeeResponse.employeeEditableInfo?.positionNumber
                    ? editableEmployeeResponse.employeeEditableInfo?.positionNumber
                    : 'No PCN'
            })`;

            if (isMounted()) {
                setPrehireData({
                    displayName: fullDisplayName,
                    personnelId: editableEmployeeResponse.id,
                });
            }
        } catch (e) {
            console.error('Error fetching prehire data');
            console.error(e);
        }
    };

    useEffect(() => {
        if (props.personnelId && isGUID(props.personnelId)) {
            fetchPrehireEmployeeData();
        } else if (props.personnelId || props.alias || props.oid) {
            fetchEmployeeBasicData();
        } else {
            setEmployeeBasicData(props.employeeBasicData);
        }
    }, [props.alias, props.personnelId, props.oid, props.employeeBasicData]);

    return {
        prehireData,
        employeeBasicData,
    };
};

export const useDebounceGetEmployeePersonas = (
    searchEmployeeStatus?: SearchEmployeeStatus,
    showUserPrincipalAsSecondaryText?: boolean,
    showSecondaryText?: boolean,
    showOnlyEmail?: boolean,
    showFTEOnly?: boolean,
) => {
    const authContext = useContext(AuthContext);
    const cacheContext = useContext(CacheContext);

    const debouncedGetTypeaheadPersonas = debounceAsync<IPersonaProps[]>(
        getTypeaheadEmployeePersonas,
    );
    async function getTypeaheadEmployeePersonas(input: string): Promise<IPersonaProps[]> {
        if (!input) {
            return [];
        }
        try {
            let employees;
            if (searchEmployeeStatus === SearchEmployeeStatus.prehire) {
                employees = await EmployeeClient.searchPrehires(input, authContext);
            } else {
                employees = await EmployeeClient.searchEmployees(
                    input,
                    authContext,
                    searchEmployeeStatus,
                );
            }

            if (showFTEOnly) {
                employees = employees.filter((e) => e.isFTE);
            }

            const pictures = await fetchOrUseCachePictures(authContext, employees, cacheContext);

            return employees.map((e) => {
                const secondaryText = showUserPrincipalAsSecondaryText
                    ? e.userPrincipalName
                    : undefined;

                const persona = showOnlyEmail
                    ? transformEmailToPersona(e.userPrincipalName)
                    : transformEmployeeToPersona(e, secondaryText, cacheContext);

                const picture = pictures[e.id];
                if (picture) {
                    persona.imageUrl = picture;
                }
                return persona;
            });
        } catch (e) {
            console.error('useDebounceGetEmployeePersonas error: ', e);
            return [];
        }
    }
    return debouncedGetTypeaheadPersonas;
};

export interface IEmployeeNameAndType {
    displayName: string;
    alias?: string;
    email?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    type: PersonnelTypes;
    l1?: string;
    l2?: string;
    l3?: string;
    l4?: string;
    l5?: string;
}

export enum PersonnelTypes {
    Terminated = 'terminated',
    PreHire = 'pre-hire',
    Active = 'active',
    FutureTermination = 'future termination',
}

export function getEmployeeType(providedEmployee: IBasicEmployee | IEmployee): PersonnelTypes {
    if (isGUID(providedEmployee.id)) return PersonnelTypes.PreHire;
    if (!providedEmployee.isActiveEmployee) return PersonnelTypes.Terminated;
    return PersonnelTypes.Active;
}

export const getEmployeeTypeLabel = (
    employee: IEmployee,
    employeeStatus?: SearchEmployeeStatus,
): { label: string; backgroundColor: string } => {
    let label = 'Vendor';
    let backgroundColor = SharedColors.orange10;
    if (employee) {
        if (isGUID(employee.id)) {
            label = employeeStatus || 'Prehire';
            backgroundColor = SharedColors.cyanBlue10;
        } else if (!employee.isActiveEmployee) {
            label = 'Terminated';
            backgroundColor = SharedColors.red10;
        } else if (employee.isFTE) {
            label = 'FTE';
            backgroundColor = SharedColors.cyanBlue10;
        }
    }

    return {
        label,
        backgroundColor,
    };
};

export const getRestrictedProfileLabel = (
    isRestrictedProfile: boolean,
): { label: string; backgroundColor: string; useDarkTextColor: boolean } | undefined => {
    return isRestrictedProfile
        ? {
              label: 'Restricted profile',
              backgroundColor: SharedColors.yellow10,
              useDarkTextColor: true,
          }
        : undefined;
};

export function getJobTitleOrDefault(
    providedEmployee: { jobTitle?: string; standardTitle?: string } | undefined,
    defaultValue = '',
): string {
    if (providedEmployee) {
        if (providedEmployee.jobTitle) {
            return providedEmployee.jobTitle;
        } else if (providedEmployee.standardTitle) {
            return providedEmployee.standardTitle;
        }
    }

    return defaultValue;
}
