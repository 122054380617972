import config from 'environments/environment';
import { GetDefaultHttpOptions, HttpContentTypeEnum, IPagedResults } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import { IFiltersContext } from 'contexts/filters-context';
import { IEmployee } from 'clients/employee-client';
import ClientUtils from 'clients/client-utils';
import { Dictionary } from 'assets/constants/global-constants';
import {
    AcceptanceOptions,
    ApprovalOptions,
    IBarChartData,
    IContact,
    ICreateNominationRequest,
    ICustomerMetricsSummaryData,
    IScreening,
    IScreeningResult,
    IScreeningSearchResult,
} from 'components/screening/us-gov/IScreening';
import { ICustomerMetricsBackend } from 'components/screening/common/ICustomerMetrics';
import {
    AdjudicatedDecision,
    ClearanceLevelType,
    ScreeningParentStateType,
    ScreeningRequestTypes,
    ScreeningStateType,
    StateName,
    UsGovScreeningUserType,
} from 'components/screening/common/common-constants';
import { IAccesses } from 'components/screening/us-gov/candidates/nomination/steps/common/display-badges';
import { IDocumentToken } from 'clients/documents-client';
import {
    ContractRequestParamNames,
    ContractStatus,
    ContractType,
    IContract,
    IContractRequest,
} from 'components/screening/us-gov/IContract';
import { ScreeningRole } from 'configs/roles';
import { isGUID } from 'utils/string-utils';
import { IBaseSummaryDataResponse } from 'components/common/search-filter/search-filter-container';
import { IAgency } from 'components/screening/us-gov/IAgency';

const MaxScreeningEmployeeQuery = 5000;
const nominationConfig = config.nominationServiceConfig;
export enum ApplicationServices {
    screening = 'Screening_Service',
    profile = 'Profile_Service',
}
class UsGovScreeningClient {
    static get screeningBaseUrl(): string {
        return `${nominationConfig.baseUrl}${nominationConfig.screeningsEndpoint}`;
    }

    static async createNomination(
        authContext: IAuthContext,
        request: ICreateNominationRequest,
    ): Promise<IScreening> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = this.screeningBaseUrl;
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(request);
        const response = await fetch(url, httpOptions);
        if (response.status === 201) {
            return await response.json();
        } else {
            throw await response.text();
        }
    }

    static async updateScreening(
        authContext: IAuthContext,
        screening: IScreening,
    ): Promise<IScreening> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.screeningBaseUrl}/Update`;
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(screening);
        return ClientUtils.doHttpRequest<IScreening>(url, httpOptions);
    }

    static async updateScreeningContact(
        authContext: IAuthContext,
        id: string,
        screeningContact: IContact,
    ): Promise<IScreening> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.screeningBaseUrl}/${id}/UpdateScreeningContact`;
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(screeningContact);
        return ClientUtils.doHttpRequest<IScreening>(url, httpOptions);
    }

    static async updateScreeningState(
        authContext: IAuthContext,
        id: string,
        state: string,
        substate?: string,
    ): Promise<IScreening> {
        const httpOptions = await GetDefaultHttpOptions(authContext);

        let url = `${this.screeningBaseUrl}/${id}/ChangeState/${state}/`;

        if (substate) {
            url += substate;
        }

        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            default:
                throw result;
        }
    }

    static async updateProcessOwner(
        authContext: IAuthContext,
        id: string,
        processOwnerId: string | undefined,
    ): Promise<IScreening> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const newProcessOwnerId = processOwnerId ? processOwnerId : 'unset';
        const url = `${this.screeningBaseUrl}/${id}/processowner/${newProcessOwnerId}`;
        httpOptions.method = 'POST';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to update Process owner`;
            default:
                throw result;
        }
    }

    /*doing this to to avoid repetition, but this couples the ability of a contract owner to Approve/Deny
    nominations to the ability of a nominee to Accept/Reject an NDA
    if this changes on the backend it should also change here*/
    private static _ApproveDenyActions: string[] = ['Approve', 'Deny', 'Accept', 'Decline'];
    static async approveDeny(
        authContext: IAuthContext,
        id: string,
        action: ApprovalOptions | AcceptanceOptions,
    ): Promise<IScreening | undefined> {
        if (this._ApproveDenyActions.includes(action)) {
            const httpOptions = await GetDefaultHttpOptions(authContext);
            //This endpoint is dangerous. The backend should make sure only the authorized user can Approve/Deny/Accept/Reject
            const url = `${this.screeningBaseUrl}/${id}/${action}`;
            httpOptions.method = 'POST';
            const response = await fetch(url, httpOptions);
            if (response.status === 200) {
                return await response.json();
            } else {
                throw await response.text();
            }
        } else {
            return;
        }
    }

    static async getScreeningById(authContext: IAuthContext, id: string): Promise<IScreening> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.screeningBaseUrl}/${id}`;
        return ClientUtils.doHttpRequest<IScreening>(url, httpOptions);
    }

    static async getScreeningRecordExcelDataByRequests(
        authContext: IAuthContext,
        excelRequests: IScreeningRecordUSGovExcelRequest[],
        userType?: string,
        isMyOrg?: boolean,
        correlationId?: string,
    ): Promise<IScreeningRecordUSGovExcelResponse[]> {
        const urlParams = new URLSearchParams();
        if (userType) {
            urlParams.append('userType', userType);
        }
        if (isMyOrg) {
            urlParams.append('isMyOrg', `${isMyOrg}`);
        }
        if (!!correlationId) {
            urlParams.append('correlationId', correlationId);
        }

        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(excelRequests);

        const url = `${
            this.screeningBaseUrl
        }/screeningrecordusgovexceldata?${urlParams.toString()}`;

        return ClientUtils.doHttpRequest<IScreeningRecordUSGovExcelResponse[]>(url, httpOptions);
    }

    static async getScreeningUserTypesForUser(
        authContext: IAuthContext,
    ): Promise<IScreeningUserTypes> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.screeningBaseUrl}/usertypes`;
        httpOptions.method = 'GET';
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Failed to get User Type`;
            default:
                throw result;
        }
    }

    static async GetScreeningSummaryData(
        authContext: IAuthContext,
        summaryType: SummaryDataTypeEnum,
        summaryTypeData?: string,
    ): Promise<IScreeningSummaryDataResponse> {
        const httpOptions = await GetDefaultHttpOptions(authContext);

        let url = `${this.screeningBaseUrl}/GetScreeningSummaryData`;

        const urlParams = new URLSearchParams();
        urlParams.append('summaryType', summaryType);

        if (summaryTypeData) {
            urlParams.append('summaryTypeData', summaryTypeData);
        }

        const paramsStringValue = urlParams.toString();
        if (!!paramsStringValue) {
            url += '?' + paramsStringValue;
        }

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const jsonResponse = await res.json();
            return jsonResponse;
        } else {
            throw res;
        }
    }

    static async getPagedScreenings(
        authContext: IAuthContext,
        filter: IFiltersContext,
        isMyOrg: boolean | undefined,
        continuationToken?: string,
    ): Promise<IScreeningResult> {
        const urlParams = new URLSearchParams();
        urlParams.append('resultCount', nominationConfig.screeningsResultCount.toString());
        if (filter.userType) {
            urlParams.append('userType', filter.userType);
        }
        if (filter.personnelId) {
            urlParams.append('personnelId', filter.personnelId);
        }
        if (isMyOrg) {
            urlParams.append('isMyOrg', `${isMyOrg}`);
        }
        return this._getScreenings(authContext, urlParams, continuationToken);
    }

    static async searchScreenings(
        authContext: IAuthContext,
        urlParams: URLSearchParams,
        resultCount?: number,
        summaryType?: SummaryDataTypeEnum,
        continuationToken?: string,
    ): Promise<IScreeningSearchResult> {
        // Create a duplicate of urlParams to avoid modifying the original.
        const urlParamsObj = new URLSearchParams(urlParams);
        if (resultCount !== undefined) {
            urlParamsObj.append('resultCount', resultCount.toString());
        }
        if (summaryType !== undefined) {
            urlParamsObj.append('summaryType', summaryType.toString());
        }

        let url = `${this.screeningBaseUrl}/search`;
        if (!!urlParamsObj.toString()) {
            url += '?' + urlParamsObj.toString();
        }

        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return await response.json();
        }
        try {
            console.error(await response.text());
            throw new Error('Error encountered while getting screenings records');
        } catch (e) {
            console.error(e);
            throw new Error('Error encountered while getting screenings records');
        }
    }

    static async getEmployeeIdsWithFutureTerminationDates(
        authContext: IAuthContext,
        personnelIds: string[],
        correlationId?: string,
    ): Promise<string[]> {
        const promiseArray: Promise<string[]>[] = [];
        // make sure only fulltime employee (non GUID ids) unique ids are passed in as query
        const uniquePersonnelIds = [...new Set(personnelIds.filter((x) => !isGUID(x)))];
        const returnEmployees: string[] = [];

        if (uniquePersonnelIds.length > 0) {
            for (
                let startIndex = 0;
                startIndex < uniquePersonnelIds.length;
                startIndex += MaxScreeningEmployeeQuery
            ) {
                const personnelIdsChunk = uniquePersonnelIds.slice(
                    startIndex,
                    startIndex + MaxScreeningEmployeeQuery,
                );
                if (personnelIdsChunk.length > 0) {
                    promiseArray.push(
                        UsGovScreeningClient._employeeIdsWithFutureTerminationDatesHelper(
                            authContext,
                            personnelIdsChunk,
                            correlationId,
                        ),
                    );
                }
            }

            const resolved = await Promise.allSettled(promiseArray);
            resolved.forEach((x) => {
                if (x.status === 'rejected') {
                    throw x.reason;
                } else {
                    x.value.forEach((employee) => returnEmployees.push(employee));
                }
            });
        }

        return returnEmployees;
    }

    private static async _employeeIdsWithFutureTerminationDatesHelper(
        authContext: IAuthContext,
        personnelIds: string[],
        correlationId?: string,
    ): Promise<string[]> {
        const urlParams = new URLSearchParams();
        if (!!correlationId) {
            urlParams.append('correlationId', correlationId);
        }

        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        const url = `${
            this.screeningBaseUrl
        }/EmployeeIdsWithFutureTerminationDates?${urlParams.toString()}`;
        httpOptions.body = JSON.stringify(personnelIds);
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const jsonResponse = (await res.json()) as string[];
            return jsonResponse;
        } else {
            throw res;
        }
    }

    /*
    Changed return from this._getScreenings() to PublicTrustScreeningClient._getScreenings() because in personnel-profile-page.tsx,
    the personnel-profile-screening-table component receives a queryMethod parameter which is of type Function.
    */
    static async getPagedScreeningsByEmployee(
        authContext: IAuthContext,
        employee: IEmployee,
        pageSize: number,
        userType?: string,
        continuationToken?: string,
    ): Promise<IScreeningResult> {
        const urlParams = new URLSearchParams();
        urlParams.append('resultCount', `${pageSize}`);
        urlParams.append('personnelId', employee.id);

        if (userType) {
            urlParams.append('userType', userType);
        }

        return UsGovScreeningClient._getScreenings(authContext, urlParams, continuationToken);
    }

    private static async _getScreenings(
        authContext: IAuthContext,
        urlParam: URLSearchParams,
        continuationToken?: string,
    ): Promise<IScreeningResult> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const url = `${this.screeningBaseUrl}?${urlParam.toString()}`;
        return ClientUtils.doHttpRequest<IScreeningResult>(url, httpOptions);
    }

    /**
     * gets customer metric for a select personnelID or the current user via authContext.
     * @param authContext
     * @param screeningId Optional parameter if we want to find the customer metrics to a specific user
     * @returns customer metric for a select personnelID or the current user via authContext
     */
    static async getCustomerMetrics(
        authContext: IAuthContext,
        screeningId: string,
    ): Promise<ICustomerMetricsBackend> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.screeningBaseUrl}/customerMetrics/${screeningId}`;
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    }

    static async getSelectCustomerMetrics(
        authContext: IAuthContext,
        screeningIds: string[],
    ): Promise<ICustomerMetricsBackend[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);

        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(screeningIds);
        const url = `${this.screeningBaseUrl}/customerMetrics`;
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            return res.json();
        } else {
            throw res;
        }
    }

    /**
     * gets US Gov Screening customer metrics summary Data
     * @param authContext
     * @param summaryType Optional parameter if we want to get bar chart data for a different US Gov Screening page
     * @returns Dictionary of bar chart data in key-value pair and text information to display next to the bar chart
     */
    static async getCustomerMetricsSummaryDataBasedOnParams(
        authContext: IAuthContext,
        urlParams: URLSearchParams,
        summaryType?: SummaryDataTypeEnum,
    ): Promise<ICustomerMetricsSummaryData> {
        const urlParamsObj = new URLSearchParams(urlParams);

        if (summaryType !== undefined) {
            urlParamsObj.append('summaryType', summaryType.toString());
        }

        let url = `${this.screeningBaseUrl}/CustomerMetricsData`;
        if (!!urlParamsObj.toString()) {
            url += '?' + urlParamsObj.toString();
        }

        const httpOptions = await GetDefaultHttpOptions(authContext);
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const jsonResponse = (await res.json()) as ICustomerMetricsSummaryData;
            return jsonResponse;
        } else {
            throw res;
        }
    }

    /**
     * gets US Gov Screening bar chart data
     * @param authContext
     * @param summaryType Optional parameter if we want to get bar chart data for a different US Gov Screening page
     * @returns Dictionary of bar chart data in key-value pair and text information to display next to the bar chart
     */
    static async getBarChartData(
        authContext: IAuthContext,
        urlParams: URLSearchParams,
        summaryType?: SummaryDataTypeEnum,
    ): Promise<IBarChartData> {
        const urlParamsObj = new URLSearchParams(urlParams);
        if (summaryType !== undefined) {
            urlParamsObj.append('summaryType', summaryType.toString());
        }

        let url = `${this.screeningBaseUrl}/BarChartData`;
        if (!!urlParamsObj.toString()) {
            url += '?' + urlParamsObj.toString();
        }

        const httpOptions = await GetDefaultHttpOptions(authContext);
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const jsonResponse = (await res.json()) as IBarChartData;
            return jsonResponse;
        } else {
            throw res;
        }
    }

    /**
     * gets all Customer metrics
     * @param authContext
     * @param screeningId Optional parameter if we want to find the customer metrics to a specific user
     * @returns Dictionary of reports to for a select personnelID or the current user via authContext
     */
    static async getAllCustomerMetrics(
        authContext: IAuthContext,
    ): Promise<ICustomerMetricsBackend[]> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${this.screeningBaseUrl}/customerMetrics/all`;
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const jsonResponse = (await res.json()) as ICustomerMetricsBackend[];
            return jsonResponse;
        } else {
            throw res;
        }
    }

    /**
     * gets a Dictionary of reports to for a select personnelID or the current user via authContext.
     * (key is the personnel id of the employee A, value is the personnel id of the employee B that Employee A reports to)
     * @param authContext
     * @param personnelId Optional parameter if we want to find the reports to a specific user
     * @param continuationToken
     * @returns Dictionary of reports to for a select personnelID or the current user via authContext
     */
    static async getReportToScreening(
        authContext: IAuthContext,
        personnelId?: string,
        continuationToken?: string,
    ): Promise<Dictionary<number>> {
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        let url = `${this.screeningBaseUrl}/ReportTo`;
        if (personnelId) {
            url = `${url}?personnelId=${personnelId}`;
        }
        return ClientUtils.doHttpRequest<Dictionary<number>>(url, httpOptions);
    }

    static async getAssociatedDocumentUserToken(
        authContext: IAuthContext,
        associatedUserId: string,
        service: ApplicationServices,
    ): Promise<IDocumentToken> {
        const { baseUrl, document } = config.nominationServiceConfig;
        const url: string =
            baseUrl +
            document.associatedUserToken.replace('{userId}', associatedUserId) +
            `?applicationName=${service}`;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        return ClientUtils.doHttpRequest<IDocumentToken>(url, httpOptions);
    }

    /**
     * @returns status of the contract, ie, Active; Inactive or Disabled, or undefined.
     */
    static async validateContract(
        authContext: IAuthContext,
        contractId: string,
        contractType: keyof typeof ContractType,
    ): Promise<ContractStatus | undefined> {
        const url = nominationConfig.baseUrl + nominationConfig.contractsEndpoint + `/validate`;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify({
            Id: contractId,
            Type: contractType,
        });
        try {
            const res = await fetch(url, httpOptions);
            if (res.status === 200) {
                return (await res.json()) as ContractStatus;
            }
            throw res;
        } catch (e) {
            if (e.status === 404) {
                return undefined;
            }
            throw e;
        }
    }

    static async getContractById(
        authContext: IAuthContext,
        contractId: string,
    ): Promise<IContract> {
        const url =
            nominationConfig.baseUrl + nominationConfig.contractsEndpoint + `/${contractId}`;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else if (result.status === 404) {
            throw `Contract with the specified ID: ${contractId} was not found`;
        } else {
            throw result;
        }
    }

    /*
    Contracts are shared between US Gov and Public Trust, so added isPublicTrust bool to method rather than
    duplicating this method in public-trust-screening-client.
    */
    static async getContractsByContractIds(
        authContext: IAuthContext,
        contractIds: string[],
        isPublicTrust: boolean,
        continuationToken?: string,
    ): Promise<IContract[]> {
        const { publicTrustContractsEndpoint, contractsEndpoint } = nominationConfig;
        const url =
            nominationConfig.baseUrl +
            `${isPublicTrust ? publicTrustContractsEndpoint : contractsEndpoint}` +
            `/getContractsbyIds`;
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(contractIds);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 404:
                throw `Contracts was not found`;
            default:
                throw result;
        }
    }

    static async getContracts(
        authContext: IAuthContext,
        contractTypes: string[],
        continuationToken?: string,
    ): Promise<IPagedResults<IContract>> {
        const { baseUrl, contractsEndpoint } = nominationConfig;
        const url = baseUrl + contractsEndpoint;

        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);

        const urlParams = new URLSearchParams();
        contractTypes.forEach((contractType) => {
            urlParams.append(ContractRequestParamNames.ContractTypes, contractType);
        });

        const result = await fetch(`${url}?${urlParams.toString()}`, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async getContractsByOwnerId(
        authContext: IAuthContext,
        ownerId: string,
        continuationToken?: string,
    ): Promise<IPagedResults<IContract>> {
        const { baseUrl, contractsEndpoint } = nominationConfig;
        const url = `${baseUrl}${contractsEndpoint}/owner/details/${ownerId}`;
        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async createContract(
        authContext: IAuthContext,
        request: IContractRequest,
    ): Promise<IContract> {
        const url = nominationConfig.baseUrl + nominationConfig.contractsEndpoint;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(request);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 201:
            case 200:
                return result.json();
            case 409:
                throw `A contract already exists with the same ID`;
            default:
                throw result;
        }
    }

    // GET Agencies.
    static async getAgencies(
        authContext: IAuthContext,
        continuationToken?: string,
    ): Promise<IPagedResults<IAgency>> {
        const { baseUrl, agenciesEndpoint } = nominationConfig;
        const url = baseUrl + agenciesEndpoint;

        const httpOptions = await GetDefaultHttpOptions(authContext, continuationToken);

        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    static async downloadScreeningReport(
        authContext: IAuthContext,
        urlParams: URLSearchParams,
        summaryType?: SummaryDataTypeEnum,
    ): Promise<string> {
        const urlParamsObj = new URLSearchParams(urlParams);

        if (summaryType !== undefined) {
            urlParamsObj.append('summaryType', summaryType.toString());
        }

        let url = `${this.screeningBaseUrl}/DownloadUSGovScreeningReport`;
        if (!!urlParamsObj.toString()) {
            url += '?' + urlParamsObj.toString();
        }

        const httpOptions = await GetDefaultHttpOptions(
            authContext,
            '',
            HttpContentTypeEnum.TextPlain,
        );

        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            default:
                throw `Unable to generate and return US Gov Screenings Report: ${result}`;
        }
    }

    static async editContractById(
        authContext: IAuthContext,
        request: IContractRequest,
    ): Promise<IContract> {
        const contractId = request.id;
        const url =
            nominationConfig.baseUrl + nominationConfig.contractsEndpoint + `/${contractId}`;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(request);
        const result = await fetch(url, httpOptions);
        switch (result.status) {
            case 200:
                return result.json();
            case 400:
                throw await result.text();
            case 404:
                throw `Contract ${contractId} was not found`;
            default:
                throw result;
        }
    }

    static async searchContracts(search: string, authContext: IAuthContext): Promise<any> {
        const MAX_CONTRACTS_TO_DISPLAY = 4; //this can be improved
        const url =
            nominationConfig.baseUrl +
            nominationConfig.contractsEndpoint +
            `?${ContractRequestParamNames.Search}=` +
            search +
            `&${ContractRequestParamNames.ResultCount}=` +
            MAX_CONTRACTS_TO_DISPLAY;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        return (await ClientUtils.doHttpRequest<any>(url, httpOptions)).results;
    }

    static async searchContractsByUserType(
        userType: string,
        id: string,
        authContext: IAuthContext,
    ): Promise<IContract[]> {
        const tailUrl = userType !== '' ? `/ByView/${userType}/${id}` : '';
        const url = `${nominationConfig.baseUrl}${nominationConfig.contractsEndpoint}${tailUrl}`;
        const httpOptions = await GetDefaultHttpOptions(authContext);
        return (await ClientUtils.doHttpRequest<any>(url, httpOptions)).results;
    }

    static async uploadScreeningRecords(
        authContext: IAuthContext,
        request: IScreeningRecordUploadRequest[],
    ): Promise<string | undefined> {
        const httpOptions = await GetDefaultHttpOptions(authContext);
        const url = `${nominationConfig.baseUrl}${nominationConfig.screeningsEndpoint}/upload`;

        httpOptions.method = 'PUT';
        httpOptions.body = JSON.stringify(request);

        const result = await fetch(url, httpOptions);

        switch (result.status) {
            case 200:
                return result.text();
            case 204:
                return;
            default:
                const text = await result.text();
                console.error(text);
                let json;
                try {
                    json = JSON.parse(text);
                } catch {}
                throw new Error(json?.message || result);
        }
    }
}

export default UsGovScreeningClient;

export interface IScreeningUserTypes {
    types: string[];
}

// functions
export function hasUserType(
    userTypes: IScreeningUserTypes,
    userType: UsGovScreeningUserType,
): boolean {
    return userTypes.types.findIndex((type) => type.toLowerCase() === userType.toLowerCase()) > -1;
}

export interface IScreeningRecordUSGovExcelRequest {
    ScreeningRecordId: string;
    contractId?: string;
    nominatedById: string;
    personnelId: string;
    ProcessOwnerId?: string;
}

export interface IScreeningRecordUSGovExcelResponse {
    action: string;
    clearance: string;
    contractId?: string;
    contractCustomer: string;
    contractProjectName: string;
    customerBadgeRequired: boolean;
    employeeStatus: string;
    employeeTitle: string;
    geographicLocation: string;
    l1: string;
    l2: string;
    l3: string;
    l4: string;
    l5: string;
    l6: string;
    nominatedBy: string;
    nominatedOn: number;
    officeLocation: string;
    organization: string;
    personnelAlias: string;
    personnelFirstName: string;
    personnelLastName: string;
    personnelMiddleName?: string;
    personnelId: string;
    personStatusCode: string;
    processOwner: string;
    reportsTo: string;
    requestType: string;
    screeningRecordId: string;
    screeningView: string;
    status: StateName;
    statusChangedOn: number;
    subStatus: string;
    terminationDateUTCSeconds: number;
}

// Note that IUsGovScreeningToken only defines a small subset
// of the properties of the Screening token data structure.
// Feel free to add to it if you needed to.
export interface IUsGovScreeningToken {
    roles: ScreeningRole[];
}

export interface IScreeningSummaryDataResponse extends IBaseSummaryDataResponse {
    totalRecordCount: number;
}

export enum SummaryDataTypeEnum {
    ClearanceRecords = 'ClearanceRecords',
    PublicTrustManage = 'PublicTrustManage',
    PublicTrustMyContracts = 'PublicTrustMyContracts',
    PublicTrustMyNominees = 'PublicTrustMyNominees',
    PublicTrustMyOrg = 'PublicTrustMyOrg',
    PublicTrustMyScreenings = 'PublicTrustMyScreenings',
    SuitabilityRecords = 'SuitabilityRecords',
    UsGovManage = 'UsGovManage',
    UsGovMyContracts = 'UsGovMyContracts',
    UsGovMyNominees = 'UsGovMyNominees',
    UsGovMyOrg = 'UsGovMyOrg',
    UsGovMyScreenings = 'UsGovMyScreenings',
}

export interface IScreeningRecordUploadRequest {
    microsoftEmployeeOrVendor: string;
    id: string;
    personnelId: string;
    microsoftAlias: string;
    firstName: string;
    lastName: string;
    governmentContractNumber: string;
    requestType: ScreeningRequestTypes;
    clearanceLevel: ClearanceLevelType;
    specialAccess: IAccesses;
    customerBadgeRequired: string;
    processOwner: string;
    screeningStatus: ScreeningParentStateType;
    screeningSubStatus: ScreeningStateType;
    adjudicatedDecision: AdjudicatedDecision;
    adjudicatedDate: string;
    businessJustification: string;
    eQIP: string;
    preHireFirstName: string;
    preHireMiddleName: string;
    preHireLastName: string;
    preHireSuffix: string;
    preHirePcn: string;
    preHireManager: string;
    preHireJobTitle: string;
    preHireStartDate: string;
    preHirePersonalPhoneNumber: string;
    preHirePersonalEmailAddress: string;
    preHireHomeAddress: string;
    uploadStatus?: string; // Will be available in upload result. Is ok to be available in request data.
    uploadComment?: string; // Will be available in upload result. Is ok to be available in request data.
}
