import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Location } from 'history';
import {
    ActionButton,
    CommandBar,
    DetailsList,
    IButtonStyles,
    ICommandBarItemProps,
    mergeStyleSets,
    MessageBarType,
    SelectionMode,
    Selection,
    Stack,
    StackItem,
    Toggle,
} from '@fluentui/react';
import { IconNames, xLargeMaxWidthCoeff } from 'assets/constants/global-constants';
import { doNothing } from 'utils/misc-utils';
import { globalStyles } from 'assets/styles/global-styles';
import { TableCell } from 'components/common/table';
import EllipsisTextCss from 'components/common/ellipsis-text-css';
import GroupClient, {
    GroupRole,
    ICANDAMetadata,
    IGroupRule,
    IUserAccessReviewSubtype,
    lifecycleEventOptionsKeys,
} from 'clients/group-client';
import { AuthContext } from 'contexts/auth-context';
import { CoreEmployeeHoverCardFromPrincipalId } from 'components/core/common/employee-card/core-employee-hover-card';
import MyGroupsActionCenterPOCPanel from 'components/groups/my-groups/my-groups-action-center-poc-panel';
import LabelInfoIcon from 'components/common/use-input/info-icon-label';
import { useHistory } from 'react-router-dom';
import { useBoolean } from '@fluentui/react-hooks';
import { enumNameToDisplayString } from 'utils/enum-utils';
import {
    ApproveOrDenyUarModal,
    ApproveOrDenyUarModalMode,
} from 'components/groups/agreement/approve-or-deny-uar-modal';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import useMessageBar from 'components/common/use-message-bar';

import { useFetchSimple } from 'utils/misc-hooks';
import IsLoadingIndicator from 'components/common/is-loading-indicator';
import Spacer from 'components/common/spacer';

interface IMyGroupsActionCenterPageProps {
    location: Location;
}

export default function MyGroupsActionCenterPage(
    props: IMyGroupsActionCenterPageProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [candaMetadata, setCandaMetadata] = useState<ICANDAMetadata[]>([]);
    const [displayedCANDAMetadata, setDisplayedCANDAMetadata] = useState<ICANDAMetadata[]>([]);
    const [myGroupRoles, setMyGroupRoles] = useState<Map<string, GroupRole>>();
    const [groupRules, setGroupRules] = useState<IGroupRule[]>([]);
    const [showPointsOfContactPanel, setShowPointsOfContactPanel] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [showApproveDenyModal, setShowApproveDenyModal] = useState(false);
    const [showApproveDenyModalType, setShowApproveDenyModalType] = useState<
        ApproveOrDenyUarModalMode
    >();
    const [selectedUars, setSelectedUars] = useState<ICANDAMetadata[]>();
    const history = useHistory();
    const [showDeniedUARs, { toggle: toggleShowDeniedUARs }] = useBoolean(false);
    const [isDeniedUarsVisible, setIsDeniedUarsVisible] = useState(false);
    const [shouldFetchUARRecords, setShouldFetchUARRecords] = useState(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const principalUserContext = useContext(PrincipalUserContext);

    const principalId = useMemo((): string => {
        return principalUserContext.principalRecord.id;
    }, [principalUserContext.principalRecord]);

    useFetchSimple({
        dependencies: [authContext, shouldFetchUARRecords],
        canPerformFetch: shouldFetchUARRecords,
        fetchFunc: async () => {
            setIsLoading(true);
            setShouldFetchUARRecords(false);
            return GroupClient.getValidUars(authContext);
        },
        onSuccess: (result) => {
            setCandaMetadata(result);
        },
        onError: (error: Error) => {
            console.error(error);
        },
        onFinally: () => {
            setIsLoading(false);
        },
    });

    useFetchSimple({
        dependencies: [authContext, candaMetadata],
        canPerformFetch: true,
        fetchFunc: async () => {
            setIsLoading(true);
            return await Promise.all(
                candaMetadata.map((uar) => GroupClient.listGroupRules(authContext, uar.groupId)),
            ).then((results) => results.flat());
        },
        onSuccess: (result) => {
            setGroupRules(result);
        },
        onError: (error: Error) => {
            console.error(error);
        },
        onFinally: () => {
            setIsLoading(false);
        },
    });

    useFetchSimple({
        dependencies: [authContext],
        canPerformFetch: true,
        fetchFunc: async () => {
            return GroupClient.getMyGroups(authContext);
        },
        onSuccess: (result) => {
            setMyGroupRoles(
                result.results.reduce((acc, group) => acc.set(group.id, group.role), new Map()),
            );
        },
        onError: (error: Error) => {
            console.error(error);
        },
        onFinally: doNothing,
    });

    useEffect(() => {
        setDisplayedCANDAMetadata(
            candaMetadata.filter((uar) => (showDeniedUARs ? uar.isDenied : !uar.isDenied)),
        );
    }, [candaMetadata, showDeniedUARs]);

    const commandBarLeftItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'approve',
                text: 'Approve UAR',
                onClick: () => {
                    setShowApproveDenyModal(true);
                    setShowApproveDenyModalType(
                        isApproveDenyButtonsEnabled()[1]
                            ? ApproveOrDenyUarModalMode.Approve
                            : ApproveOrDenyUarModalMode.ApproveBlocked,
                    );
                },
                iconProps: { iconName: IconNames.CheckMark },
                disabled: !isApproveDenyButtonsEnabled()[0],
                leftCommandBarButtonStyle,
            },
            {
                key: 'deny',
                text: 'Deny UAR',
                onClick: () => {
                    setShowApproveDenyModal(true);
                    setShowApproveDenyModalType(
                        isApproveDenyButtonsEnabled()[1]
                            ? ApproveOrDenyUarModalMode.Deny
                            : ApproveOrDenyUarModalMode.DenyBlocked,
                    );
                },
                iconProps: { iconName: IconNames.Cancel },
                disabled: !isApproveDenyButtonsEnabled()[0],
                leftCommandBarButtonStyle,
            },
        ];
    }, [selectedUars]);

    function isApproveDenyButtonsEnabled() {
        if (selectedUars && selectedUars.length === 0) {
            return [false, false];
        }

        if (groupRules && groupRules.length === 0) {
            return [false, false];
        }

        if (!groupRules.some((rule) => selectedUars?.find((uar) => uar.ruleId === rule.id))) {
            return [false, false];
        }

        const groupRulesForSelected = groupRules.filter((rule) =>
            selectedUars?.find((uar) => uar.ruleId === rule.id),
        );

        return [
            true,
            groupRulesForSelected.every(
                (rule) => rule.disableBulkUarActions === undefined || !rule.disableBulkUarActions,
            ),
        ];
    }

    const commandBarRightItems: ICommandBarItemProps[] = useMemo((): ICommandBarItemProps[] => {
        return [
            {
                key: 'deniedUarsToggle',
                commandBarButtonAs: () => (
                    <div
                        style={{ marginTop: '6px', marginLeft: '10px' }}
                        aria-label='Toggle to view or hide denied UARs'>
                        <Toggle
                            label='View denied UARs'
                            inlineLabel
                            onText='Yes'
                            offText='No'
                            checked={isDeniedUarsVisible}
                            onChange={toggleShowDeniedUARs}
                            onClick={(): void => {
                                setIsDeniedUarsVisible(!isDeniedUarsVisible);
                            }}
                        />
                    </div>
                ),
            },
        ];
    }, [isDeniedUarsVisible]);

    const generateStatusForUar = (uar: ICANDAMetadata): JSX.Element => {
        if (uar.isSigned && !uar.isDenied) {
            return (
                <Stack horizontal verticalAlign='center'>
                    <StackItem className={classNames.approved}>
                        <LabelInfoIcon iconName={IconNames.Completed} iconHoverContent={''}>
                            &nbsp;
                        </LabelInfoIcon>
                    </StackItem>
                    <StackItem>Approved</StackItem>
                </Stack>
            );
        } else if (uar.isDenied) {
            return (
                <Stack horizontal verticalAlign='center'>
                    <StackItem className={classNames.denied}>
                        <LabelInfoIcon iconName={IconNames.ErrorBadge} iconHoverContent={''}>
                            &nbsp;
                        </LabelInfoIcon>
                    </StackItem>
                    <StackItem> Denied</StackItem>
                </Stack>
            );
        } else {
            return (
                <Stack horizontal verticalAlign='center'>
                    <StackItem className={classNames.noresponse}>
                        <LabelInfoIcon iconName={IconNames.Blocked2} iconHoverContent={''}>
                            &nbsp;
                        </LabelInfoIcon>
                    </StackItem>
                    <StackItem>No response</StackItem>
                </Stack>
            );
        }
    };

    const getGroupRoleFromMyGroupsAndGroupId = (groupId: string): string => {
        let role = GroupRole.MEMBER;
        if (myGroupRoles && myGroupRoles.get(groupId)) {
            role = myGroupRoles.get(groupId) as GroupRole;
        }
        return enumNameToDisplayString(role);
    };

    const columnsFilterable = useMemo(() => {
        return [
            {
                key: UserAccessReviewListColumnKeys.User,
                name: userAccessReviewColumnNames.User,
                fieldName: columnFieldNames.User,
                ariaLabel: userAccessReviewColumnNames.User,
                minWidth: columnWidths.user,
                maxWidth: columnWidths.user * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            <CoreEmployeeHoverCardFromPrincipalId
                                principalId={item.personnelId}
                                showMiniCardAlias={false}
                            />
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
            {
                key: UserAccessReviewListColumnKeys.Group,
                name: userAccessReviewColumnNames.Group,
                fieldName: columnFieldNames.Group,
                ariaLabel: userAccessReviewColumnNames.Group,
                minWidth: columnWidths.group,
                maxWidth: columnWidths.group * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            <EllipsisTextCss text={item.groupName} />
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
            {
                key: UserAccessReviewListColumnKeys.LifeCycleEvent,
                name: userAccessReviewColumnNames.LifeCycleEvent,
                fieldName: columnFieldNames.LifeCycleEvent,
                ariaLabel: userAccessReviewColumnNames.LifeCycleEvent,
                minWidth: columnWidths.lifeCycleEvent,
                maxWidth: columnWidths.lifeCycleEvent * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            <EllipsisTextCss text={getFormattedLifecycleEvent()} />
                        </TableCell>
                    );

                    function getFormattedLifecycleEvent(): string {
                        if (
                            item.ruleSubType.toString() ===
                            lifecycleEventOptionsKeys.DurationLifecycle.toString()
                        ) {
                            // comparing to lifecycleEventOptionsKeys.DurationLifecycle.toString() because this is the enum used when creating the UAR record
                            return 'Duration';
                        } else if (
                            item.ruleSubType.toString() ===
                            IUserAccessReviewSubtype.AssignmentChange
                        ) {
                            return 'Assignment Change';
                        } else return item.ruleSubType.toString();
                    }
                },
                isFilterable: false,
            },
            {
                key: UserAccessReviewListColumnKeys.Status,
                name: userAccessReviewColumnNames.Status,
                fieldName: columnFieldNames.Status,
                ariaLabel: userAccessReviewColumnNames.Status,
                minWidth: columnWidths.status,
                maxWidth: columnWidths.status * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            {generateStatusForUar(item)}
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
            {
                key: UserAccessReviewListColumnKeys.GroupPOC,
                name: userAccessReviewColumnNames.GroupPOC,
                fieldName: columnFieldNames.GroupPOC,
                ariaLabel: userAccessReviewColumnNames.GroupPOC,
                minWidth: columnWidths.groupPOC,
                maxWidth: columnWidths.groupPOC * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            <ActionButton
                                text='View'
                                iconProps={{ iconName: IconNames.View }}
                                onClick={(): void => {
                                    setSelectedGroup(item.groupId);
                                    setShowPointsOfContactPanel(true);
                                }}
                            />
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
            {
                key: UserAccessReviewListColumnKeys.MyRole,
                name: userAccessReviewColumnNames.MyRole,
                fieldName: columnFieldNames.MyRole,
                ariaLabel: userAccessReviewColumnNames.MyRole,
                minWidth: columnWidths.myRole,
                maxWidth: columnWidths.myRole * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            <EllipsisTextCss
                                text={getGroupRoleFromMyGroupsAndGroupId(item.groupId)}
                            />
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
            {
                key: UserAccessReviewListColumnKeys.Actions,
                name: userAccessReviewColumnNames.Actions,
                fieldName: columnFieldNames.Actions,
                ariaLabel: userAccessReviewColumnNames.Actions,
                minWidth: columnWidths.actions,
                maxWidth: columnWidths.actions * xLargeMaxWidthCoeff,
                isRowHeader: true,
                isResizable: true,
                onRender: (item: ICANDAMetadata): JSX.Element => {
                    return (
                        <TableCell className={classNames.detailsListField}>
                            <ActionButton
                                text='View UAR'
                                iconProps={{ iconName: IconNames.View }}
                                onClick={(): void => {
                                    history.push(
                                        `/groups/${item.groupId}/agreements/${item.ruleId}/${item.personnelId}`,
                                    );
                                }}
                            />
                        </TableCell>
                    );
                },
                isFilterable: false,
            },
        ];
    }, [myGroupRoles]);

    const {
        theMessage: signErrMsg,
        theElement: signErrMsgElement,
        setMessage: setSignErrMsg,
    } = useMessageBar({
        type: MessageBarType.error,
    });

    const onSuccess = (): void => {
        setShowApproveDenyModal(false);
        setShowApproveDenyModalType(undefined);
        setShouldFetchUARRecords(true);
    };

    const selection: Selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                setSelectedUars((selection.getSelection() as ICANDAMetadata[]) ?? undefined);
            },
        });
    }, []);

    return (
        <>
            <h2 style={{ paddingLeft: '5px' }}>User Access Reviews</h2>
            <CommandBar
                styles={{
                    root: {
                        paddingTop: 10,
                        paddingLeft: 10,
                        background: globalStyles.backgroundColor,
                    },
                }}
                items={commandBarLeftItems}
                farItems={commandBarRightItems}
            />
            {myGroupRoles && myGroupRoles.size > 0 && (
                <>
                    <DetailsList
                        columns={columnsFilterable}
                        items={displayedCANDAMetadata}
                        selectionMode={SelectionMode.multiple}
                        selection={selection}
                        className={classNames.detailsList}
                        checkboxVisibility={1}
                    />
                    <IsLoadingIndicator
                        isLoading={isLoading}
                        before={<Spacer marginTop={20} />}
                        msg='Loading UAR records...'
                    />
                </>
            )}

            <MyGroupsActionCenterPOCPanel
                isOpen={showPointsOfContactPanel}
                onDismiss={(): void => {
                    setShowPointsOfContactPanel(false);
                }}
                groupId={selectedGroup!}
            />
            {showApproveDenyModalType && (
                <ApproveOrDenyUarModal
                    show={showApproveDenyModal}
                    onDismiss={(): void => {
                        setShowApproveDenyModal(false);
                        setShowApproveDenyModalType(undefined);
                    }}
                    approveOrDenyMode={showApproveDenyModalType}
                    onSuccess={onSuccess}
                    agreementMetadata={selectedUars!}
                    onError={(approveOrDeny: string): void =>
                        setSignErrMsg(
                            `Error occurred while trying to ${approveOrDeny} the agreement`,
                        )
                    }
                    principalId={principalId}
                />
            )}
        </>
    );
}

const leftCommandBarButtonStyle: IButtonStyles = {
    root: { marginRight: '15px' },
};

const columnWidths = {
    user: 100,
    group: 100,
    lifeCycleEvent: 100,
    status: 100,
    groupPOC: 100,
    myRole: 100,
    actions: 100,
};

export enum UserAccessReviewListColumnKeys {
    User = 'user',
    Group = 'group',
    LifeCycleEvent = 'lifecycleEvent',
    Status = 'status',
    GroupPOC = 'groupPOC',
    MyRole = 'myRole',
    Actions = 'actions',
}

// filterableTable uses "key" to filter
export enum userAccessReviewColumnNames {
    User = 'User',
    Group = 'Group',
    LifeCycleEvent = 'Lifecycle event',
    Status = 'Status',
    GroupPOC = 'Group points of contact',
    MyRole = 'My role',
    Actions = 'Actions',
}

// filterableTable uses "name" as the table header text
enum columnFieldNames {
    User = 'User',
    Group = 'Group',
    LifeCycleEvent = 'Lifecycle event',
    Status = 'Status',
    GroupPOC = 'Group points of contact',
    MyRole = 'My role',
    Actions = 'Actions',
}

const classNames = mergeStyleSets({
    denied: [{ color: 'red' }],
    noresponse: [{ color: 'grey' }],
    approved: [{ color: 'green' }],
    detailsListField: {
        display: 'flex',
        alignItems: 'center',
    },
    detailsList: {
        '& .is-selected': {
            background: 'white !important',
            borderBottom: '1px solid rgb(243, 242, 241)',
        },
    },
});
