import { DefaultButton, Panel, PanelType, PrimaryButton, VerticalDivider } from '@fluentui/react';
import GroupClient, { IGroup, PocTypeEnum } from 'clients/group-client';
import HorizontalBar from 'components/common/horizontal-bar';
import {
    CoreEmployeeHoverCardFromGraph,
    CoreEmployeeHoverCardFromPrincipalId,
} from 'components/core/common/employee-card/core-employee-hover-card';
import CoreEmployeeMiniCard from 'components/core/common/employee-card/core-employee-mini-card';
import { AuthContext } from 'contexts/auth-context';
import React, { useContext, useState } from 'react';
import { useFetchSimple } from 'utils/misc-hooks';
import { doNothing } from 'utils/misc-utils';
import { Link } from 'react-router-dom';
import { mediumGrey } from 'assets/constants/global-colors';

export interface IMyGroupsActionCenterPOCPanelProps {
    isOpen?: boolean;
    onDismiss: () => void;
    groupId?: string;
}
export default function MyGroupsActionCenterPOCPanel(
    props: IMyGroupsActionCenterPOCPanelProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [uarGroups, setUarGroups] = useState<IGroup[]>();
    const buttonStyles = { root: { marginRight: 8 } };

    useFetchSimple({
        dependencies: [authContext],
        canPerformFetch: true,
        fetchFunc: async () => {
            return GroupClient.getGroups(authContext);
        },
        onSuccess: (result) => {
            setUarGroups(result.results);
        },
        onError: (error: Error) => {
            console.error(error);
        },
        onFinally: doNothing,
    });

    const onCancel = (): void => {
        props.onDismiss();
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <DefaultButton onClick={onCancel}>Close</DefaultButton>
            </div>
        ),
        [onCancel],
    );

    return (
        <Panel
            headerText='Group points of contact'
            isOpen={props.isOpen}
            onDismiss={() => {
                props.onDismiss();
            }}
            closeButtonAriaLabel='Close'
            isLightDismiss
            onRenderFooterContent={onRenderFooterContent}
            type={PanelType.custom}
            customWidth={'400px'}
            styles={styles.panel}
            isFooterAtBottom={true}>
            <div>
                <h3>Individual POCs</h3>
                <div>
                    {props.groupId &&
                        uarGroups &&
                        uarGroups
                            .find((group) => group.id === props.groupId!)
                            ?.pointsOfContact.filter(
                                (poc) => poc.contactValueType === PocTypeEnum.Employee,
                            )
                            .map((poc) => {
                                return (
                                    <CoreEmployeeHoverCardFromPrincipalId
                                        key={poc.contactValue}
                                        principalId={poc.contactValue}
                                        showFullName={false}
                                        showMiniCardAlias={false}
                                    />
                                );
                            })}
                </div>
                <hr style={styles.divider}></hr>
                <h3>Email Distribution POCs</h3>
                <div>
                    {props.groupId &&
                        uarGroups &&
                        uarGroups
                            .find((group) => group.id === props.groupId!)
                            ?.pointsOfContact.filter(
                                (poc) => poc.contactValueType === PocTypeEnum.DistributionList,
                            )
                            .map((poc) => {
                                return (
                                    <Link
                                        key={poc.contactValue}
                                        href={`mailto:${poc.contactValue}`}
                                        to={`mailto:${poc.contactValue}`}
                                    />
                                );
                            })}
                </div>
            </div>
        </Panel>
    );
}

const styles = {
    panel: {
        root: {
            marginTop: '65px',
            marginBottom: '85px',
        },
    },
    divider: {
        color: mediumGrey,
        width: 'auto',
        marginBottom: '0px',
        marginTop: '20px',
        marginRight: '0px',
        marginLeft: '0px',
    },
};
