/**
 * File Name: document-table
 * Team: Personnel - Screening Team
 * Summary: This is the 'Unified' document table of Screening Details of a Screening. This will have the following 3 documents in it:
 * 'Request' Document requests, Uploaded Documents (done via user ie User's uploaded documents via Document requests), and 'Adhoc' documents
 * (adhoc documents are just manually added, by NST, documents via Add button in the table)
 * Use Cases:
 * 1) Allows NST upload adhoc Document
 * 2) Allows NST request documents from Screening's User
 * 3) Allows NST and Screening's User view all 3 document types and interact with each.
 * Last Updated Summary: 10/7/2022 by andreli
 **/
import React, { useState, useContext, useEffect } from 'react';
import { SharedColors } from '@fluentui/theme';
import { Icon, IColumn } from '@fluentui/react';
import DocumentsClient, {
    emptyNoContentIDocumentToken,
    IDocumentRecord,
    IDocumentToken,
    IDocumentTypeItem,
} from 'clients/documents-client';
import TemplateClient, {
    IScreeningTemplateRequestRecord,
    IDocumentMetadata,
    isIScreeningTemplateRequestRecordRequestingUpdate,
    ScreeningTemplateRequestedState,
    AssociationType,
    AssociationValue,
    isIScreeningTemplateRequestRecordOutstanding,
} from 'clients/template-client';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import { EmployeeNameResolverId } from 'components/common/employee/employee-name-resolver';
import { ActivityEventBin, binSortEvents } from 'components/common/event';
import { AuthContext } from 'contexts/auth-context';
import { unixTimeStampToFormattedDateString } from 'utils/time-utils';
import { ScreeningPaths } from 'components/screening/common/common-constants';
import ViewUploadedDocumentModal from 'components/screening/common/documents/modals/view-document-modal';
import { ViewRequestedDocumentModal } from 'components/screening/common/documents/modals/view-requested-document-modal';
import PublicTrustDocumentClient from 'clients/screening/public-trust-document-client';
import DocumentProfileAddModal from 'components/personnel-profile/documents-profile/documents-profile-add-modal';
import { useIsMounted } from 'utils/misc-hooks';
import DocumentProfileDeleteModal from 'components/personnel-profile/documents-profile/documents-profile-delete-modal';
import { Table, TableCell } from 'components/common/table';
import { containsNSTUser } from 'components/screening/us-gov/IScreening';
import { RequestDocumentModal } from 'components/screening/common/documents/modals/request-document-modal';
import {
    convertIDocumentRecordToIUnifiedDocumentRecord,
    convertIScreeningTemplateRequestRecordToIUnifiedDocumentRecord,
    DocumentSourceType,
    DocumentTableStatuses,
    getAdhocDocument,
    getAdhocDocumentToken,
    getDocumentMetadataByDocId,
    getScreeningDocument,
    IUnifiedDocumentRecord,
} from 'components/screening/common/documents/document-common';
import { documentScreeningStyles } from 'components/screening/common/documents/document-styles';
import ActivitiesClient, {
    IActivity,
    IActivityPost,
    IActivityValue,
} from 'clients/activities-client';
import EllipsisText from 'components/common/ellipsis-text';
import { UserContext } from 'contexts/user-context';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
const REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS = 1000;

export interface DocumentsTableProps {
    screeningId: string;
    userTypes: string[];
    isUserCandidate: boolean;
    screeningPath: ScreeningPaths;
    nomineeId: string;
    employeeId: string;
    editableEmployeeId: string | undefined;
    associationValue: AssociationValue;
    screeningEventBinsShouldUpdateCount: number;
    // function to manually update the Activities list on the RHS of the Screening details page
    updateScreeningDetailsActivities: React.Dispatch<
        React.SetStateAction<ActivityEventBin[] | undefined>
    >;
    setGenericErrorText: React.Dispatch<React.SetStateAction<string>>;
}

function getTemplateRequestFromDocument(
    documentId: string,
    requestedDocuments: IScreeningTemplateRequestRecord[] | undefined,
): IScreeningTemplateRequestRecord | undefined {
    return requestedDocuments?.find((x) => x.documentServiceDocumentId === documentId);
}

function DocumentsTable(props: DocumentsTableProps): JSX.Element {
    const isDocumentStorageFlag = useFeatureFlag(FeatureFlagKeys.documentStorage).enabled;
    const userContext = useContext(UserContext);
    const [convertedUploadedDocuments, setConvertedUploadedDocuments] = useState<
        IUnifiedDocumentRecord[]
    >([]);
    const [documentTypesMap, setDocumentTypesMap] = useState<Map<string, string>>();
    const [errorMsg, setErrorMsg] = useState<string>();
    const authContext = useContext(AuthContext);
    const [isUpdatedRequested, setIsUpdatedRequested] = useState<boolean>(false);
    const [outstandingDocCount, setoutstandingDocCount] = useState<number>(0);
    const [adhocDocumentToken, setAdhocDocumentToken] = useState<IDocumentToken>(
        emptyNoContentIDocumentToken,
    );
    const [uploadedDocumentToken, setUploadedDocumentToken] = useState<IDocumentToken>(
        emptyNoContentIDocumentToken,
    );
    const [documentMetadata, setDocumentMetadata] = useState<IDocumentMetadata[]>();
    const isMounted = useIsMounted();

    const [requestedDocuments, setRequestedDocuments] = useState<
        IScreeningTemplateRequestRecord[]
    >();

    const [isRefreshActivitiesLocked, setRefreshActivitiesLocked] = useState<boolean>(false);
    const [refreshActivityCallDelayMs, setRefreshActivityCallDelayMs] = useState<number>(0);
    const [screeningEventBins, setScreeningEventBins] = useState<ActivityEventBin[]>();

    /** start of UseEffect Section **/
    useEffect(() => {
        // this will get a new batch of activities based on if the screening-details needs it.
        eventBitWillManuallyUpdate();
    }, [props.screeningEventBinsShouldUpdateCount]);

    // load data on initial render
    useEffect(() => {
        const getDocumentTypes = async (): Promise<void> => {
            const docTypeItemsList: IDocumentTypeItem[] = await DocumentsClient.getDocumentTypeItems(
                authContext,
                AssociationType.ScreeningRecord,
                props.associationValue,
            );
            const documentTypeMap: Map<string, string> = new Map<string, string>(
                docTypeItemsList.map((docTypeItem) => {
                    return [docTypeItem.documentType, docTypeItem.displayName];
                }),
            );
            setDocumentTypesMap(documentTypeMap);
        };
        getDocumentTypes();
        // load unified table
        getScreeningDocumentRecords();
    }, []);

    useEffect(() => {
        if (requestedDocuments) {
            if (
                requestedDocuments?.find((x) =>
                    isIScreeningTemplateRequestRecordRequestingUpdate(x),
                )
            ) {
                setIsUpdatedRequested(true);
            } else {
                setIsUpdatedRequested(false);
            }

            setoutstandingDocCount(
                requestedDocuments.filter((x) => isIScreeningTemplateRequestRecordOutstanding(x))
                    .length,
            );
        }
    }, [requestedDocuments]);

    /** End of UseEffect Section **/

    /**
     * so there are 3 paths to getting documents currently:
     * 1) Request documents that will be grabbed from props
     * 2) Uploaded Documents that will need to use DocumentsClient.getDocumentToken to get
     *  documents viaDocumentsClient.getDocuments.
     * 3) Adhoc documents are associated by 'screeningId' so we need to use token: UsGovScreeningClient.getAssociatedDocumentUserToken
     * and get documents via DocumentsClient.getDocumentsByAssociatedUserId.
     * Note: you can think about it like you need a document token based on a screening id to get documents for it
     * you need a associatedDocumentUserToken for the adhoc documents that are associated to it by that AssociatedUserId
     * Each will have their own separate tokens and api calls. To delete/upload/update/request you need to use the specfic token for each
     *
     * Below I take each different types and i convert them into 'IUnifiedDocumentRecord' so that they can load properly into the table
     */
    async function getScreeningDocumentRecords(): Promise<void> {
        const convertedElements: IUnifiedDocumentRecord[] = [];
        const requestedDocuments: IScreeningTemplateRequestRecord[] = await getScreeningDocument(
            authContext,
            props.screeningId,
            props.screeningPath,
        );
        const recentlyGrabbedMetaData = await getDocumentMetadata(requestedDocuments);
        if (requestedDocuments.length > 0) {
            requestedDocuments
                .filter((x) => x.requestedState === ScreeningTemplateRequestedState.RequestedState)
                .forEach((requestedDoc) => {
                    const x = convertIScreeningTemplateRequestRecordToIUnifiedDocumentRecord(
                        requestedDoc,
                        recentlyGrabbedMetaData,
                    );
                    if (x !== undefined) {
                        convertedElements.push(x);
                    }
                });
        }
        let adhocDocuments: IDocumentRecord[] = [];
        let uploadedDocuments: IDocumentRecord[] = [];
        const adhocAssociatedToken = await getAdhocDocumentToken(authContext, props.screeningId);
        if (adhocAssociatedToken !== undefined && adhocAssociatedToken.token !== '') {
            setAdhocDocumentToken(adhocAssociatedToken);
            adhocDocuments = await getAdhocDocument(
                authContext,
                props.screeningId,
                adhocAssociatedToken.token,
            );
            if (adhocDocuments.length > 0) {
                convertedElements.push(
                    ...adhocDocuments.map((document) => {
                        const newUnifiedDocument: IUnifiedDocumentRecord = {
                            documentSourceType: DocumentSourceType.Adhoc,
                            id: document.id,
                            authorPersonnelId: document.authorPersonnelId,
                            createdDate: document.createdDate,
                            lastModifiedDate: document.lastModifiedDate,
                            fileName: document.fileName,
                            documentNotes: document.documentNotes,
                            documentTitle: document.documentTitle,
                            documentTypeName: document.documentTypeName,
                            documentType: document.documentType,
                            fileMimeType: document.fileMimeType,
                            expiryTime: document.expiryTime,
                            lock: document.lock,
                            metadata: document.metadata,
                            status: 'UPLOADED',
                        };
                        return newUnifiedDocument;
                    }),
                );
            }
        }
        try {
            const documentToken: IDocumentToken = await DocumentsClient.getDocumentToken(
                authContext,
                props.screeningId,
                props.screeningPath,
            );
            if (!documentToken || !documentToken.token) {
                if (isMounted()) {
                    // sort based on most recently modified date first.
                    convertedElements.sort((a, b) => b.lastModifiedDate - a.lastModifiedDate);
                    setConvertedUploadedDocuments(convertedElements);
                    setRequestedDocuments(requestedDocuments);
                }
                return;
            }
            setUploadedDocumentToken(documentToken);
            uploadedDocuments = await DocumentsClient.getDocuments(
                authContext,
                props.screeningId,
                documentToken?.token,
            ); // Ignore 'DELETING' and 'DELETED' statuses
            uploadedDocuments = uploadedDocuments?.filter((x) => x.status === 'ACTIVE'); // TODO: go to backend and don't send this
            if (isMounted() && documentToken && uploadedDocuments.length > 0) {
                convertedElements.push(
                    ...uploadedDocuments.map((document) => {
                        const documentMetadataRecord = recentlyGrabbedMetaData?.find(
                            (x) => x.title === document.fileName,
                        );
                        const requestRecord = requestedDocuments?.find(
                            (x) => x.documentId === documentMetadataRecord?.documentId,
                        );
                        return convertIDocumentRecordToIUnifiedDocumentRecord(
                            document,
                            requestRecord,
                        );
                    }),
                );
            }
        } catch (err) {
            if (err?.status >= 400 && err?.status < 500 && isMounted()) {
                setErrorMsg('Not allowed to view documents');
            } else {
                setErrorMsg('More then likely no documents with screeningId in document-service');
                props.setGenericErrorText(
                    `More then likely no documents with screeningId in document-service, ${getGenericErrorText(
                        err,
                    )}`,
                );
            }
        }
        // sort based on most recently modified date first.
        convertedElements.sort((a, b) => b.lastModifiedDate - a.lastModifiedDate);

        if (isMounted()) {
            setRequestedDocuments(requestedDocuments);
            setConvertedUploadedDocuments(convertedElements);
            getEventData(uploadedDocuments, adhocDocuments);
        }
    }

    async function getLatestAssociatedToken(
        documentSourceType: DocumentSourceType,
    ): Promise<IDocumentToken> {
        if (documentSourceType === DocumentSourceType.UploadedByUser) {
            return await getLatestUploadedDocumentToken();
        }
        return await getLatestAdhocToken();
    }

    async function getLatestAdhocToken(): Promise<IDocumentToken> {
        if (adhocDocumentToken.token !== '' && adhocDocumentToken?.expires) {
            const tokenTime = new Date(adhocDocumentToken.expires).getTime();
            const currentTime = new Date().getTime();
            if (tokenTime - currentTime <= 0) {
                const newAdhocAssociatedToken = await getAdhocDocumentToken(
                    authContext,
                    props.screeningId,
                );
                setAdhocDocumentToken(newAdhocAssociatedToken);
                return newAdhocAssociatedToken;
            }
        } else {
            const newAdhocAssociatedToken = await getAdhocDocumentToken(
                authContext,
                props.screeningId,
            );
            setAdhocDocumentToken(newAdhocAssociatedToken);
            return newAdhocAssociatedToken;
        }
        return adhocDocumentToken;
    }

    async function getLatestUploadedDocumentToken(): Promise<IDocumentToken> {
        if (uploadedDocumentToken.token !== '' && uploadedDocumentToken?.expires) {
            const tokenTime = new Date(uploadedDocumentToken.expires).getTime();
            const currentTime = new Date().getTime();
            if (tokenTime - currentTime <= 0) {
                const newDocumentToken: IDocumentToken = await DocumentsClient.getDocumentToken(
                    authContext,
                    props.screeningId,
                    props.screeningPath,
                );
                setUploadedDocumentToken(newDocumentToken);
                return newDocumentToken;
            }
        } else {
            const newDocumentToken: IDocumentToken = await DocumentsClient.getDocumentToken(
                authContext,
                props.screeningId,
                props.screeningPath,
            );
            setUploadedDocumentToken(newDocumentToken);
            return newDocumentToken;
        }

        return uploadedDocumentToken;
    }

    function eventBitWillManuallyUpdate(): void {
        if (!isRefreshActivitiesLocked) {
            setRefreshActivitiesLocked(true);
            setTimeout(() => {
                // let the server get the new document then get the data.
                getEventData();
            }, refreshActivityCallDelayMs);
            setRefreshActivityCallDelayMs(REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS);
        }
    }

    async function getEventData(
        uploadedDocuments?: IDocumentRecord[],
        adhocDocuments?: IDocumentRecord[],
    ): Promise<void> {
        const activitiesPromises: Promise<IActivity[]>[] = [];
        const activitiesScreening = ActivitiesClient.getScreeningActivityToken(
            authContext,
            props.screeningId,
        ).then((token) =>
            activitiesPromises.push(
                ActivitiesClient.getAllScreeningActivitiesForScreeningId(
                    authContext,
                    props.screeningId,
                    token,
                ),
            ),
        );
        try {
            try {
                const activitiesDocuments: Promise<number>[] = [];
                let localUploadedDocuments: IDocumentRecord[] = [];
                if (uploadedDocuments !== undefined) {
                    localUploadedDocuments = uploadedDocuments;
                } else {
                    const documentToken = await getLatestUploadedDocumentToken();
                    localUploadedDocuments = await DocumentsClient.getDocuments(
                        authContext,
                        props.screeningId,
                        documentToken.token,
                    );
                }
                if (localUploadedDocuments.length > 0) {
                    localUploadedDocuments?.forEach((docRecord) =>
                        activitiesDocuments.push(
                            ActivitiesClient.getDocumentActivityToken(
                                authContext,
                                docRecord.id,
                            ).then((docToken) =>
                                activitiesPromises.push(
                                    ActivitiesClient.getAllDocumentActivitiesForDocumentId(
                                        authContext,
                                        docRecord.id,
                                        docToken,
                                    ),
                                ),
                            ),
                        ),
                    );
                }

                let localAdhocDocuments: IDocumentRecord[] = [];
                if (adhocDocuments !== undefined) {
                    localAdhocDocuments = adhocDocuments;
                } else {
                    const adhocDocumentToken = await getLatestAdhocToken();
                    localAdhocDocuments = await getAdhocDocument(
                        authContext,
                        props.screeningId,
                        adhocDocumentToken.token,
                    );
                }
                if (localAdhocDocuments.length > 0) {
                    localAdhocDocuments?.forEach((docRecord) =>
                        activitiesDocuments.push(
                            ActivitiesClient.getDocumentActivityToken(
                                authContext,
                                docRecord.id,
                            ).then((docToken) =>
                                activitiesPromises.push(
                                    ActivitiesClient.getAllDocumentActivitiesForDocumentId(
                                        authContext,
                                        docRecord.id,
                                        docToken,
                                    ),
                                ),
                            ),
                        ),
                    );
                }
                await Promise.allSettled([...activitiesDocuments]);
            } catch (e) {
                props.setGenericErrorText(
                    `Error getting document records, ${getGenericErrorText(e)}`,
                );
            }
            await Promise.allSettled([activitiesScreening]);
            await Promise.allSettled(activitiesPromises);

            const events: IActivity[] = [];
            for (let i = 0; i < activitiesPromises.length; i++) {
                try {
                    const res = await activitiesPromises[i];
                    // TODO remove else case once feature flag is removed

                    if (isDocumentStorageFlag) {
                        res.forEach((x) => {
                            // this prevents double showing of created events by NST e.g. x uploaded file
                            // as well allowing nominee-created events to be shown
                            if (x.event !== 'Created' || x.subject!.value === props.nomineeId)
                                events.push(x);
                        });
                    } else {
                        res.forEach((x) => events.push(x));
                    }
                } catch (e) {
                    props.setGenericErrorText(
                        `Error with getting activities from result, ${getGenericErrorText(e)}`,
                    );
                }
            }

            // keep here for future debugging.
            console.log(events);

            // filter events to remove duplicate uploads
            removeDuplicateUploadActivities(events);
            const sortedBins = binSortEvents(events);
            setScreeningEventBins(sortedBins);
            props.updateScreeningDetailsActivities(sortedBins);
        } catch (e) {
            props.setGenericErrorText(getGenericErrorText(e));
        }
        setRefreshActivitiesLocked(false);
    }

    // Remove duplicate "documentApp" activities that have a matching "screeningApp" + "document-uploaded" activity
    // Note: "documentApp" and "screeningApp" are values for the "appName" field in the activity object.
    // Note: When a document gets uploaded, document service will create a "documentApp" activity. We fetch that activity
    // if the document exists in the document table. if the docuemnt gets deleted, we can't get the activity.
    // To be more stable in our activity timeline, we have a matching "screeningApp" activity with event = "document-uploaded" and
    // we remove the duplicate if both of these activities exist.
    function removeDuplicateUploadActivities(activities: IActivity[]) {
        const screeningAppUploadedSet = new Set<string>();

        // Populate the set with reference IDs from screeningApp "document-uploaded" activities
        activities.forEach((activity) => {
            if (activity.appName === 'screeningApp' && activity.event === 'document-uploaded') {
                activity.additionalObjects.forEach((obj) => {
                    if (obj.type === 'documentId') screeningAppUploadedSet.add(obj.value);
                });
            }
        });

        // Filter out the duplicates in place
        for (let i = activities.length - 1; i >= 0; i--) {
            const activity = activities[i];
            if (
                activity.appName === 'documentApp' &&
                activity.event === 'Created' &&
                screeningAppUploadedSet.has(activity.referenceId)
            ) {
                activities.splice(i, 1);
            }
        }
    }

    function isDocumentRequestTitleUnique(requestTitle: string): boolean {
        if (documentMetadata) {
            return documentMetadata.filter((x) => x.title === requestTitle).length === 0;
        } else if (documentMetadata === undefined) {
            return true;
        }
        return false;
    }

    function doesDocumentRecordRequireEdits(documentRecord: IDocumentRecord): boolean {
        const templateRequest = getTemplateRequestFromDocument(
            documentRecord.id,
            requestedDocuments,
        );
        if (templateRequest) {
            return isIScreeningTemplateRequestRecordRequestingUpdate(templateRequest);
        }
        return false;
    }

    function updateSpecificDocumentRequest(
        request: IScreeningTemplateRequestRecord,
        newDocumentTableStatus: DocumentTableStatuses,
    ): void {
        if (requestedDocuments) {
            const requestedDocumentsCopy = requestedDocuments.slice(0);
            const requestToUpdateIdx = requestedDocumentsCopy.findIndex((x) => x.id === request.id);
            if (requestToUpdateIdx > -1) {
                requestedDocumentsCopy[requestToUpdateIdx] = request;
                setRequestedDocuments(requestedDocumentsCopy);
                getDocumentMetadata(requestedDocumentsCopy);
            }
            const x = convertedUploadedDocuments.slice(0);
            const convertedIndex = convertedUploadedDocuments.findIndex(
                (x) => x.documentRequestId === request.id,
            );
            if (convertedIndex > -1) {
                const unifiedDocument = x[convertedIndex];
                if (unifiedDocument) {
                    unifiedDocument.status = newDocumentTableStatus.toString();
                    const lastModifiedDate = Date.parse(request.updatedDateTime ?? '') / 1000;
                    unifiedDocument.lastModifiedDate = lastModifiedDate;
                    unifiedDocument.authorPersonnelId = userContext.employeeRecord.id;
                    x[convertedIndex] = unifiedDocument;
                    setConvertedUploadedDocuments(x);
                    eventBitWillManuallyUpdate();
                }
            }
        }
    }

    function onDocumentLockorUnlock(
        doc: IDocumentRecord,
        newDocumentTableStatus: DocumentTableStatuses,
    ): void {
        const x = convertedUploadedDocuments.slice(0);
        const convertedIndex = convertedUploadedDocuments.findIndex((x) => x.id === doc.id);
        if (convertedIndex > -1) {
            const unifiedDocument = x[convertedIndex];
            if (unifiedDocument) {
                unifiedDocument.status = newDocumentTableStatus.toString();
                unifiedDocument.lastModifiedDate = doc.lastModifiedDate;
                unifiedDocument.authorPersonnelId = userContext.employeeRecord.id;
                if (newDocumentTableStatus === DocumentTableStatuses.Completed) {
                    unifiedDocument.lock = {
                        applicationContext: '',
                        isLocked: true,
                    };
                } else {
                    unifiedDocument.lock = undefined;
                }
                x[convertedIndex] = unifiedDocument;
                setConvertedUploadedDocuments(x);
                eventBitWillManuallyUpdate();
            }
        }
    }

    async function addRequestDocuments(
        newRequests: IScreeningTemplateRequestRecord[],
    ): Promise<void> {
        const requestedDocumentsCopy = requestedDocuments ? requestedDocuments.slice(0) : [];
        const updatedRequests = requestedDocumentsCopy.concat(newRequests);
        setRequestedDocuments(updatedRequests);
        const newMetaData = await getDocumentMetadata(updatedRequests);
        const x = convertedUploadedDocuments;
        newRequests.forEach((request) => {
            const convertedRequest = convertIScreeningTemplateRequestRecordToIUnifiedDocumentRecord(
                request,
                newMetaData,
            );
            if (convertedRequest !== undefined) {
                x.unshift(convertedRequest);
            }
        });
        setConvertedUploadedDocuments(x);
        eventBitWillManuallyUpdate();
    }

    function removeDocumentRequest(request: IScreeningTemplateRequestRecord): void {
        const x = convertedUploadedDocuments.filter((x) => x.documentRequestId !== request.id);
        if (documentMetadata) {
            const metadata = documentMetadata.filter((x) => x.documentId !== request.documentId);
            setDocumentMetadata(metadata);
        }
        if (requestedDocuments) {
            const requests = requestedDocuments.filter((x) => x.id !== request.id);
            setRequestedDocuments(requests);
        }
        setConvertedUploadedDocuments(x);
        eventBitWillManuallyUpdate();
    }

    function getGenericErrorText(e: Response): string {
        return `Error: ${e.status} - ${e.statusText}`;
    }

    async function getDocumentMetadata(
        requestedDocuments: IScreeningTemplateRequestRecord[],
    ): Promise<IDocumentMetadata[]> {
        try {
            const documentIds: Set<string> = new Set<string>();
            requestedDocuments.forEach((x) => {
                documentIds.add(x.documentId);
            });
            let metadataResults: IDocumentMetadata[] = [];
            if (props.screeningPath === ScreeningPaths.UsGov) {
                metadataResults = await TemplateClient.getDocumentMetadataFromDocumentIds(
                    authContext,
                    documentIds,
                );
            } else {
                metadataResults = await PublicTrustDocumentClient.getDocumentMetadataFromDocumentIds(
                    authContext,
                    documentIds,
                );
            }
            setDocumentMetadata(metadataResults);
            return metadataResults;
        } catch (e) {
            props.setGenericErrorText(getGenericErrorText(e));
        }

        return [];
    }

    async function removeRequestDocument(documentRequestId: string | undefined): Promise<void> {
        if (documentRequestId) {
            try {
                if (props.screeningPath === ScreeningPaths.UsGov) {
                    await TemplateClient.deleteDocumentRequest(authContext, documentRequestId);
                } else {
                    await PublicTrustDocumentClient.deleteDocumentRequest(
                        authContext,
                        documentRequestId,
                    );
                }
            } catch (error) {
                props.setGenericErrorText(
                    `failed to delete document ${documentRequestId}, ${getGenericErrorText(error)}`,
                );
            }
        }
    }

    function addIDocumentRecord(doc: IDocumentRecord, status: string): void {
        const x = convertedUploadedDocuments.slice(0);
        x.unshift(convertIDocumentRecordToIUnifiedDocumentRecord(doc, undefined, status));
        setConvertedUploadedDocuments(x);
        eventBitWillManuallyUpdate();
    }

    async function onUploadToRequestDocument(
        doc: IDocumentRecord,
        status: string,
        request: IScreeningTemplateRequestRecord,
    ): Promise<void> {
        let x = convertedUploadedDocuments.slice(0);
        request.requestedState = ScreeningTemplateRequestedState.UploadedState;
        let updatedRequest: IScreeningTemplateRequestRecord[] = [];
        if (props.screeningPath === ScreeningPaths.UsGov) {
            updatedRequest = await TemplateClient.updateMultipleDocuments(authContext, [request]);
        } else {
            updatedRequest = await PublicTrustDocumentClient.updateMultipleDocuments(authContext, [
                request,
            ]);
        }
        if (requestedDocuments) {
            const requestedDocumentsCopy = requestedDocuments.slice(0);
            const requestToUpdateIdx = requestedDocumentsCopy.findIndex((x) => x.id === request.id);
            if (requestToUpdateIdx > -1) {
                requestedDocumentsCopy[requestToUpdateIdx] = updatedRequest[0];
                getDocumentMetadata(requestedDocumentsCopy);
                setRequestedDocuments(requestedDocumentsCopy);
            }
        }

        writeUploadDocumentToScreenings(doc, props.screeningId);
        x = x.filter((doc) => doc.documentRequestId !== request.id);
        x.unshift(convertIDocumentRecordToIUnifiedDocumentRecord(doc, undefined, status));
        setConvertedUploadedDocuments(x);
        eventBitWillManuallyUpdate();
    }

    function writeUploadDocumentToScreenings(
        uploadedFile: IDocumentRecord,
        screeningId: string,
    ): void {
        const uploadMessage = `${userContext.employeeRecord.fullName} uploaded ${uploadedFile.documentType}`;
        const event = 'document-uploaded';
        const subjectData: IActivityValue = {
            type: 'personnelId',
            value: userContext.employeeRecord.id,
        };

        const directObjectData: IActivityValue = {
            type: 'documentFileName',
            value: uploadedFile.fileName ?? '',
        };

        const activityPostData: IActivityPost = {
            event,
            eventTimestampUTC: new Date().getTime(),
            referenceId: screeningId,
            appName: 'screeningApp',
            securityIds: [ActivitiesClient.AdminSecurityId, screeningId],
            subject: subjectData,
            directObject: directObjectData,
            additionalObjects: uploadedFile.id
                ? [{ type: 'documentId', value: uploadedFile.id }]
                : undefined,
            message: uploadMessage,
            tags: [],
        };

        ActivitiesClient.getScreeningActivityToken(authContext, screeningId, ['write']).then(
            async (token) => {
                await ActivitiesClient.postScreeningActivity(
                    authContext,
                    screeningId,
                    activityPostData,
                    token,
                );
            },
        );
    }

    const columns: IColumn[] = [
        {
            key: 'type',
            name: 'Type',
            minWidth: 150,
            maxWidth: 250,
            isRowHeader: true,
            ariaLabel: 'type',
            data: 'string',
            onRender: (item: IUnifiedDocumentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EllipsisText
                            text={
                                item.documentTypeName ??
                                documentTypesMap?.get(item.documentType) ??
                                item.documentType ??
                                ''
                            }
                            textLengthBeforeEllipsis={30}
                        />
                    </TableCell>
                );
            },
        },
        {
            key: 'title',
            name: 'Title',
            minWidth: 90,
            maxWidth: 250,
            isRowHeader: true,
            ariaLabel: 'Document title',
            data: 'string',
            onRender: (item: IUnifiedDocumentRecord): JSX.Element => {
                const isLocked = DocumentsClient.isDocumentLocked(item);
                const isRequireEdit = doesDocumentRecordRequireEdits(item);
                const isDocumentDeleted = DocumentsClient.isDocumentDeleted(item);
                const key = `${item.id}-${isLocked}-${isRequireEdit}-${isDocumentDeleted}}`;
                return (
                    <TableCell key={key}>
                        {isLocked && (
                            <>
                                <Icon iconName='lock' />{' '}
                            </>
                        )}
                        {isRequireEdit && (
                            <>
                                <Icon iconName='Edit' />{' '}
                            </>
                        )}
                        {isDocumentDeleted && (
                            <>
                                <Icon iconName='PageRemove' title='Document has been removed' />{' '}
                            </>
                        )}
                        <span className={documentScreeningStyles.stringContent}>
                            <EllipsisText text={item.fileName} textLengthBeforeEllipsis={30} />
                        </span>
                    </TableCell>
                );
            },
        },
        {
            key: 'status',
            name: 'Status',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            ariaLabel: 'type',
            data: 'string',
            onRender: (item: IUnifiedDocumentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <span className={documentScreeningStyles.stringContent}>{item.status}</span>
                    </TableCell>
                );
            },
        },
        {
            key: 'lastModifiedBy',
            name: 'Last modified by',
            minWidth: 110,
            maxWidth: 110,
            isRowHeader: true,
            ariaLabel: 'Last modified by',
            data: 'string',
            onRender: (item: IUnifiedDocumentRecord): JSX.Element => {
                return (
                    <TableCell>
                        <EmployeeHoverCard personnelId={item.authorPersonnelId}>
                            <span className={documentScreeningStyles.stringContent}>
                                <EmployeeNameResolverId personnelId={item.authorPersonnelId} />
                            </span>
                        </EmployeeHoverCard>
                    </TableCell>
                );
            },
        },
        {
            key: 'LastModifiedOn',
            name: 'Last modified on',
            minWidth: 140,
            maxWidth: 140,
            data: 'string',
            isRowHeader: true,
            ariaLabel: 'Last modified on',
            onRender: (item: IUnifiedDocumentRecord): JSX.Element => {
                return item.lastModifiedDate ? (
                    <TableCell>
                        <span className={documentScreeningStyles.stringContent}>
                            {unixTimeStampToFormattedDateString(item.lastModifiedDate)}
                        </span>
                    </TableCell>
                ) : (
                    <></>
                );
            },
        },
        {
            key: 'actions',
            name: '',
            minWidth: 60,
            maxWidth: 60,
            isRowHeader: true,
            isPadded: false,
            ariaLabel: 'Actions',
            onRender: (item: IUnifiedDocumentRecord): JSX.Element => {
                if (item.documentSourceType === DocumentSourceType.Requested) {
                    return (
                        <TableCell>
                            <ViewRequestedDocumentModal
                                title='View'
                                iconName='FileRequest'
                                documentId={item.id}
                                referenceId={props.screeningId}
                                documentRequestId={item.documentRequestId}
                                documentMetadata={getDocumentMetadataByDocId(
                                    item.id,
                                    documentMetadata,
                                )}
                                updateUploadedDocumentsPanel={(doc: IDocumentRecord): void => {
                                    const screningTemplateReqRecord = requestedDocuments?.find(
                                        (x) => x.documentId === item.id,
                                    );
                                    if (screningTemplateReqRecord !== undefined) {
                                        onUploadToRequestDocument(
                                            doc,
                                            DocumentTableStatuses.WithNST,
                                            screningTemplateReqRecord,
                                        );
                                    }
                                }}
                                onRequestRemove={(): void => {
                                    const screningTemplateReqRecord = requestedDocuments?.find(
                                        (x) => x.documentId === item.id, // link document id of requestDocument with 'row id'
                                    );
                                    if (screningTemplateReqRecord) {
                                        removeDocumentRequest(screningTemplateReqRecord);
                                    }
                                }}
                                userTypes={props.userTypes}
                                isUploadingTemplateRequest={false}
                                screeningPath={props.screeningPath}
                                nomineeId={props.nomineeId}
                                documentTypesMap={documentTypesMap}
                            />
                        </TableCell>
                    );
                }
                return (
                    <TableCell key={item.id}>
                        <ViewUploadedDocumentModal
                            documentRecord={item}
                            documentMetadata={documentMetadata}
                            isRequestDocument
                            isUserCandidate={props.isUserCandidate}
                            getLatestAssociatedToken={(): Promise<IDocumentToken> =>
                                getLatestAssociatedToken(item.documentSourceType)
                            }
                            onUpdateRequest={updateSpecificDocumentRequest}
                            onDocumentLockorUnlock={onDocumentLockorUnlock}
                            screeningId={props.screeningId}
                            templateRequestRecords={requestedDocuments}
                            screeningEventBins={screeningEventBins}
                            screeningPath={props.screeningPath}
                            userTypes={props.userTypes}
                        />
                    </TableCell>
                );
            },
        },
    ];

    const isNSTUserAndNotCandidate = containsNSTUser(props.userTypes) && !props.isUserCandidate;

    if (isNSTUserAndNotCandidate) {
        columns.push({
            key: 'documents_delete',
            name: '',
            ariaLabel: 'delete',
            minWidth: 100,
            maxWidth: 130,
            isRowHeader: true,
            onRender: (row: IUnifiedDocumentRecord): JSX.Element => {
                return (
                    <TableCell key={row.id}>
                        <DocumentProfileDeleteModal
                            isScreeningDetails
                            screeningId={props.screeningId}
                            documentFileName={row.fileName}
                            documentId={row.id}
                            employeeId={props.employeeId}
                            editableEmployeeId={props.editableEmployeeId}
                            eventBitWillManuallyUpdate={eventBitWillManuallyUpdate}
                            // note for future people: the associated Token is for only Uploaded and Adhoc documents,
                            // request Documents are handled via onRequestDocumentDelete
                            // the uploadedDocument token can only delete Documents uploaded through the normal
                            // process of requesting and uploading via screening's user vs adhocDocumentToken is for documents uploaded by the NST team manually
                            associatedUserToken={
                                (row.documentSourceType === DocumentSourceType.UploadedByUser
                                    ? uploadedDocumentToken
                                    : adhocDocumentToken) ?? emptyNoContentIDocumentToken
                            }
                            getLatestAssociatedToken={(): Promise<IDocumentToken> =>
                                getLatestAssociatedToken(row.documentSourceType)
                            }
                            documentSourceType={row.documentSourceType}
                            onRequestDocumentDelete={(): void => {
                                const screningTemplateReqRecord = requestedDocuments?.find(
                                    (x) => x.documentId === row.id, // link document id of requestDocument with 'row id'
                                );
                                if (screningTemplateReqRecord) {
                                    // Update UI.
                                    removeDocumentRequest(screningTemplateReqRecord);
                                    // Make an API call to delete screening document request record.
                                    removeRequestDocument(row.documentRequestId);
                                }
                            }}
                            onUploadedDocumentDelete={(): void => {
                                const filteredDocs = convertedUploadedDocuments.filter(
                                    (document) => document.id !== row.id,
                                );
                                setConvertedUploadedDocuments(filteredDocs);
                            }}
                        />
                    </TableCell>
                );
            },
        });
    }

    return (
        <ContainerWithEtiquettes
            leftEtiquetteLabel='Documents'
            rightEtiquette={
                outstandingDocCount > 0
                    ? {
                          label: `${outstandingDocCount} OUTSTANDING`,
                          backgroundColor: SharedColors.red10,
                      }
                    : undefined
            }
            secondRightEtiquette={
                isUpdatedRequested
                    ? {
                          label: 'UPDATES REQUESTED',
                          backgroundColor: SharedColors.cyanBlue10,
                      }
                    : undefined
            }
            bottomInfo={
                isNSTUserAndNotCandidate && (
                    <>
                        <DocumentProfileAddModal
                            screeningId={props.screeningId}
                            employeeId={props.employeeId}
                            editableEmployeeId={props.editableEmployeeId}
                            associationType={AssociationType.ScreeningRecord}
                            eventBitWillManuallyUpdate={eventBitWillManuallyUpdate}
                            updateUploadedDocumentsPanel={(doc: IDocumentRecord): void =>
                                addIDocumentRecord(doc, DocumentTableStatuses.Uploaded)
                            }
                            associationValue={props.associationValue}
                            isAdhocScreeningDocument
                        />
                        <RequestDocumentModal
                            screeningId={props.screeningId}
                            nomineeId={props.nomineeId}
                            screeningPath={props.screeningPath}
                            updateDocumentInfo={addRequestDocuments}
                            isDocumentRequestTitleUnique={isDocumentRequestTitleUnique}
                            documentTypesMap={documentTypesMap}
                        />
                    </>
                )
            }>
            {convertedUploadedDocuments && (
                <Table<IUnifiedDocumentRecord>
                    rows={convertedUploadedDocuments}
                    isFetchingData={false}
                    shimmerLines={1}
                    tableColumns={columns}
                    tableName='Documents'
                />
            )}
            {errorMsg && <span>{errorMsg}</span>}
        </ContainerWithEtiquettes>
    );
}

export default DocumentsTable;
