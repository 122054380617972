import { useMemo, useState } from 'react';
import config from 'environments/environment';
import { ValueOf } from 'utils/object-utils';

export const LoadingState = {
    loaded: 'loaded',
    loading: 'loading',
} as const;

export const FeatureFlagKeys = {
    emailsCore: 'emails/core',
    emailsLogSanitized: 'emails/logSanitized',
    activityCore: 'activity/core',

    documentStorage: 'document/storage',

    screeningCore: 'screening/core',

    formsCore: 'forms/core',
    scaCore: 'sca/core',
    facilitiesCore: 'facilities/core',
    staffingCore: 'staffing/core',

    screeningContracts: 'screening/admin',
    screeningContractOwners: 'screening/contractOwners',
    screeningSearchFilterContentRedesign: 'screening/searchFilterContentRedesign',
    cloudReadinessReportDownload: 'screening/cloudReadinessReportDownload',
    screeningAgencyDropdown: 'screening/agencyDropdown',

    groupsCore: 'groups/core',
    groupsMetaDataSection: 'groups/metadata',
    groupsLinkToSecurityGroup: 'groups/linkToSecurityGroup',
    groupsPOC: 'groups/POC',
    groupsMin2FteOwners: 'groups/min2FteOwners',
    groupsLinkOnPremAD: 'groups/linkOnPremAD',
    groupsEnableDynamicGroup: 'groups/enableDynamicGroup',
    groupsLinkWithServicePrincipal: 'groups/linkWithServicePrincipal',
    groupsActionCenter: 'groups/actionCenter',

    profileVisitor: 'profile/visitor',
    profileUsGov: 'profile/usGov',
    profileAttributesUpdatedMessageBar: 'profile/attributesUpdatedMessageBar',

    eligibilities: 'eligibility/enableEligibilities',
    eligibilityAutoAssign: 'eligibility/enableAutoAssign',
    supportLinkToAka: 'support/linkToAka',

    headerBanner: 'core/headerBanner',

    personnelCoreAttributes: 'core/attributes',

    suitabilityRecords: 'suitability/records',

    suitabilityRecordsTab: 'suitability/suitabilityRecordsTab',

    formsEdit: 'forms/edit',
    formsUseFluentUITimePicker: 'forms/useFluentUITimePicker',

    cloudScreeningHireRightApi: 'cloudScreening/hireRightApi',

    employeeCore: 'employee/core',

    enableNonExternalComponents: 'groups/enableNonExternalComponents',

    aboutUser: 'about/user',
    csatFeedback: 'csat',

    guestsCore: 'guests/core',
} as const;

export type FeatureFlagResponse = {
    loadingState: keyof typeof LoadingState;
    enabled: boolean;
};

export const useFeatureFlag = (flag: ValueOf<typeof FeatureFlagKeys>): FeatureFlagResponse => {
    const defaultFeatureFlagResponse: FeatureFlagResponse = {
        loadingState: LoadingState.loaded,
        enabled: false,
    };
    const [isEnabled, setEnabled] = useState<FeatureFlagResponse>({
        loadingState: LoadingState.loading,
        enabled: false,
    });
    useMemo(async () => {
        if (!flag) {
            return defaultFeatureFlagResponse;
        }
        try {
            const isEnabled = FetchFeatureFlag(flag);
            setEnabled({
                loadingState: LoadingState.loaded,
                enabled: isEnabled,
            });
        } catch (error) {
            console.error(error);
            return defaultFeatureFlagResponse;
        }
    }, [flag]);

    return isEnabled;
};

const FetchFeatureFlag = (flag: ValueOf<typeof FeatureFlagKeys>): boolean => {
    // This is the only place feature flags should be accessed directly from the config.
    // This call abstracts away where feature flags are fetched from.
    // Flags will be moved to a server side API call in the future
    // eslint-disable-next-line no-restricted-properties
    return config.featureFlags[flag];
};
