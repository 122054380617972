import {
    ComboBox,
    DatePicker,
    DefaultButton,
    Dropdown,
    FluentTheme,
    IComboBoxOption,
    IDropdownOption,
    IPersonaProps,
    IconButton,
    Label,
    Panel,
    PanelType,
    PrimaryButton,
    TimePicker,
} from '@fluentui/react';
import {
    AttributeDataType,
    AttributePredefinedValueStatus,
    AttributeStatus,
    EditAssignmentRequest,
    GetAttributeByIdResult,
    GetAttributeResult,
    GetAttributeSetByIdResult,
    GetPrincipalAssignmentsResult,
    GetPrincipalByFieldResult,
    GetPrincipalByIdResult,
} from 'personnel-core-clients';
import {
    CoreAssignmentsClient,
    CoreAttributeSetsClient,
    CoreAttributesClient,
    CorePrincipalsClient,
} from 'clients/core/personnel-core-client-wrappers';
import { CoreSinglePrincipalPersonaPickerTypeaheadSearch } from 'components/common/core-employee-picker-typeahead-search';
import { AuthContext } from 'contexts/auth-context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { convertValues } from 'components/core/common/attribute-utils';
import AttributeValuePicker from 'components/core/common/attribute-value-picker';
import { CoreEmployeeHoverCardFromGraph } from 'components/core/common/employee-card/core-employee-hover-card';
import { PrincipalUserContext } from 'contexts/principal-user-context';
import { IconNames } from 'assets/constants/global-constants';
import { MAX_DATE_IN_UNIX_SECONDS, getLocalTimeZone, timeZoneAbbr } from 'utils/time-utils';
import { Role } from 'configs/roles';

export enum AssignmentModalState {
    Add = 'Add',
    Edit = 'Edit',
}

export enum AssignmentModalType {
    User = 'User',
    Attribute = 'Attribute',
}

export interface IAddEditAssignmentProps {
    isOpen?: boolean;
    modalState: AssignmentModalState;
    modalType: AssignmentModalType;
    initAssignment?: GetPrincipalAssignmentsResult | undefined;
    initPrincipal?: GetPrincipalByIdResult;
    initAttribute?: GetAttributeByIdResult | undefined;
    initPrincipalAssignments?: GetPrincipalAssignmentsResult[] | undefined;
    onCreateOrUpdate: () => void;
    onDismiss: () => void;
}
export default function AddEditCoreAssignmentPanelActionButton(
    props: IAddEditAssignmentProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const principalUserContext = useContext(PrincipalUserContext);
    const principalClient = new CorePrincipalsClient(authContext);

    const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
    const [owner, setOwner] = useState<IPersonaProps | undefined>();
    const [principalId, setPrincipalId] = useState<string>();
    const [attributeSets, setAttributeSets] = useState<GetAttributeSetByIdResult[]>([]);
    const [attributes, setAttributes] = useState<GetAttributeResult[]>([]);
    const [selectedAttributeSetId, setSelectedAttributeSetId] = useState<string>();
    const [selectedAttribute, setSelectedAttribute] = useState<
        GetAttributeByIdResult | undefined
    >();
    const [expirationDate, setExpirationDate] = useState<Date | undefined>();
    const [selectedValue, setSelectedValue] = useState<(string | number)[]>([]);
    const [showErrors, setShowErrors] = useState(false);
    const [predefinedValueOptions, setPredefinedValueOptions] = useState<IDropdownOption[]>([]);
    const [principalAssignments, setPrincipalAssignments] = useState<
        GetPrincipalAssignmentsResult[] | undefined
    >(undefined);
    const [showAddAssignmentWarning, setShowAddAssignmentWarning] = useState<boolean>(false);
    const [selectedTime, setSelectedTime] = useState<{ hours: number; minutes: number }>({
        hours: expirationDate?.getHours() ?? 0,
        minutes: expirationDate?.getMinutes() ?? 0,
    });

    const isAdmin = useMemo(() => {
        // This is temporary until we get a role for Core Admin in the portal app or figure out another way to do this
        return (
            authContext.isInRole(Role.PortalAdmin) || authContext.isInRole(Role.CoreAttributeAdmin)
        );
    }, [authContext]);

    useEffect(() => {
        if (selectedAttribute?.usePredefinedValues) {
            const filteredPredefinedValues = selectedAttribute?.predefinedValues
                ? selectedAttribute.predefinedValues.filter((v) => {
                      const initValue = props.initAssignment?.value;
                      const isActive = v.status === AttributePredefinedValueStatus.Active;
                      const isIncludedInInitValue = Array.isArray(initValue)
                          ? initValue.includes(v.value)
                          : initValue === v.value;
                      return isActive || isIncludedInInitValue;
                  })
                : [];

            const predefinedValues = filteredPredefinedValues.map((v) => ({
                key: v.value,
                text: v.value,
            }));

            setPredefinedValueOptions(predefinedValues);
        } else {
            setPredefinedValueOptions([]);
        }
    }, [selectedAttribute]);

    useEffect(() => {
        setShowErrors(false);
        if (props.isOpen) {
            const getAttributesAndSets = async (): Promise<void> => {
                const attributeSetClient = new CoreAttributeSetsClient(authContext);
                const attributeSets = (await attributeSetClient.getAll()).filter(
                    (a) => a.isActive === true,
                );

                const attributesClient = new CoreAttributesClient(authContext);
                const attributes: GetAttributeResult[] = [];
                let continuationToken = '';
                do {
                    const attributesResponse = await attributesClient.getAll(
                        100,
                        continuationToken,
                    );
                    if (attributesResponse.results) {
                        attributes.push(
                            ...attributesResponse.results.filter(
                                (a) => a.status === AttributeStatus.Active,
                            ),
                        );
                    }
                    continuationToken = attributesResponse.continuationToken ?? '';
                } while (continuationToken);

                // get all active attribute set ids where the user is an owner or manager
                const ownerManagerAttributeSetIds = new Set(
                    attributeSets
                        .filter(
                            (a) =>
                                isAdmin ||
                                a.owners.includes(principalUserContext.principalRecord.oid ?? '') ||
                                a.managers.includes(principalUserContext.principalRecord.oid ?? ''),
                        )
                        .map((a) => a.id),
                );

                // get all active attribute ids where the user is an owner or manager, or the user of parent attribute set is an owner or manager
                const ownerManagerAttributeIds = new Set(
                    attributes
                        .filter(
                            (a) =>
                                isAdmin ||
                                a.owners.includes(principalUserContext.principalRecord.oid ?? '') ||
                                a.managers.includes(
                                    principalUserContext.principalRecord.oid ?? '',
                                ) ||
                                ownerManagerAttributeSetIds.has(a.attributeSetId),
                        )
                        .map((a) => a.id),
                );

                // get unique attribute set ids to show from ownerManagerAttributeIds
                const attributeSetIdsToShow = new Set(
                    attributes
                        .filter((a) => ownerManagerAttributeIds.has(a.id))
                        .map((a) => a.attributeSetId),
                );

                setAttributeSets(attributeSets.filter((a) => attributeSetIdsToShow.has(a.id)));
                setAttributes(attributes.filter((a) => ownerManagerAttributeIds.has(a.id)));
            };
            getAttributesAndSets();
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.initPrincipal) {
            setPrincipalId(props.initPrincipal.id);
        }

        if (props.initAttribute) {
            setSelectedAttribute(props.initAttribute);
            setSelectedAttributeSetId(props.initAttribute.attributeSetId);
        }

        if (props.initPrincipalAssignments) {
            setPrincipalAssignments(props.initPrincipalAssignments);
        }
    }, [props.initPrincipal, props.initAttribute, props.initPrincipalAssignments]);

    useEffect(() => {
        const getAssignment = async (): Promise<void> => {
            if (props.initAssignment) {
                const assignment = props.initAssignment;
                const attributeClient = new CoreAttributesClient(authContext);
                const attributeResult = await attributeClient.getById(assignment.attributeId);
                setSelectedAttribute(attributeResult);

                if (
                    assignment.expirationDate !== undefined &&
                    assignment.expirationDate > 0 &&
                    assignment.expirationDate < MAX_DATE_IN_UNIX_SECONDS
                ) {
                    const expDate = new Date(assignment.expirationDate * 1000);
                    setExpirationDate(expDate);
                    setSelectedTime({
                        hours: expDate?.getHours() ?? 0,
                        minutes: expDate?.getMinutes() ?? 0,
                    });
                }
                const value: (string | number)[] =
                    assignment.value.constructor.name !== 'Array' &&
                    assignment.value.constructor.name !== 'Boolean' &&
                    assignment.value.constructor.name !== 'Number'
                        ? [assignment.value.toString()]
                        : assignment.value;
                setSelectedValue(value);
            } else {
                setSelectedValue([]);
            }
        };
        getAssignment();
    }, [props.initAssignment]);

    useEffect(() => {
        const getPrincipalFromOid = async (): Promise<void> => {
            const itemProp = JSON.parse(owner!.itemProp!);
            let principal: GetPrincipalByFieldResult;
            if (itemProp.oid) {
                principal = await principalClient.getByField(null, itemProp.oid, null);
            } else if (itemProp.externalId) {
                principal = await principalClient.getByField(itemProp.externalId, null, null);
            } else if (itemProp.upn) {
                principal = await principalClient.getByField(null, null, itemProp.upn);
            }
            setPrincipalId(principal!.id);
            if (props.modalState === AssignmentModalState.Add && principal!.id) {
                await GetPrincipalAssignments(principal!.id);
            }
        };

        const GetPrincipalAssignments = async (principalId: string): Promise<void> => {
            setPrincipalAssignments(await principalClient.getAssignments(principalId));
        };

        if (props.modalType === AssignmentModalType.Attribute && owner) {
            getPrincipalFromOid();
        }

        setShowAddAssignmentWarning(false);
    }, [owner, props.modalType]);

    useEffect(() => {
        if (selectedAttribute) {
            if (props.modalState === AssignmentModalState.Add) {
                const assignment = principalAssignments?.find(
                    (assignment) => assignment.attributeId === selectedAttribute.id,
                );

                if (assignment) {
                    setShowAddAssignmentWarning(true);
                } else {
                    setShowAddAssignmentWarning(false);
                }
            }
        }
    }, [selectedAttribute, principalAssignments]);

    const attributeDropDownOptions: IComboBoxOption[] = useMemo(() => {
        return attributes
            .filter((attribute) => attribute.attributeSetId === selectedAttributeSetId)
            .map((attribute) => {
                return {
                    key: attribute.id,
                    text: `${attribute.name} ${attribute.isAutoAssigned ? '(auto assigned)' : ''}`,
                    data: attribute,
                    disabled: attribute.isAutoAssigned,
                };
            });
    }, [attributes, selectedAttributeSetId]);

    const attributeSetDropDownOptions: IComboBoxOption[] = useMemo(() => {
        return attributeSets.map((a) => {
            return { key: a.id, text: a.name };
        });
    }, [attributeSets]);

    const isAttributeValid = (): boolean => {
        return selectedAttribute !== undefined;
    };

    const isValueValid = (): boolean => {
        return (
            selectedValue !== undefined &&
            (selectedAttribute?.dataType === AttributeDataType.Boolean ||
                selectedValue.length > 0) &&
            selectedValue[0] !== ''
        );
    };

    const isEmployeeValid = (): boolean => {
        return owner !== undefined || props.initPrincipal !== undefined;
    };

    const isSubmitEnabled = (): boolean => {
        return isAttributeValid() && isValueValid() && isEmployeeValid() && !isFormSubmitting;
    };

    const validateFields = (): void => {
        if (!isValueValid() || !isAttributeValid() || !isEmployeeValid()) {
            setShowErrors(true);
            setIsFormSubmitting(false);
            throw new Error('Please check fields for errors and try again');
        }
    };

    const onClick = async (): Promise<void> => {
        setIsFormSubmitting(true);
        validateFields();
        const client = new CoreAssignmentsClient(authContext);

        if (selectedAttribute) {
            const values = convertValues(
                selectedValue,
                selectedAttribute.dataType,
                selectedAttribute.isMultiValued,
            );
            const request: EditAssignmentRequest = new EditAssignmentRequest({
                attributeId: selectedAttribute.id,
                value: values,
                expirationDate:
                    expirationDate !== undefined ? expirationDate.getTime() / 1000 : undefined,
            });
            await client.set(principalId ?? '', null, request);
        }

        onSubmit();
    };

    const onSubmit = (): void => {
        props.onCreateOrUpdate();
        onCancel();
    };
    const onCancel = (): void => {
        resetFormFields();
        setIsFormSubmitting(false);
        props.onDismiss();
    };

    const resetFormFields = (): void => {
        setSelectedValue([]);
        setExpirationDate(undefined);
        setSelectedTime({ hours: 0, minutes: 0 });
        setSelectedAttribute(undefined);
        setSelectedAttributeSetId(undefined);
        setOwner(undefined);
        setShowAddAssignmentWarning(false);
        setPrincipalAssignments(undefined);
    };

    const onRenderFooterContent = React.useCallback(
        () => (
            <div>
                <PrimaryButton
                    onClick={onClick}
                    styles={{ root: { marginRight: 8 } }}
                    disabled={!isSubmitEnabled()}>
                    {props.modalState === AssignmentModalState.Add ? 'Add' : 'Save'}
                </PrimaryButton>
                <DefaultButton onClick={onCancel}>Cancel</DefaultButton>
            </div>
        ),
        [onCancel, isSubmitEnabled],
    );

    const createAttributeSetsDropdownElement = useCallback(() => {
        return (
            <ComboBox
                label='Attribute set'
                allowFreeform
                autoComplete='on'
                options={attributeSetDropDownOptions}
                placeholder='Select attribute set'
                onChange={(ev, newVal): void => {
                    setSelectedAttributeSetId(newVal?.key.toString());
                    setSelectedValue([]);
                    setShowAddAssignmentWarning(false);
                }}
                required
                errorMessage={
                    showErrors && !isAttributeValid()
                        ? 'Please select a valid Attribute set from the dropdown.'
                        : ''
                }
                styles={styles.element}
                disabled={attributeSets.length === 0}
                useComboBoxAsMenuWidth
            />
        );
    }, [attributeSetDropDownOptions, attributeSets.length, isAttributeValid, showErrors]);

    return (
        <Panel
            headerText={
                props.modalState === AssignmentModalState.Add ? 'Add assignment' : 'Edit assignment'
            }
            isOpen={props.isOpen}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooterContent}
            onDismiss={(): void => onCancel()}
            isLightDismiss
            onLightDismissClick={(): void => onCancel()}
            isFooterAtBottom={true}
            styles={styles.panel}
            type={PanelType.smallFixedFar}>
            {props.modalType === AssignmentModalType.Attribute &&
            props.modalState !== AssignmentModalState.Edit ? (
                <>
                    <CoreSinglePrincipalPersonaPickerTypeaheadSearch
                        label='Name'
                        placeHolder='Select user name or alias'
                        onChange={setOwner}
                        selectedItem={owner}
                        required
                        styles={{ labelStyle: { marginBottom: '0px' } }}
                    />
                    {showErrors && !isEmployeeValid() && (
                        <span style={{ color: FluentTheme.semanticColors.errorText, fontSize: 12 }}>
                            A valid Employee is required.
                        </span>
                    )}
                </>
            ) : (
                <>
                    <Label style={styles.label}>Name</Label>
                    <CoreEmployeeHoverCardFromGraph
                        key={props.initPrincipal?.id}
                        oid={props.initPrincipal?.oid}
                        showMiniCardAlias={false}
                        employeeCardStyle={styles.employeeCard}
                    />
                </>
            )}
            {props.modalState === AssignmentModalState.Add && !props.initAttribute ? (
                <>{createAttributeSetsDropdownElement()}</>
            ) : (
                <></>
            )}
            {props.modalState === AssignmentModalState.Add && !props.initAttribute ? (
                <>
                    <ComboBox
                        label='Attribute'
                        allowFreeform
                        autoComplete='on'
                        options={attributeDropDownOptions}
                        placeholder='Select attribute'
                        onChange={(ev, newVal): void => setSelectedAttribute(newVal?.data)}
                        required
                        errorMessage={
                            showErrors && !isAttributeValid()
                                ? 'Please select a valid Attribute from the dropdown.'
                                : ''
                        }
                        disabled={
                            selectedAttributeSetId === undefined ||
                            attributeDropDownOptions.length === 0
                        }
                        styles={styles.element}
                        useComboBoxAsMenuWidth
                    />
                </>
            ) : (
                <>
                    <Label style={styles.label}>Attribute</Label>
                    {selectedAttribute?.name}
                </>
            )}
            <div style={{ marginTop: elementMarginTop }}>
                <AttributeValuePicker
                    label='Values'
                    allowMultiValueSelection={selectedAttribute?.isMultiValued}
                    dropDownOptions={predefinedValueOptions}
                    assignmentValue={
                        selectedAttribute?.isMultiValued
                            ? props.initAssignment?.value
                            : selectedValue
                    }
                    onSelectValue={(val): void => {
                        if (val.length > 0 && val[0] !== '') {
                            setSelectedValue(val);
                        } else setSelectedValue([]);
                    }}
                    type={selectedAttribute?.dataType}
                    isRequired={true}
                    disabled={
                        !props.initAssignment &&
                        (selectedAttributeSetId === undefined ||
                            selectedAttribute === undefined ||
                            attributeDropDownOptions.length === 0)
                    }
                />
            </div>
            {props.modalState === AssignmentModalState.Add && showAddAssignmentWarning && (
                <span style={{ color: FluentTheme.semanticColors.errorText, fontSize: 12 }}>
                    {'This will overwrite the existing assigned value.'}
                </span>
            )}
            {showErrors && !isValueValid() && (
                <span style={{ color: FluentTheme.semanticColors.errorText, fontSize: 12 }}>
                    A valid Attribute Value is required.
                </span>
            )}
            <Label style={styles.label}>Expiration ({timeZoneAbbr(getLocalTimeZone())})</Label>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1.5fr 1fr 10px',
                    gridColumnGap: '3px',
                }}>
                <DatePicker
                    ariaLabel='Expiration Date picker'
                    value={expirationDate}
                    allowTextInput={true}
                    minDate={new Date()}
                    onSelectDate={(date): void => {
                        if (date) {
                            date = new Date(
                                date.setHours(selectedTime.hours, selectedTime.minutes, 0, 0),
                            );
                        }
                        setExpirationDate(date ?? new Date());
                    }}
                    placeholder='Select a date...'
                />
                <TimePicker
                    ariaLabel='Expiration Time picker'
                    dateAnchor={
                        expirationDate
                            ? new Date(expirationDate)
                            : new Date(new Date().setHours(0, 0, 0, 0))
                    }
                    value={
                        expirationDate
                            ? new Date(expirationDate)
                            : new Date(new Date().setHours(0, 0, 0, 0))
                    }
                    onChange={(ev, time): void => {
                        setSelectedTime({ hours: time.getHours(), minutes: time.getMinutes() });
                        setExpirationDate((date) => {
                            if (date) {
                                const newDate = new Date(date);
                                newDate.setHours(time.getHours(), time.getMinutes(), 0, 0);
                                return newDate;
                            }
                        });
                    }}
                    timeRange={{ start: 0, end: 24 }}
                    increments={1}
                    useHour12
                    placeholder='Select a time...'
                />
                <IconButton
                    iconProps={{ iconName: IconNames.Clear }}
                    ariaLabel='Clear Expiration Date/Time Pickers'
                    onClick={(): void => setExpirationDate(undefined)}
                />
            </div>
            <Label style={styles.label}>Description</Label>
            {selectedAttribute?.description}
        </Panel>
    );
}

const elementMarginTop = '20px';

const styles = {
    element: {
        label: {
            marginTop: elementMarginTop,
        },
    },
    label: {
        marginTop: '25px',
        marginBottom: '5px',
        padding: 0,
        height: '20px',
    },
    button: {
        marginTop: '20px',
        marginBottom: '5px',
        padding: 0,
    },
    panel: {
        root: {
            marginTop: '65px',
            whiteSpace: 'normal !important',
        },
    },
    employeeCard: {
        card: {
            wordWrap: 'break-word',
            width: '200px',
        },
        details: {
            wordWrap: 'break-word',
            whiteSpace: 'normal',
        },
    },
};
