import React, { ReactNode, createContext, useReducer } from 'react';
import { IEmployee } from 'clients/employee-client';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import { GetPrincipalByFieldResult } from 'personnel-core-clients';

type FilterKeyType = keyof IContextState | 'reset';
type FilterDataType = string | IEmployee | GetPrincipalByFieldResult | undefined;

interface IContextState {
    visitorId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    company: string;
    title: string;
    sponsor: IEmployee | GetPrincipalByFieldResult | undefined;
    resetFilter: () => void;
    setItem: (key: FilterKeyType, value: FilterDataType) => void;
}

type actionType = {
    type: string;
    payload: FilterDataType;
};

export const VisitorsFilterContext = createContext<IContextState>(null!);

export interface IVisitorsProviderProps {
    children: ReactNode;
}

export default function VisitorsFilterProvider(props: IVisitorsProviderProps): JSX.Element {
    const reducer = (state: IContextState, action: actionType): IContextState => {
        let result;
        switch (action.type) {
            case 'visitorId':
            case 'firstName':
            case 'middleName':
            case 'lastName':
            case 'email':
            case 'company':
            case 'title':
            case 'sponsor':
                result = {
                    ...state,
                    [action.type]: action.payload,
                };
                break;
            case 'resetFilter':
                result = {
                    ...initValue(),
                };
                break;
            default:
                result = state;
                break;
        }
        return result;
    };

    const initValue = (): IContextState => {
        return {
            visitorId: '',
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            company: '',
            title: '',
            sponsor: undefined,
            resetFilter,
            setItem,
        };
    };

    const [contextValue, dispatch] = useReducer(reducer, initValue());

    function setItem(key: FilterKeyType, value: FilterDataType): void {
        dispatch({ type: key, payload: value });
    }

    function resetFilter(): void {
        dispatch({ type: 'resetFilter', payload: undefined });
    }

    return (
        <VisitorsFilterContext.Provider value={contextValue}>
            {props.children}
        </VisitorsFilterContext.Provider>
    );
}
