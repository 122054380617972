import Tabs, { TabbedContent } from 'components/common/tabs';
import MyGroups from 'components/groups/my-groups/my-groups';
import React, { useContext, useEffect } from 'react';
import { Location } from 'history';
import MyGroupsProvider from 'components/groups/my-groups/my-groups-context';
import { BreadCrumbContext } from 'contexts/breadcrumb-context';
import { CustomBreadcrumb, ICustomBreadcrumbItem } from 'components/common/bread-crumb';
import { useHistory } from 'react-router-dom';
import MyGroupsActionCenterPage from 'components/groups/my-groups//my-groups-action-center-page';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IMyGroupsWrapperProps {
    location: Location;
    match: {
        params: {
            category?: string;
        };
    };
}

const category = {
    MyGroups: {
        text: 'mygroups',
        index: '0',
    },
    ActionCenter: {
        text: 'actioncenter',
        index: '1',
    },
};

const categories = [category.MyGroups, category.ActionCenter];

const myGroupsBreadCrumbItem: ICustomBreadcrumbItem[] = [
    { title: 'Groups', link: '' },
    { title: 'My Groups', link: '' },
];

const actionCenterBreadCrumbItem: ICustomBreadcrumbItem[] = [
    { title: 'Groups', link: '' },
    { title: 'My Groups', link: '' },
    { title: 'Action Center', link: '' },
];

export default function MyGroupsWrapper(props: IMyGroupsWrapperProps): JSX.Element {
    const breadCrumbContext = useContext(BreadCrumbContext);
    const history = useHistory();
    const isGroupsActionCenterEnabled = useFeatureFlag(FeatureFlagKeys.groupsActionCenter).enabled;

    useEffect(() => {
        breadCrumbContext.setBreadCrumbs([
            { title: 'Groups', link: '' },
            { title: 'My Groups', link: '' },
        ]);
    }, []);

    function onTabChange(key?: string): void {
        if (!key) {
            return;
        }

        const selectedCategory = categories.find((x) => x.index === key);
        if (
            selectedCategory &&
            !history.location.pathname.toLowerCase().includes(selectedCategory.text)
        ) {
            history.push(selectedCategory.text);
            updateBreadcrumbs(selectedCategory.text);
        }
    }

    function updateBreadcrumbs(categoryText?: string): void {
        if (!categoryText) {
            return;
        }

        const currentBreadCrumb = getCurrentBreadCrumb(categoryText);

        breadCrumbContext.setBreadCrumbs(currentBreadCrumb);
    }

    function getDefaultTabKey(): string {
        switch (props.match.params.category?.toLowerCase()) {
            case category.ActionCenter.text:
                return category.ActionCenter.index;
            case category.MyGroups.text:
            default:
                return category.MyGroups.index;
        }
    }
    return (
        <>
            {isGroupsActionCenterEnabled ? (
                <div style={{ padding: '0 60px' }}>
                    <CustomBreadcrumb breadCrumbContext={breadCrumbContext} />
                    <Tabs
                        pivotStyle={{ link: { paddingLeft: '4px', marginTop: '10px' } }}
                        selectedKey={getDefaultTabKey()}
                        onChange={onTabChange}>
                        <TabbedContent title='My Groups' tabHeader={'My Groups'}>
                            <MyGroupsProvider>
                                <MyGroups {...props} />
                            </MyGroupsProvider>
                        </TabbedContent>
                        <TabbedContent title='Action Center' tabHeader={'Action Center'}>
                            <MyGroupsActionCenterPage {...props} />
                        </TabbedContent>
                    </Tabs>
                </div>
            ) : (
                <MyGroupsProvider>
                    <MyGroups {...props} />
                </MyGroupsProvider>
            )}
        </>
    );

    function getCurrentBreadCrumb(categoryText: string) {
        switch (categoryText) {
            case category.MyGroups.text:
                return myGroupsBreadCrumbItem;
            case category.ActionCenter.text:
                return actionCenterBreadCrumbItem;
            default:
                return myGroupsBreadCrumbItem;
        }
    }
}
