import React, { useContext, useEffect, useState } from 'react';
import { ClearanceClient, IClearanceRecord, ISpecialAccessRecord } from 'clients/clearance-client';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { Table, TableCell } from 'components/common/table';
import { dateToLocalDate } from 'utils/time-utils';
import {
    getActiveAccessesTitle,
    getClearanceLevelAndAgencyTitle,
    getSpecialAccessesGroupName,
    groupedSpecialAccesses,
    specialAccessStatusSpan,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import { SharedColors } from '@fluentui/theme';
import { IColumn, IGroup } from '@fluentui/react';
import SpecialAccessDetails from 'components/personnel-profile/clearance/special-access-details';
import EllipsisText from 'components/common/ellipsis-text';
import { Dictionary } from 'assets/constants/global-constants';
import GroupSpecialAccessesDetails from 'components/personnel-profile/clearance/group-special-accesses-details';
import { generateRandomKey } from 'utils/misc-utils';
import { commonPersonnelPersonnelStyles } from 'components/personnel-profile/common-personnel-profile-styles';
import { getDefaultColumnWidths } from 'utils/table-utils';
import { UserContext } from 'contexts/user-context';
import { DefaultUserEducationMessage } from 'components/personnel-profile/common/profile-user-education';

interface ISpecialAccessGroup {
    group?: IGroup;
    specialAccesses: ISpecialAccessRecord[];
}

export interface PersonnelProfileSpecialAccessTableProps {
    employee: IEmployeeWithEditableData | undefined;
    clearances: IClearanceRecord[];
    specialAccesses: ISpecialAccessRecord[];
    setSpecialAccesses: (records: ISpecialAccessRecord[]) => void;
    isClearedWorkforceView?: boolean;
}

export default function PersonnelProfileSpecialAccessTable(
    props: PersonnelProfileSpecialAccessTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [specialAccessGroups, setSpecialAccessGroups] = useState<ISpecialAccessGroup[]>([]);

    useEffect(() => {
        if (props.specialAccesses) {
            const groupedSpecialAccessDict: Dictionary<ISpecialAccessRecord[]> = {};
            const newSpecialAccessGroups: ISpecialAccessGroup[] = [];

            props.specialAccesses.forEach((x) => {
                if (isGroupedSpecialAccess(x)) {
                    if (groupedSpecialAccessDict[x.clearanceId as string]) {
                        groupedSpecialAccessDict[x.clearanceId as string].push(x);
                    } else {
                        groupedSpecialAccessDict[x.clearanceId as string] = [x];
                    }
                } else {
                    newSpecialAccessGroups.push({ specialAccesses: [x] });
                }
            });

            Object.values(groupedSpecialAccessDict).forEach((x) => {
                // Ensure the latest creation date time out of each group is at the top
                sortSpecialAccesses(x);

                newSpecialAccessGroups.push({
                    group:
                        x.length > 1
                            ? {
                                  key: generateRandomKey('IGroup'),
                                  name: getSpecialAccessesGroupName(x),
                                  startIndex: 0,
                                  count: x.length,
                                  level: 0,
                                  isCollapsed: true,
                              }
                            : undefined,
                    specialAccesses: x,
                });
            });

            sortSpecialAccessGroups(newSpecialAccessGroups);

            setSpecialAccessGroups(newSpecialAccessGroups);
        }
    }, [props.specialAccesses]);

    function isGroupedSpecialAccess(specialAccessRecord: ISpecialAccessRecord): boolean {
        return (
            specialAccessRecord.clearanceId !== undefined &&
            groupedSpecialAccesses.find((x) => x === specialAccessRecord.specialAccess) !==
                undefined
        );
    }

    function sortSpecialAccesses(specialAccesses: ISpecialAccessRecord[]): void {
        specialAccesses.sort((a, b) => {
            const bDateTime = b.creationDateTimeUTCMilliseconds ?? 0;
            const aDateTime = a.creationDateTimeUTCMilliseconds ?? 0;
            return bDateTime - aDateTime;
        });
    }

    function sortSpecialAccessGroups(specialAccessGroups: ISpecialAccessGroup[]): void {
        specialAccessGroups.sort((a, b) => {
            const bDateTime =
                b.specialAccesses.length > 0
                    ? b.specialAccesses[0].creationDateTimeUTCMilliseconds ?? 0
                    : 0;
            const aDateTime =
                a.specialAccesses.length > 0
                    ? a.specialAccesses[0].creationDateTimeUTCMilliseconds ?? 0
                    : 0;
            return bDateTime - aDateTime;
        });
    }

    function createRightEtiquette(
        etiquetteSpecialAccesses: ISpecialAccessRecord[],
    ): { label: string; backgroundColor: string; maxLength: number } | undefined {
        if (props.isClearedWorkforceView) {
            return undefined;
        }

        const title = getActiveAccessesTitle(etiquetteSpecialAccesses);

        return title
            ? {
                  label: title,
                  backgroundColor: SharedColors.cyanBlue10,
                  maxLength: 100,
              }
            : undefined;
    }

    async function upsertSpecialAccessRecords(records: ISpecialAccessRecord[]): Promise<boolean> {
        try {
            const result = await ClearanceClient.upsertSpecialAccesses(authContext, records);
            const updatedIds = new Set<string>(result.map((x) => x.id));
            const newSpecialAccesses = props.specialAccesses.filter((x) => !updatedIds.has(x.id));
            props.setSpecialAccesses([...result, ...newSpecialAccesses]);
            return true;
        } catch (e) {
            console.error('error on upsert', e);
            return false;
        }
    }

    async function deleteSpecialAccessRecord(id: string): Promise<boolean> {
        return await deleteSpecialAccessRecords([id]);
    }

    async function deleteSpecialAccessRecords(ids: string[]): Promise<boolean> {
        try {
            await ClearanceClient.deleteSpecialAccesses(authContext, ids);
            const deletedIds = new Set<string>(ids);
            const newSpecialAccesses = props.specialAccesses.filter((x) => !deletedIds.has(x.id));
            props.setSpecialAccesses(newSpecialAccesses);
            return true;
        } catch (e) {
            console.error('error on delete', e);
            return false;
        }
    }

    function generateSpecialAccessTable(index: number): JSX.Element {
        if (index === specialAccessGroups.length - 1) {
            return getTable(index);
        }

        return (
            <>
                {getTable(index)}
                {generateSpecialAccessTable(index + 1)}
            </>
        );
    }

    function getTable(index: number): JSX.Element {
        if (!specialAccessGroups || index < 0 || index >= specialAccessGroups.length) {
            return <></>;
        }

        const specialAccessGroup = specialAccessGroups[index];

        return (
            <Table<ISpecialAccessRecord>
                collapseButton={{ isVisible: false }}
                isFetchingData={false}
                isColumnHeader={index === 0} // only show column header for first special access group
                groupProps={
                    specialAccessGroup.group
                        ? {
                              onRenderHeader: (prop, defaultRender) => {
                                  return (
                                      <>
                                          {defaultRender!({
                                              ...prop,
                                              // need to hide the header overflow, otherwise the table's width will grow infinitely
                                              styles: { root: { overflow: 'hidden' } },
                                              onRenderTitle: () => {
                                                  return (
                                                      <>
                                                          <div
                                                              style={{
                                                                  display: 'flex',
                                                                  width: '100%',
                                                              }}>
                                                              <span>
                                                                  {specialAccessGroup.group?.name}
                                                              </span>
                                                              <span style={{ flexGrow: 1 }}>
                                                                  <GroupSpecialAccessesDetails
                                                                      specialAccesses={
                                                                          specialAccessGroup.specialAccesses
                                                                      }
                                                                      upsertRecordsFunction={
                                                                          upsertSpecialAccessRecords
                                                                      }
                                                                      deleteRecordsFunction={
                                                                          deleteSpecialAccessRecords
                                                                      }
                                                                      clearances={props.clearances}
                                                                      isClearedWorkforceView={
                                                                          props.isClearedWorkforceView
                                                                      }
                                                                  />
                                                              </span>
                                                          </div>
                                                      </>
                                                  );
                                              },
                                          })}
                                      </>
                                  );
                              },
                          }
                        : undefined
                }
                groups={specialAccessGroup.group ? [specialAccessGroup.group] : undefined}
                detailsRowStyles={
                    specialAccessGroup.group ? { root: { marginLeft: -36 } } : undefined
                }
                rows={specialAccessGroup.specialAccesses}
                tableColumns={generateColumns(
                    props.employee,
                    upsertSpecialAccessRecords,
                    deleteSpecialAccessRecord,
                    props.clearances,
                    props.isClearedWorkforceView,
                )}
                tableName='Special Access Records'
            />
        );
    }

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Special access'
                    rightEtiquette={createRightEtiquette(props.specialAccesses)}
                    bottomLeftInfo={props.isClearedWorkforceView && <DefaultUserEducationMessage />}
                    bottomInfo={
                        !props.isClearedWorkforceView && (
                            <SpecialAccessDetails
                                buttonIcon='AddToShoppingList'
                                buttonText='Add'
                                modalTitle={`Add Special Accesses`}
                                employee={props.employee}
                                upsertRecordsFunction={upsertSpecialAccessRecords}
                                clearances={props.clearances}
                            />
                        )
                    }>
                    {props.specialAccesses.length === 0 || specialAccessGroups.length === 0 ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraphMorePadding}>
                            No special access information to display
                        </p>
                    ) : (
                        generateSpecialAccessTable(0)
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

function generateColumns(
    employee: IEmployeeWithEditableData | undefined,
    upsertRecords: { (records: ISpecialAccessRecord[]): Promise<boolean> },
    deleteRecord: { (id: string): Promise<boolean> },
    clearances: IClearanceRecord[],
    isClearedWorkforceView?: boolean,
): IColumn[] {
    const columnWidths = getDefaultColumnWidths(6);
    return [
        {
            key: 'special_access',
            name: 'Special Access',
            ariaLabel: 'Special Access',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
            onRender: (row: ISpecialAccessRecord): JSX.Element => (
                <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>
                    <EllipsisText text={row.specialAccess} textLengthBeforeEllipsis={17} />
                </TableCell>
            ),
        },
        {
            key: 'special_access_status',
            name: 'Status',
            ariaLabel: 'Status',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true, // Style is taken from get-candidates-table-columns.tsx#getStatusLabel()
            onRender: (row: ISpecialAccessRecord): JSX.Element => (
                <TableCell>{specialAccessStatusSpan(row)}</TableCell>
            ),
        },
        {
            key: 'special_access_brief_date',
            name: 'Date Briefed',
            ariaLabel: 'Date Briefed',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISpecialAccessRecord): JSX.Element => (
                <TableCell>{dateToLocalDate(row.briefDateUTCMilliseconds)}</TableCell>
            ),
        },
        {
            key: 'special_access_clearance',
            name: 'Clearance',
            ariaLabel: 'Clearance',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISpecialAccessRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText
                        text={getClearanceLevelAndAgencyTitle(row.clearance)}
                        textLengthBeforeEllipsis={11}
                    />
                </TableCell>
            ),
        },
        {
            key: 'special_access_contract',
            name: 'Contract',
            ariaLabel: 'Contract',
            minWidth: columnWidths.defaultColumnWidth,
            maxWidth: columnWidths.defaultColumnWidth,
            isRowHeader: true,
            onRender: (row: ISpecialAccessRecord): JSX.Element => (
                <TableCell>
                    <EllipsisText text={row?.clearance?.contractId} textLengthBeforeEllipsis={23} />
                </TableCell>
            ),
        },
        {
            key: 'special_access_action',
            name: 'Action',
            ariaLabel: 'Action',
            minWidth: columnWidths.actionColumnWidth,
            maxWidth: columnWidths.actionColumnWidth,
            isRowHeader: true,
            onRender: (row: ISpecialAccessRecord): JSX.Element => (
                <TableCell>
                    <SpecialAccessDetails
                        buttonIcon='EntryView'
                        specialAccess={row}
                        buttonText='View'
                        modalTitle={`Special Access: ${row.specialAccess?.toLocaleUpperCase()}`}
                        employee={employee}
                        upsertRecordsFunction={upsertRecords}
                        deleteRecordFunction={deleteRecord}
                        clearances={clearances}
                        isClearedWorkforceView={isClearedWorkforceView}
                    />
                </TableCell>
            ),
        },
    ];
}
