export const AccessDeniedURL = '/access-denied';

// Icons can be found at https://developer.microsoft.com/en-us/fluentui#/styles/web/icons
export enum IconNames {
    AccountManagement = 'AccountManagement',
    Add = 'Add',
    AddGroup = 'AddGroup',
    AddFriend = 'AddFriend',
    AddNotes = 'AddNotes',
    AirTickets = 'AirTickets',
    AddToShoppingList = 'AddToShoppingList',
    AppIconDefaultAdd = 'AppIconDefaultAdd',
    Back = 'Back',
    BarChartVerticalFill = 'BarChartVerticalFill',
    Blocked2 = 'Blocked2',
    BlockContact = 'BlockContact',
    BulkUpload = 'BulkUpload',
    BulletedList = 'BulletedList',
    BulletedList2 = 'BulletedList2',
    CalculatorSubtract = 'CalculatorSubtract',
    Calendar = 'Calendar',
    Cancel = 'Cancel',
    CaretDownSolid8 = 'CaretDownSolid8',
    ChangeEntitlements = 'ChangeEntitlements',
    Checkbox = 'Checkbox',
    CheckboxComposite = 'CheckboxComposite',
    CheckList = 'CheckList',
    CheckMark = 'CheckMark',
    ChevronDown = 'ChevronDown',
    ChevronRight = 'ChevronRight',
    CircleFill = 'CircleFill',
    CirclePlus = 'CirclePlus',
    CircleRing = 'CircleRing',
    Clear = 'Clear',
    ClearFilter = 'ClearFilter',
    ClipboardList = 'ClipboardList',
    CloudDownload = 'CloudDownload',
    Comment = 'comment',
    ComplianceAudit = 'ComplianceAudit',
    Contact = 'Contact',
    Copy = 'Copy',
    Completed = 'Completed',
    CompletedSolid = 'CompletedSolid',
    CustomList = 'CustomList',
    Delete = 'Delete',
    DeveloperTools = 'DeveloperTools',
    Download = 'Download',
    FabricFolder = 'FabricFolder',
    FabricSyncFolder = 'FabricSyncFolder',
    FabricUnsyncFolder = 'FabricUnsyncFolder',
    FavoriteStar = 'FavoriteStar',
    Filter = 'Filter',
    FilterSettings = 'FilterSettings',
    Flag = 'Flag',
    Edit = 'EditSolid12',
    EditNoFill = 'Edit',
    Error = 'Error',
    ErrorBadge = 'ErrorBadge',
    EntitlementPolicy = 'EntitlementPolicy',
    EntitlementRedemption = 'EntitlementRedemption',
    EntryView = 'EntryView',
    ExcelLogo = 'ExcelLogo',
    History = 'History',
    IncidentTriangle = 'IncidentTriangle',
    IDBadge = 'IDBadge',
    ImagePixel = 'ImagePixel',
    Info = 'Info',
    Leave = 'Leave',
    LightningBolt = 'LightningBolt',
    Link = 'Link',
    Lock = 'Lock',
    Mail = 'Mail',
    MailAlert = 'MailAlert',
    MailCheck = 'MailCheck',
    MailPause = 'MailPause',
    MailReminder = 'MailReminder',
    OpenFile = 'OpenFile',
    OpenInNewTab = 'OpenInNewTab',
    Org = 'Org',
    PageLink = 'PageLink',
    PageList = 'PageList',
    PenWorkspace = 'PenWorkspace',
    PeopleAdd = 'PeopleAdd',
    PreviewLink = 'PreviewLink',
    QuickNote = 'QuickNote',
    RadioBullet = 'RadioBullet',
    Refresh = 'refresh',
    ReminderPerson = 'ReminderPerson',
    Remove = 'Remove',
    RemoveFromShoppingList = 'RemoveFromShoppingList',
    RemoveLinkChain = 'RemoveLinkChain',
    ReservationOrders = 'ReservationOrders',
    Save = 'Save',
    Search = 'Search',
    Separator = 'Separator',
    Send = 'Send',
    Settings = 'Settings',
    Signin = 'Signin',
    SignOut = 'SignOut',
    SkypeCheck = 'SkypeCheck',
    SkypeCircleCheck = 'SkypeCircleCheck',
    SortDown = 'SortDown',
    SortUp = 'SortUp',
    X = 'StatusCircleErrorX',
    Switch = 'Switch',
    SyncOccurence = 'SyncOccurence',
    Table = 'Table',
    TaskManager = 'TaskManager',
    TextField = 'TextField',
    Toolbox = 'Toolbox',
    Trash = 'Trash',
    Unknown = 'Unknown',
    Unlock = 'Unlock',
    UserFollowed = 'UserFollowed',
    UserOptional = 'UserOptional',
    UserRemove = 'UserRemove',
    UserWarning = 'UserWarning',
    View = 'View',
    Warning = 'Warning',
    World = 'World',
}

export const manageTitle = 'Manage';
export const noDataText = 'N/A';

export type Dictionary<T> = { [key: string]: T };

export interface IResponseError extends Error {
    status?: number;
}

// The following is used in lists that use <Table> or <DetailsList>.
// It's used when defining column widths.
export const maxWidthCoeff = 1.5;
export const largeMaxWidthCoeff = 2;
export const xLargeMaxWidthCoeff = 2.5;
export const xxLargeMaxWidthCoeff = 3;
export const xxxLargeMaxWidthCoeff = 3.5;
export const xxxxLargeMaxWidthCoeff = 4;
export const xxxxxLargeMaxWidthCoeff = 4.5;

// The following characters should not be in Id: '/', '\\', '?' and '#'.
// Reference: https://learn.microsoft.com/en-us/dotnet/api/microsoft.azure.documents.resource.id?view=azure-dotnet#remarks
export const cosmosDbIdSpecialCharacters: string[] = ['/', '\\', '?', '#'];

export const hasCosmostDbIdSpecialCharacters = (str: string): boolean => /[\\\/\?\#]/.test(str);
