import {
    DatePicker,
    ActionButton,
    Dropdown,
    IDropdownOption,
    IStackItemStyles,
    Label,
    MessageBar,
    MessageBarType,
    Stack,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { IClearanceRecord, IPolygraphRecord } from 'clients/clearance-client';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { displayRequiredFieldsErrors } from 'utils/error-display-utils';
import { dateToLocalDate } from 'utils/time-utils';
import BasicDetailsModal, {
    ButtonParams,
    EditParams,
    SaveParams,
} from 'components/personnel-profile/common/basic-details-modal';
import {
    createClearanceDropdownOptions,
    findPolygraph,
    getModalPixelWidthValueBasedOnTextLength,
    polygraphResultSpan,
    polygraphTypeDropdownOptions,
    resultDropdownOptions,
    clearanceStringStatusSpan,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import { labelStackItem } from 'components/personnel-profile/common/common-types';

const ValidFieldsNameIndx = 0;
const ValidFieldsErrorTextIndx = 1;
const validFields: string[][] = [
    ['results', 'polygraph result'],
    ['clearanceId', 'clearance'],
    ['polygraphType', 'polygraph type'],
    ['testDateUTCMilliseconds', 'test date'],
];

enum Mode {
    Edit,
    View,
}

export interface PolygraphDetailsProps {
    polygraph?: IPolygraphRecord;
    employee?: IEmployeeWithEditableData;
    buttonText: string;
    buttonIcon: string;
    modalTitle: string;
    upsertRecordFunction: (record: IPolygraphRecord) => Promise<boolean>;
    deleteRecordFunction?: (id: string) => Promise<boolean>;
    clearances: IClearanceRecord[];
    isClearedWorkforceView?: boolean;
}

export default function PolygraphDetails(props: PolygraphDetailsProps): JSX.Element {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentMode, setMode] = useState<Mode>(Mode.View);
    const [basicDetailsModalEditButton, setBasicDetailsModalEditButton] = useState<ButtonParams>(
        props.polygraph ? EditParams : SaveParams,
    );
    const [basicDetailsModalCloseButtonTitle, setBasicDetailsModalCloseButtonTitle] = useState<
        'Close' | 'Cancel'
    >('Close');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [errorMsg, setErrorMsg] = useState<JSX.Element>();

    const [saveObject, setSaveObject] = useState<IPolygraphRecord | undefined>();

    const [isDeleteDialogAutoClosed, setDeleteDialogAutoClosed] = useState<boolean>(false);

    useEffect(() => {
        if (props.polygraph) {
            setMode(Mode.View);
        } else {
            setMode(Mode.Edit);
        }
    }, [props.polygraph, props.employee]);
    const minModalValueWidth = 35;
    const valueStackItem: IStackItemStyles = {
        root: {
            padding: 5,
            width: getModalPixelWidthValueBasedOnTextLength(minModalValueWidth),
        },
    };

    function onEditButtonPress(): void {
        if (currentMode === Mode.View) {
            setMode(Mode.Edit);
        } else {
            if (saveObject && validatePolygraph(saveObject)) {
                setIsDisabled(true);
                props.upsertRecordFunction(saveObject).then((result: boolean) => {
                    setIsDisabled(false);
                    if (result) {
                        setModalOpen(false);
                    } else {
                        setErrorMsg(
                            <span>
                                Personnel is unable to save this record, please reach out to
                                Personnel for support.
                            </span>,
                        );
                    }
                });
            }
        }
    }

    function validatePolygraph(polygraphRecord: IPolygraphRecord | undefined): boolean {
        if (polygraphRecord) {
            const foundValid: string[][] = [];
            for (const [key, value] of Object.entries(polygraphRecord)) {
                const foundField = validFields.find((x) => x[ValidFieldsNameIndx] === key);
                if (foundField && value) {
                    foundValid.push(foundField);
                }
            }
            if (foundValid.length === validFields.length) {
                return true;
            }
            const invalidFields = validFields
                .filter((x) => foundValid.findIndex((y) => y === x) === -1)
                .map((x) => {
                    return x[ValidFieldsErrorTextIndx];
                });
            setErrorMsg(displayRequiredFieldsErrors(invalidFields));
        }
        return false;
    }

    function onDeleteButtonPress(): void {
        if (props.deleteRecordFunction && saveObject && saveObject.id) {
            setIsDisabled(true);
            props.deleteRecordFunction(saveObject.id).then((result: boolean) => {
                setIsDisabled(false);
                if (result) {
                    setModalOpen(false);
                } else {
                    setDeleteDialogAutoClosed(!isDeleteDialogAutoClosed);
                    setErrorMsg(
                        <span>
                            Personnel is unable to save this record, please reach out to Personnel
                            for support.
                        </span>,
                    );
                }
            });
        }
    }

    function onCloseButtonPress(): void {
        if (currentMode === Mode.View || props.polygraph === undefined) {
            setModalOpen(false);
        } else {
            setMode(Mode.View);
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            setSaveObject(
                props.polygraph
                    ? { ...props.polygraph }
                    : ({ personnelId: props.employee?.data?.id } as IPolygraphRecord),
            );
            if (currentMode === Mode.Edit) {
                setBasicDetailsModalEditButton(SaveParams);
                setBasicDetailsModalCloseButtonTitle('Cancel');
            } else {
                setBasicDetailsModalEditButton(EditParams);
                setBasicDetailsModalCloseButtonTitle('Close');
            }
        }
    }, [isModalOpen, currentMode]);

    function canEdit(): boolean {
        return currentMode === Mode.Edit;
    }

    function canDelete(): boolean {
        return (
            !props.isClearedWorkforceView &&
            props.deleteRecordFunction !== undefined &&
            currentMode === Mode.View
        );
    }

    function changeValue(property: string, value: string | number | undefined): void {
        //Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        newSaveObject[`${property}`] = value;
        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    return (
        <Stack>
            <ActionButton
                onClick={(): void => {
                    setModalOpen(true);
                }}
                iconProps={{ iconName: props.buttonIcon }}
                text={props.buttonText}
                styles={{ root: { maxHeight: contentMaxHeight } }}
            />

            <BasicDetailsModal
                title={props.modalTitle}
                editButton={basicDetailsModalEditButton}
                closeTitle={basicDetailsModalCloseButtonTitle}
                isOpen={isModalOpen}
                onEditClick={props.isClearedWorkforceView ? undefined : onEditButtonPress}
                onCloseClick={onCloseButtonPress}
                onDeleteClick={canDelete() ? onDeleteButtonPress : undefined}
                deleteDialogProps={{
                    title: 'Delete Confirmation',
                    subText: 'Are you sure you want to permanently delete this polygraph record?',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    autoClosed: isDeleteDialogAutoClosed,
                }}
                actionButtonDisabled={isDisabled}>
                <Stack>
                    <ActionButton
                        // The purpose of this button is to catch the intial propagated onClick event
                        // when the 'add polygraph' button is clicked to open up this modal.
                        // Otherwise the first stack item's onChange event will trigger e.g. in this case
                        // the dropdown will select and display the top item from the dropdown selection
                        style={{ maxHeight: 0, maxWidth: 0 }}
                        hidden={true}
                        onClick={(event): void => {
                            event.stopPropagation();
                        }}
                    />

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Type</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {findPolygraph(props.polygraph?.polygraphType)?.longHand ??
                                            props.polygraph?.polygraphType}
                                    </>
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('polygraphType', option?.key);
                                        }}
                                        placeholder='Select polygraph type'
                                        options={polygraphTypeDropdownOptions(
                                            findPolygraph(props.polygraph?.polygraphType),
                                        )}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Test</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>{dateToLocalDate(props.polygraph?.testDateUTCMilliseconds)}</>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Test Date'
                                        placeholder='Select test date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.testDateUTCMilliseconds
                                                ? new Date(saveObject.testDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'testDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Clearance</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                <>
                                    {!canEdit() && props.polygraph && (
                                        <>{clearanceStringStatusSpan(props.polygraph?.clearance)}</>
                                    )}
                                    {canEdit() && (
                                        <Dropdown
                                            onChange={(
                                                event: React.FormEvent<HTMLDivElement>,
                                                option?: IDropdownOption,
                                                index?: number,
                                            ): void => {
                                                changeValue('clearanceId', option?.key);
                                                if (saveObject) {
                                                    saveObject.clearance = props.clearances.find(
                                                        (x) => option?.key === x.id,
                                                    );
                                                    saveObject.clearanceId =
                                                        saveObject.clearance?.id;
                                                }
                                            }}
                                            defaultSelectedKey={
                                                saveObject && saveObject.clearanceId
                                            }
                                            placeholder='Select clearance'
                                            options={createClearanceDropdownOptions(
                                                props.clearances,
                                            )}
                                        />
                                    )}
                                </>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    {!canEdit() && (
                        <Stack.Item>
                            <Stack horizontal>
                                <Stack.Item align='center' styles={labelStackItem}>
                                    <Label>Contract</Label>
                                </Stack.Item>
                                <Stack.Item align='center' styles={valueStackItem}>
                                    <>{`${
                                        props.polygraph?.clearance?.contractId
                                            ? props.polygraph?.clearance?.contractId
                                            : ''
                                    }`}</>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    )}

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Completion</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.polygraph?.completionDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Completion Date'
                                        placeholder='Select completion date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.completionDateUTCMilliseconds
                                                ? new Date(saveObject.completionDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'completionDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Result</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && <>{polygraphResultSpan(props?.polygraph)}</>}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('results', option?.key);
                                        }}
                                        placeholder='Select result'
                                        options={resultDropdownOptions(props.polygraph?.results)}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>

                {errorMsg && (
                    <Stack.Item>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'>
                            {errorMsg}
                        </MessageBar>
                    </Stack.Item>
                )}
            </BasicDetailsModal>
        </Stack>
    );
}
