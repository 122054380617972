import { SeverityLevel } from '@microsoft/applicationinsights-web';
import FacilitiesClient, {
    IFacilitiesTokenResult,
    isFacilityKioskClientPath,
} from 'clients/facilities-client';
import { FacilityUserType } from 'utils/facilities-utils';
import PublicTrustScreeningClient from 'clients/screening/public-trust-screening-client';
import { UsGovScreeningUserType } from 'components/screening/common/common-constants';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { getEnumFromString } from 'utils/enum-utils';
import { utcSecondsToIsoDateString } from 'utils/time-utils';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import CloudScreeningClient from 'clients/cloud-screening-client';
import { CloudScreeningUserType } from 'utils/cloud-screening-utils';
import { IEmployee } from 'clients/employee-client';
import ScaClient from 'clients/sca-client';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { Role } from 'configs/roles';
import { logMessage } from 'utils/telemetry-utils';
import { AuthContext, IAuthContext, IAuthProfile } from 'contexts/auth-context';

// null! needed for creating context without specifying default values required by typescript
export const UserContext = createContext<IUserContext>(null!);

export interface IUserProviderProps {
    msalUser: IAuthProfile;
    employeeRecord: IEmployee;
    children: ReactNode;
}

function UserContextProvider(props: IUserProviderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const screeningCore = useFeatureFlag(FeatureFlagKeys.screeningCore);
    const facilitiesCore = useFeatureFlag(FeatureFlagKeys.facilitiesCore);
    const scaCore = useFeatureFlag(FeatureFlagKeys.scaCore);

    const [msalUser, employeeRecord] = [props.msalUser, props.employeeRecord];

    const [isScaManager, setUserScaManagerStatus] = useState<boolean>(false);
    const [isScaMember, setUserScaMemberStatus] = useState<boolean>(false);
    const [isScaStatusChecked, setIsScaStatusChecked] = useState<boolean>(false);
    const [isUsGovScreeningUserTypesLoaded, setUsGovScreeningUserTypesLoaded] = useState<boolean>(
        false,
    );
    const [isPublicTrustUserTypesLoaded, setPublicTrustUserTypesLoaded] = useState<boolean>(false);
    const [isCloudScreeningUserTypesLoaded, setCloudScreeningUserTypesLoaded] = useState<boolean>(
        false,
    );
    const [usGovScreeningUserTypes, setUsGovScreeningUserTypes] = useState<Set<string>>(
        new Set<string>(),
    );
    const [publicTrustScreeningUserTypes, setPublicTrustScreeningUserTypes] = useState<Set<string>>(
        new Set<string>(),
    );
    const [cloudScreeningUserTypes, setCloudScreeningUserTypes] = useState<Set<string>>(
        new Set<string>(),
    );

    const [isFacilitiesTokenLoaded, setFacilitiesTokenLoaded] = useState(false);

    async function CheckScaStatus(
        varAuthContext: IAuthContext,
        employee: IEmployee | { id: string },
    ): Promise<void> {
        setIsScaStatusChecked(false);
        try {
            const isScaManagerPromise: Promise<boolean> = ScaClient.isScaManager(
                varAuthContext,
                employee.id,
            );
            const isScaMemberPromise: Promise<boolean> = ScaClient.isScaMember(
                varAuthContext,
                employee.id,
            );

            const [isScaManager, isScaMember] = await Promise.all([
                isScaManagerPromise,
                isScaMemberPromise,
            ]);

            setUserScaManagerStatus(isScaManager);
            setUserScaMemberStatus(isScaMember);
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'User-Context',
                message: `Checked SCA status for ${oid}`,
                severityLevel: SeverityLevel.Information,
            });
        } catch (e) {
            console.error('Error checking SCA satus in userContext', e);
        } finally {
            setIsScaStatusChecked(true);
        }
    }

    async function SetScreeningUserTypes(varAuthContext: IAuthContext): Promise<void> {
        try {
            setUsGovScreeningUserTypesLoaded(false);
            const types = await getUSGovUserTypes(varAuthContext);
            setUsGovScreeningUserTypes(types);
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'user-context | SetScreeningUserTypes',
                message: `Set Screening user types for ${oid}`,
                severityLevel: SeverityLevel.Information,
            });
        } catch (e) {
            console.error('Error with setting up ScreeningUserTypes in userContext', e);
        } finally {
            setUsGovScreeningUserTypesLoaded(true);
        }
    }

    async function getUSGovUserTypes(varAuthContext: IAuthContext): Promise<Set<string>> {
        const userTypes = await UsGovScreeningClient.getScreeningUserTypesForUser(varAuthContext);
        const types = new Set<string>();
        userTypes.types?.forEach((type) => {
            types.add(type);
        });
        return types;
    }

    async function SetPublicTrustUserTypes(varAuthContext: IAuthContext): Promise<void> {
        try {
            setPublicTrustUserTypesLoaded(false);
            const types = await getPublicTrustUserTypes(varAuthContext);
            setPublicTrustScreeningUserTypes(types);
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'user-context | SetPublicTrustUserTypes',
                message: `Set Public Trust user types for ${oid}`,
                severityLevel: SeverityLevel.Information,
            });
        } catch (e) {
            console.error('Error with setting up ScreeningUserTypes in userContext', e);
        } finally {
            setPublicTrustUserTypesLoaded(true);
        }
    }

    async function getPublicTrustUserTypes(varAuthContext: IAuthContext): Promise<Set<string>> {
        const userTypes = await PublicTrustScreeningClient.getUserTypes(varAuthContext);
        const types = new Set<string>();
        userTypes.forEach((type) => {
            types.add(type);
        });
        return types;
    }

    async function GetCloudScreeningUserTypes(varAuthContext: IAuthContext): Promise<void> {
        try {
            setCloudScreeningUserTypesLoaded(false);
            const userTypes = await CloudScreeningClient.getUserTypes(varAuthContext);
            const types: Set<string> = new Set<string>(userTypes);
            setCloudScreeningUserTypes(types);
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'user-context | GetCloudScreeningUserTypes',
                message: `Set Cloud Screening user types for ${oid}`,
                severityLevel: SeverityLevel.Information,
            });
        } catch (e) {
            console.error('Error with setting up CloudScreeningUserTypes in userContext', e);
        } finally {
            setCloudScreeningUserTypesLoaded(true);
        }
    }

    async function InitializeFacilitiesToken(varAuthContext: IAuthContext): Promise<void> {
        try {
            setFacilitiesTokenLoaded(false);
            await FacilitiesClient.getFacilitiesToken(varAuthContext);
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'user-context | InitializeFacilitiesToken',
                message: `Initialized facilities token for ${oid}`,
                severityLevel: SeverityLevel.Information,
            });
        } catch (e) {
            console.error('Error with initializing FacilitiesToken in userContext', e);
        } finally {
            setFacilitiesTokenLoaded(true);
        }
    }

    useEffect(() => {
        // Requires employee data to exist in EDS
        if (authContext && employeeRecord?.id) {
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'User-Context',
                message: `Getting user types and status for ${oid}. `,
                severityLevel: SeverityLevel.Information,
            });
            // Facilities kiosk client doesn't care about groups, SCA, or any of the screening services so avoid calling their APIs
            if (!isFacilityKioskClientPath()) {
                if (scaCore.enabled) {
                    CheckScaStatus(authContext, employeeRecord);
                }
                if (screeningCore.enabled) {
                    SetScreeningUserTypes(authContext);
                    SetPublicTrustUserTypes(authContext);
                    GetCloudScreeningUserTypes(authContext);
                }
            }

            if (facilitiesCore.enabled) {
                InitializeFacilitiesToken(authContext);
            }
        }
    }, []);

    function hasAccessAny(checkAccessTypes: CheckAccessType[]): boolean {
        return checkAccessTypes.some(async (checkAccessType) => await hasAccess(checkAccessType));
    }

    async function screeningHasAccessAny(checkAccessTypes: CheckAccessType[]): Promise<boolean> {
        let accesses: boolean[] = [];
        if (checkAccessTypes.length === 0) {
            return false;
        }
        const hasAccessBool = await hasAccess(checkAccessTypes[0]);
        accesses.push(hasAccessBool);
        const restOfArray = await Promise.all(
            checkAccessTypes.slice(1).map((checkAccessType) => hasAccess(checkAccessType)),
        );

        accesses = accesses.concat(restOfArray);
        return accesses.some((x) => x);
    }

    /**
     * Check the current user's access to various supported Service Providers.
     */
    async function hasAccess(checkAccessType: CheckAccessType): Promise<boolean> {
        const serviceProvider: ServiceProviderType = checkAccessType.serviceProvider;
        const oid = authContext.getUserProfile()?.oid ?? '';

        switch (serviceProvider) {
            case ServiceProviderType.CloudScreening:
                logMessage({
                    method: 'User-Context : hasAccess',
                    message: `Checking Clound Screening Access ${oid}`,
                    severityLevel: SeverityLevel.Information,
                });
                return checkCloudScreeningAccess(checkAccessType);
            case ServiceProviderType.Facility:
                logMessage({
                    method: 'User-Context : hasAccess',
                    message: `Checking Facility Access ${oid}`,
                    severityLevel: SeverityLevel.Information,
                });
                return checkFacilityAccess(checkAccessType);
            case ServiceProviderType.Role:
                logMessage({
                    method: 'User-Context : hasAccess',
                    message: `Checking Role Access ${oid}`,
                    severityLevel: SeverityLevel.Information,
                });
                return checkRoleAccess(checkAccessType);
            case ServiceProviderType.UsGovScreening:
                logMessage({
                    method: 'User-Context : hasAccess',
                    message: `Checking Us Gov Screening Access ${oid}`,
                    severityLevel: SeverityLevel.Information,
                });
                return checkUsGovScreeningAccess(checkAccessType);
            case ServiceProviderType.PublicTrust:
                logMessage({
                    method: 'User-Context : hasAccess',
                    message: `Checking Public Trust Access ${oid}`,
                    severityLevel: SeverityLevel.Information,
                });
                return checkPublicTrustAccess(checkAccessType);
            default:
                logMessage({
                    method: 'User-Context : hasAccess',
                    message: `Attempted call to default check access path for ${oid}. Returning false.`,
                    severityLevel: SeverityLevel.Information,
                });
                return false;
        }
    }

    function checkCloudScreeningAccess(checkAccessType: CheckAccessType): boolean {
        const enumValueCloud: CloudScreeningUserType | undefined = getEnumFromString(
            CloudScreeningUserType,
            checkAccessType.value,
        );

        const shouldGrantPermission: boolean = enumValueCloud
            ? hasCloudScreeningUserType(enumValueCloud)
            : false;

        return shouldGrantPermission;
    }

    function checkFacilityAccess(checkAccessType: CheckAccessType): boolean {
        const enumValueFacility: FacilityUserType | undefined = getEnumFromString(
            FacilityUserType,
            checkAccessType.value,
        );

        const shouldGrantPermission: boolean = enumValueFacility
            ? hasFacilitiesUserType(enumValueFacility)
            : false;

        return shouldGrantPermission;
    }

    function checkRoleAccess(checkAccessType: CheckAccessType): boolean {
        const enumValueRole: Role | undefined = getEnumFromString(Role, checkAccessType.value);

        const shouldGrantPermission: boolean = enumValueRole
            ? authContext.isInRole(enumValueRole)
            : false;

        return shouldGrantPermission;
    }

    async function checkUsGovScreeningAccess(checkAccessType: CheckAccessType): Promise<boolean> {
        const enumValueUsGov: UsGovScreeningUserType | undefined = getEnumFromString(
            UsGovScreeningUserType,
            checkAccessType.value,
        );
        if (isUsGovScreeningUserTypesLoaded) {
            return enumValueUsGov ? hasUsGovScreeningUserType(enumValueUsGov) : false;
        } else {
            const types = await getUSGovUserTypes(authContext);
            setUsGovScreeningUserTypes(types);
            setUsGovScreeningUserTypesLoaded(true);
            return enumValueUsGov ? hasUserType(types, enumValueUsGov) : false;
        }
    }

    async function checkPublicTrustAccess(checkAccessType: CheckAccessType): Promise<boolean> {
        const enumValuePublicTrust: UsGovScreeningUserType | undefined = getEnumFromString(
            UsGovScreeningUserType,
            checkAccessType.value,
        );
        if (isPublicTrustUserTypesLoaded) {
            return enumValuePublicTrust ? hasPublicTrustUserType(enumValuePublicTrust) : false;
        } else {
            const types = await getPublicTrustUserTypes(authContext);
            setPublicTrustScreeningUserTypes(types);
            setPublicTrustUserTypesLoaded(true);
            return enumValuePublicTrust ? hasUserType(types, enumValuePublicTrust) : false;
        }
    }

    function hasUserType(
        userTypes: Set<string>,
        userType: CloudScreeningUserType | UsGovScreeningUserType,
    ): boolean {
        return userTypes.has(userType);
    }

    function hasUsGovScreeningUserType(userType: UsGovScreeningUserType): boolean {
        return hasUserType(usGovScreeningUserTypes, userType);
    }

    function hasPublicTrustUserType(userType: UsGovScreeningUserType): boolean {
        return hasUserType(publicTrustScreeningUserTypes, userType);
    }

    function hasCloudScreeningUserType(userType: CloudScreeningUserType): boolean {
        return hasUserType(cloudScreeningUserTypes, userType);
    }

    function isNSTUserType(): boolean {
        return (
            hasUsGovScreeningUserType(UsGovScreeningUserType.NST) ||
            hasPublicTrustUserType(UsGovScreeningUserType.NST)
        );
    }

    function hasFacilitiesUserType(
        userType: FacilityUserType,
        facilitiesToken?: IFacilitiesTokenResult,
    ): boolean {
        return FacilitiesClient.hasUserTypeCachedToken(userType, facilitiesToken);
    }

    async function refreshFacilitiesToken(): Promise<undefined | IFacilitiesTokenResult> {
        if (
            authContext.isKioskRenderMode() ||
            authContext.isKioskLandingRenderMode() ||
            authContext.isDisplayRenderMode()
        ) {
            let facilitiesToken = undefined;
            if (window.location.hash && window.location.hash.length > 1) {
                const hashParams = new URLSearchParams(window.location.hash.substring(1)); // remove '#' from string
                facilitiesToken = hashParams.get('facilities-token');
            }

            if (facilitiesToken && facilitiesToken.length > 0) {
                const deserializedToken = FacilitiesClient.deserializeFacilitiesToken(
                    facilitiesToken,
                );
                return {
                    token: facilitiesToken,
                    expires: utcSecondsToIsoDateString(deserializedToken?.exp),
                } as IFacilitiesTokenResult;
            }
        }
        const token = await FacilitiesClient.getFacilitiesToken(authContext);
        if (!token) {
            const oid = authContext.getUserProfile()?.oid ?? '';
            logMessage({
                method: 'User-Context : refreshFacilitiesToken',
                message: `Failed to retrieve token from Facilities for ${oid}`,
                severityLevel: SeverityLevel.Information,
            });
        }
        return token;
    }

    return (
        <UserContext.Provider
            value={{
                msalUser,
                employeeRecord,
                isUsGovScreeningUserTypesLoaded,
                isPublicTrustUserTypesLoaded,
                isCloudScreeningUserTypesLoaded,
                isFacilitiesTokenLoaded,
                refreshFacilitiesToken,
                hasAccess,
                hasAccessAny,
                screeningHasAccessAny,
                hasUsGovScreeningUserType,
                hasPublicTrustUserType,
                hasCloudScreeningUserType,
                isNSTUserType,
                hasFacilitiesUserType,
                isScaManager,
                isScaMember,
                isScaStatusChecked,
                /* Exposed for debug only utilize is*ScreeningUserTypesLoaded for checking types */
                cloudScreeningUserTypes,
                usGovScreeningUserTypes: usGovScreeningUserTypes,
                publicTrustScreeningUserTypes: publicTrustScreeningUserTypes,
            }}>
            {props.children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;

export interface IUserContext {
    msalUser: IAuthProfile;
    employeeRecord: IEmployee;
    isUsGovScreeningUserTypesLoaded: boolean;
    isPublicTrustUserTypesLoaded: boolean;
    isCloudScreeningUserTypesLoaded: boolean;
    isFacilitiesTokenLoaded: boolean;
    refreshFacilitiesToken: () => Promise<undefined | IFacilitiesTokenResult>;
    hasAccess: (checkAccessTypes: CheckAccessType) => Promise<boolean>;
    hasAccessAny: (checkAccessTypes: CheckAccessType[]) => boolean;
    screeningHasAccessAny: (checkAccessTypes: CheckAccessType[]) => Promise<boolean>;
    hasUsGovScreeningUserType: (userType: UsGovScreeningUserType) => boolean;
    hasPublicTrustUserType: (userType: UsGovScreeningUserType) => boolean;
    hasCloudScreeningUserType: (userType: CloudScreeningUserType) => boolean;
    isNSTUserType: () => boolean;
    hasFacilitiesUserType: (
        userType: FacilityUserType,
        facilitiesToken?: IFacilitiesTokenResult,
    ) => boolean;
    isScaManager: boolean;
    isScaMember: boolean;
    isScaStatusChecked: boolean;
    cloudScreeningUserTypes: Set<string>;
    usGovScreeningUserTypes: Set<string>;
    publicTrustScreeningUserTypes: Set<string>;
}

export type CheckAccessType = {
    /**
     * Type of value provided to be checked.
     */
    serviceProvider: ServiceProviderType;

    /**
     * Acceptable Access Types.
     */
    value: CloudScreeningUserType | FacilityUserType | Role | UsGovScreeningUserType;
};

export enum ServiceProviderType {
    CloudScreening = 'CloudScreening',
    Facility = 'Facility',
    Role = 'Role',
    UsGovScreening = 'UsGovScreening',
    PublicTrust = 'PublicTrust',
}
