import {
    DefaultButton,
    Dialog,
    DialogFooter,
    mergeStyleSets,
    PrimaryButton,
} from '@fluentui/react';
import React from 'react';

export interface UserEducationMessageProps {
    children: React.ReactNode;
}

export function UserEducationMessage(props: UserEducationMessageProps): JSX.Element {
    const styles = mergeStyleSets({
        messageContainer: {
            selectors: {
                '& a': {
                    all: 'revert',
                    textDecoration: 'none',
                    color: 'rgba(57, 113, 211, 1)',
                },
            },
        },
    });

    return <div className={styles.messageContainer}>{props.children}</div>;
}

export function DefaultUserEducationMessage(): JSX.Element {
    return (
        <UserEducationMessage>
            <span>
                Questions? National Security Operations Center (NSOC) at{' '}
                <a href='mailto:MyClearance@microsoft.com'>MyClearance@microsoft.com</a>
            </span>
        </UserEducationMessage>
    );
}

export function SCAUserEducationMessage(): JSX.Element {
    return (
        <UserEducationMessage>
            <span>
                Questions? Review <a href='https://aka.ms/personnel/userguides/sca'>resources</a>{' '}
                and contact the National Security Operations Center (NSOC) at{' '}
                <a href='mailto:MyClearance@microsoft.com'>MyClearance@microsoft.com</a>
            </span>
        </UserEducationMessage>
    );
}

export interface UserEducationModalProps {
    isDialogOpen: boolean;
    onDismiss: () => void;
}

export function UserEducationModal(props: UserEducationModalProps): JSX.Element {
    return (
        <Dialog
            hidden={!props.isDialogOpen}
            dialogContentProps={{
                title: 'Update your info',
                showCloseButton: true,
                onDismiss: props.onDismiss,
            }}
            modalProps={{
                styles: {
                    main: {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '600px !important',
                        height: '208px !important',
                        maxHeight: '208px !important',
                        maxWidth: '600px !important',
                        borderRadius: '8px',
                        background: 'var(--Light-Background-Background-1, #FFF)',
                        boxShadow:
                            '0px 32px 64px 0px rgba(0, 0, 0, 0.24), 0px 0px 8px 0px rgba(0, 0, 0, 0.20)',
                    },
                },
                className: `${styles.dialogTitleStyle}`,
            }}>
            <div style={{ color: 'rgba(36, 36, 36, 1)' }}>
                Your personal data is used to continuously manage your United States government
                clearance and/or suitability by the Microsoft National Security Team (NST).
            </div>
            <div style={{ color: 'rgba(36, 36, 36, 1)', marginTop: '15px' }}>
                To update your data, complete the{' '}
                <span style={{ fontWeight: 600 }}>Report life event form.</span>
            </div>
            <DialogFooter
                styles={{
                    actions: {
                        marginTop: '8px',
                    },
                }}>
                <PrimaryButton
                    text={'Go to report life event form'}
                    styles={{
                        root: {
                            height: '32px',
                            borderRadius: '4px',
                        },
                    }}
                    onClick={() => (window.location.href = 'https://aka.ms/reportLifeEvent')}
                />

                <DefaultButton
                    text='Close'
                    styles={{
                        root: {
                            height: '32px',
                            width: '96px',
                            minWidth: '0px',
                            borderRadius: '4px',
                            border: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                            background: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                        },
                    }}
                    onClick={props.onDismiss}
                />
            </DialogFooter>
        </Dialog>
    );
}

const styles = mergeStyleSets({
    dialogTitleStyle: {
        '.ms-Dialog-title': {
            padding: '24px 46px 8px 24px',
        },
    },
});
