import config from 'environments/environment';
import { GetAadHttpOptions } from 'clients/http-options';
import { IAuthContext } from 'contexts/auth-context';
import ClientUtils from 'clients/client-utils';
import { IChoiceGroupOption } from '@fluentui/react';
import { toTitleCase } from 'utils/string-utils';
import { partitionArray } from 'utils/array-utils';
import {
    REGEX_EMAIL,
    REGEX_SSN,
    validateNonEmptyString,
    validatePhoneNumber,
} from 'utils/misc-utils';
import { BrowserCacheKeys } from 'utils/browser-cache-utils';

const employeeConfig = config.employeeServiceConfig;
//This can be improved to base this number on the user screen
const typeAheadMaxResults = 4;

// Value is max value that Employee Service Basic query call can support.
const MaxBasicEmployeeQuery = 500;
const PromiseRejected = 'rejected';
const EmployeeIdField = 'id';
const EmployeeAliasField = 'onPremisesSamAccountName';

const EmployeeDataCache: ICacheProperty = {
    getEmployeeCache: {},
    getEmployeesCache: {},
    getBasicEmployeesCache: {},
    getPositionCache: {},
};

export enum SearchEmployeeStatus {
    prehire = 'prehire',
    terminated = 'terminated',
    active = 'active',
    either = 'either', // either is means both terminated and active
}

export function searchEmployeeStatusChoiceGroupOptions(
    selectedSearchEmployeeStatus: SearchEmployeeStatus | undefined,
): IChoiceGroupOption[] {
    return [SearchEmployeeStatus.either, SearchEmployeeStatus.prehire].map((key) => {
        return {
            key: key,
            // converting either to show as 'Employee' in the drop down menu according to design spec
            text: toTitleCase(key === SearchEmployeeStatus.either ? 'employee' : key),
            selected:
                selectedSearchEmployeeStatus &&
                selectedSearchEmployeeStatus ===
                    SearchEmployeeStatus[key as keyof typeof SearchEmployeeStatus],
        } as IChoiceGroupOption;
    });
}

export function transformPrehireToEmployeeObject(
    editableEmployee: IEmployeeEditableResponse,
): IEmployee {
    const result: IEmployee = {
        id: editableEmployee.id ?? '',
        alias: editableEmployee.alias ?? '',
        firstName: editableEmployee.employeeEditableInfo.firstName ?? '',
        middleName: editableEmployee.employeeEditableInfo.middleName ?? '',
        lastName: editableEmployee.employeeEditableInfo.lastName ?? '',
        fullName: '',
        locationAreaCity: editableEmployee.employeeEditableInfo.locationCity ?? '',
        locationAreaCode: '',
        locationAreaDetail: '',
        country: editableEmployee.employeeEditableInfo.locationCountry ?? '',
        companyCode: editableEmployee.employeeEditableInfo.companyCode ?? '',
        costCenterCode: editableEmployee.employeeEditableInfo.costCenterCode ?? '',
        positionNumber: editableEmployee.employeeEditableInfo.positionNumber ?? 0,
        reportsToEmailName: '',
        addressBookTitleDesc: '',
        standardTitle: editableEmployee.employeeEditableInfo.standardTitle ?? '',
        profession: editableEmployee.employeeEditableInfo.profession ?? '',
        discipline: editableEmployee.employeeEditableInfo.discipline ?? '',
        isFTE: false,
        csCompany: '',
        csPersonnelNumber: 0,
        terminationDate: editableEmployee.employeeEditableInfo.terminationDate ?? '',
        employeeLvl: -1,
        hierarchyLvl1: -1,
        hierarchyLvl2: -1,
        hierarchyLvl3: -1,
        hierarchyLvl4: -1,
        hierarchyLvl5: -1,
        hierarchyLvl6: -1,
        hierarchyLvl7: -1,
        hierarchyLvl8: -1,
        hierarchyLvl9: -1,
        hierarchyLvl10: -1,
        hierarchyLvl11: -1,
        hierarchyLvl12: -1,
        hierarchyLvl13: -1,
        hierarchyLvl14: -1,
        hierarchyLvl15: -1,
        hierarchyLvl16: -1,
        hierarchyLvl17: -1,
        oid: '',
        preferredFirstName: '',
        preferredLastName: '',
        displayName: '',
        email:
            editableEmployee.employeeEditableInfo.workEmail ??
            editableEmployee.employeeEditableInfo.personalEmail ??
            '',
        userPrincipalName: '',
        jobTitle: '',
        department: '',
        companyName: '',
        officeLocation: '',
        businessPhones: [],
        accountEnabled: false,
        onPremisesSamAccountName: '',
        onPremisesDomainName: '',
        hasGraphRecord: false,
        isActiveEmployee: false,
        startDate: editableEmployee.employeeEditableInfo.startDate ?? '',
    };

    return result;
}

// Used by editable employee data
// All fields that are possible to read/write
export enum AllEmployeeEditableFields {
    birthDate = 'birthDate',
    birthPlace = 'birthPlace',
    companyCode = 'companyCode',
    costCenterCode = 'costCenterCode',
    discipline = 'discipline',
    employeeType = 'employeeType', // employee type stores company name
    firstName = 'firstName',
    homeAddress = 'homeAddress',
    homeCity = 'homeCity',
    homeCountry = 'homeCountry',
    homePhone = 'homePhone',
    homeState = 'homeState',
    homeZip = 'homeZip',
    isKmp = 'isKmp',
    isUSCitizen = 'isUSCitizen',
    lastName = 'lastName',
    locationAreaDetail = 'locationAreaDetail',
    locationCity = 'locationCity',
    locationCountry = 'locationCountry',
    maidenName = 'maidenName',
    microsoftId = 'microsoftId',
    middleName = 'middleName',
    nationalIdNumber = 'nationalIdNumber',
    passportExpirationDate = 'passportExpirationDate',
    passportId = 'passportId',
    passportIssueDate = 'passportIssueDate',
    personalCellPhone = 'personalCellPhone',
    personalEmail = 'personalEmail',
    positionNumber = 'positionNumber',
    profession = 'profession',
    reportsToEmailName = 'reportsToEmailName',
    standardTitle = 'standardTitle',
    startDate = 'startDate',
    suffix = 'suffix',
    terminationDate = 'terminationDate',
    workEmail = 'workEmail',
    workPhone = 'workPhone',
}
export enum NonMaskedEmployeeEditableFields {
    companyCode = 'companyCode',
    costCenterCode = 'costCenterCode',
    discipline = 'discipline',
    firstName = 'firstName',
    isKmp = 'isKmp',
    isUSCitizen = 'isUSCitizen',
    lastName = 'lastName',
    locationAreaDetail = 'locationAreaDetail',
    locationCity = 'locationCity',
    locationCountry = 'locationCountry',
    microsoftId = 'microsoftId',
    positionNumber = 'positionNumber',
    profession = 'profession',
    reportsToEmailName = 'reportsToEmailName',
    standardTitle = 'standardTitle',
    startDate = 'startDate',
    terminationDate = 'terminationDate',
}
export enum MaskedEmployeeEditableFields {
    birthDate = 'birthDate',
    birthPlace = 'birthPlace',
    employeeType = 'employeeType', // employee type stores company name
    homeAddress = 'homeAddress',
    homeCity = 'homeCity',
    homeCountry = 'homeCountry',
    homePhone = 'homePhone',
    homeState = 'homeState',
    homeZip = 'homeZip',
    maidenName = 'maidenName',
    middleName = 'middleName',
    nationalIdNumber = 'nationalIdNumber',
    passportExpirationDate = 'passportExpirationDate',
    passportId = 'passportId',
    passportIssueDate = 'passportIssueDate',
    personalCellPhone = 'personalCellPhone',
    personalEmail = 'personalEmail',
    suffix = 'suffix',
    workEmail = 'workEmail',
    workPhone = 'workPhone',
}

export const EmployeeEditableFieldsValidationFunctions = {
    DEFAULT: validateNonEmptyString,
    birthDate: (input: Date | undefined): boolean => (input ? true : false),
    birthPlace: validateNonEmptyString,
    employeeType: validateNonEmptyString,
    homeAddress: validateNonEmptyString,
    homeCity: validateNonEmptyString,
    homeCountry: validateNonEmptyString,
    homePhone: validatePhoneNumber,
    homeState: validateNonEmptyString,
    homeZip: validateNonEmptyString,
    nationalIdNumber: (input: string | undefined = ''): boolean =>
        input?.match(REGEX_SSN) ? true : false,
    personalCellPhone: validatePhoneNumber,
    personalEmail: (input: string | undefined = ''): boolean =>
        input.match(REGEX_EMAIL) ? true : false,
    workEmail: (input: string | undefined = ''): boolean =>
        input.match(REGEX_EMAIL) ? true : false,
    workPhone: validatePhoneNumber,
};

export enum PublicEmployeeEditableFields {
    middleName = 'middleName',
    firstName = 'firstName',
    lastName = 'lastName',
    suffix = 'suffix',
    reportsToEmailName = 'reportsToEmailName',
    positionNumber = 'positionNumber',
}

initCache();

function initCache(): void {
    const getEmployeeCacheStr = sessionStorage.getItem(BrowserCacheKeys.getEmployeeCache) || '{}';
    const getEmployeesCacheStr = sessionStorage.getItem(BrowserCacheKeys.getEmployeesCache) || '{}';
    const getPositionCacheStr = sessionStorage.getItem(BrowserCacheKeys.getPositionCache) || '{}';
    const getBasicEmployeesCacheStr =
        sessionStorage.getItem(BrowserCacheKeys.getBasicEmployeesCache) || '{}';

    EmployeeDataCache.getEmployeeCache = JSON.parse(getEmployeeCacheStr);
    EmployeeDataCache.getEmployeesCache = JSON.parse(getEmployeesCacheStr);
    EmployeeDataCache.getPositionCache = JSON.parse(getPositionCacheStr);
    EmployeeDataCache.getBasicEmployeesCache = JSON.parse(getBasicEmployeesCacheStr);
}

function updateSessionCache(cacheName: string): void {
    try {
        sessionStorage.setItem(
            cacheName,
            JSON.stringify(EmployeeDataCache[cacheName as keyof ICacheProperty]),
        );
    } catch (exception) {
        if (exception instanceof DOMException) {
            sessionStorage.removeItem(cacheName);
            EmployeeDataCache[cacheName as keyof ICacheProperty] = {};
        } else {
            console.error(
                `An unexpected exception occurred while updating employee-client Session Cache for cacheName: ${cacheName}`,
            );
            throw exception;
        }
    }
}

function populateBasicEmployeeCacheFromBatchResult(
    batchResult: IBasicEmployee[],
    cacheKeyProp: string,
): void {
    for (let i = 0; i < batchResult.length; i++) {
        const value = (batchResult[i] as any)[cacheKeyProp];
        if (value) {
            const individualCallCacheKey = value.toLowerCase();
            EmployeeDataCache.getBasicEmployeesCache[individualCallCacheKey] = [batchResult[i]];
        }
    }
}

class EmployeeClient {
    static async searchEmployees(
        input: string,
        authContext: IAuthContext,
        searchEmployeeStatus: SearchEmployeeStatus = SearchEmployeeStatus.active,
    ): Promise<IEmployee[]> {
        const inputKey = `${input}_${searchEmployeeStatus}`;
        if (inputKey in EmployeeDataCache.getEmployeesCache) {
            return Promise.resolve(EmployeeDataCache.getEmployeesCache[inputKey]);
        }

        const ret = await this._searchEmployees(input, authContext, searchEmployeeStatus);
        EmployeeDataCache.getEmployeesCache[inputKey] = ret;
        updateSessionCache('getEmployeesCache');
        return ret;
    }

    // broke up prehire search from the searchEmployees function because caching will throw the prehire
    // searches off due to the nature of editable employee records being writeable
    static async searchPrehires(input: string, authContext: IAuthContext): Promise<IEmployee[]> {
        return this._searchEmployees(input, authContext, SearchEmployeeStatus.prehire);
    }

    static async getBasicEmployeesById(
        authContext: IAuthContext,
        personnelIds: string[],
    ): Promise<IBasicEmployee[]> {
        const notFoundIds: string[] = [];
        const promiseArray: Promise<IBasicEmployee[]>[] = [];

        for (let index = 0; index < personnelIds.length; index++) {
            if (personnelIds[index]) {
                const cacheKey = personnelIds[index].toString().toLowerCase();
                if (cacheKey in EmployeeDataCache.getBasicEmployeesCache) {
                    promiseArray.push(
                        Promise.resolve(EmployeeDataCache.getBasicEmployeesCache[cacheKey]),
                    );
                } else {
                    notFoundIds.push(personnelIds[index]);
                }
            }
        }

        const chunk = MaxBasicEmployeeQuery;
        if (notFoundIds.length > 0) {
            const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
            const url = employeeConfig.baseUrl + employeeConfig.recordsEndpoint + '/basic';
            for (let startIndex = 0; startIndex < notFoundIds.length; startIndex += chunk) {
                const aliasChunk = notFoundIds.slice(startIndex, startIndex + chunk);
                promiseArray.push(
                    EmployeeClient._makeRequestToEmployeeUrlForBasicEmployeeData(
                        aliasChunk,
                        httpOptions,
                        url,
                    ),
                );
            }
        }

        const resolved = await Promise.allSettled(promiseArray);
        const returnEmployees: IBasicEmployee[] = [];
        resolved.forEach((x, index) => {
            if (x.status === PromiseRejected) {
                console.log("what Chunk of basic employee query I'm on", index);
                console.log('what error reason', x.reason);
                throw x.reason;
            } else {
                x.value.forEach((employee) => returnEmployees.push(employee));
            }
        });
        return returnEmployees;
    }

    // url is expected to be either with /basic or /alias/basic depending on what ids are
    private static async _makeRequestToEmployeeUrlForBasicEmployeeData(
        ids: string[],
        httpOptions: RequestInit,
        url: string,
    ): Promise<IBasicEmployee[]> {
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(ids);
        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const ret: IBasicEmployee[] = await res.json();
            populateBasicEmployeeCacheFromBatchResult(ret, EmployeeAliasField);
            populateBasicEmployeeCacheFromBatchResult(ret, EmployeeIdField);
            updateSessionCache('getBasicEmployeesCache');
            return ret;
        } else {
            throw res;
        }
    }

    static async getBasicEmployeesByAlias(
        authContext: IAuthContext,
        aliasesParam: (string | null)[], // null will happen when reportsToEmailName is null.
    ): Promise<IBasicEmployee[]> {
        const notFoundAliases: string[] = [];
        const promiseArray: Promise<IBasicEmployee[]>[] = [];

        const aliases: string[] = aliasesParam.filter((alias) => !!alias) as string[];

        for (let index = 0; index < aliases.length; index++) {
            if (aliases[index]) {
                const cacheKey = aliases[index].toString().toLowerCase();
                if (cacheKey in EmployeeDataCache.getBasicEmployeesCache) {
                    promiseArray.push(
                        Promise.resolve(EmployeeDataCache.getBasicEmployeesCache[cacheKey]),
                    );
                } else {
                    notFoundAliases.push(aliases[index]);
                }
            }
        }

        const chunk = MaxBasicEmployeeQuery;
        if (notFoundAliases.length > 0) {
            const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
            const url = employeeConfig.baseUrl + employeeConfig.recordsEndpoint + '/alias/basic';
            for (let startIndex = 0; startIndex < notFoundAliases.length; startIndex += chunk) {
                const aliasChunk = notFoundAliases.slice(startIndex, startIndex + chunk);
                promiseArray.push(
                    EmployeeClient._makeRequestToEmployeeUrlForBasicEmployeeData(
                        aliasChunk,
                        httpOptions,
                        url,
                    ),
                );
            }
        }

        const resolved = await Promise.allSettled(promiseArray);
        const returnEmployees: IBasicEmployee[] = [];
        resolved.forEach((x) => {
            if (x.status === PromiseRejected) {
                throw x.reason;
            } else {
                x.value.forEach((employee) => returnEmployees.push(employee));
            }
        });
        return returnEmployees;
    }

    static async getEmployeeByAliasOrId(
        authContext: IAuthContext,
        aliasOrId: string,
    ): Promise<IEmployee> {
        if (aliasOrId in EmployeeDataCache.getEmployeeCache) {
            return Promise.resolve(EmployeeDataCache.getEmployeeCache[aliasOrId]);
        }

        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const url = employeeConfig.baseUrl + employeeConfig.employeeEndpoint + aliasOrId;

        const res = await ClientUtils.doHttpRequest<IEmployee>(url, httpOptions);
        EmployeeDataCache.getEmployeeCache[aliasOrId] = res;
        return res;
    }

    // The following is similar to getEmployeeByAliasOrId except
    // that it uses fetch instead of ClientUtils.doHttpRequest.
    // The reason for adding this is, the latter manipulates the
    // result in case of an exception.
    static async getEmployee(authContext: IAuthContext, aliasOrId: string): Promise<IEmployee> {
        if (aliasOrId in EmployeeDataCache.getEmployeeCache) {
            return EmployeeDataCache.getEmployeeCache[aliasOrId];
        }

        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const url = employeeConfig.baseUrl + employeeConfig.employeeEndpoint + aliasOrId;

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const employee = await res.json();
            EmployeeDataCache.getEmployeeCache[aliasOrId] = employee;
            return employee;
        } else if (res.status === 404) {
            throw 'The specified employee not found';
        } else {
            throw res;
        }
    }

    static async getEmployeesByIds(authContext: IAuthContext, ids: string[]): Promise<IEmployee[]> {
        return await this._getEmployeesByAliasesOrIds(
            authContext,
            ids,
            employeeConfig.baseUrl + employeeConfig.recordsEndpoint,
        );
    }

    static async getEmployeesByAliases(
        authContext: IAuthContext,
        aliases: string[],
    ): Promise<IEmployee[]> {
        const resultPartitions = await Promise.all(
            partitionArray(aliases, MaxBasicEmployeeQuery).map((aliasPartition) => {
                return this._getEmployeesByAliasesOrIds(
                    authContext,
                    aliasPartition,
                    employeeConfig.baseUrl + employeeConfig.recordsEndpoint + '/alias',
                );
            }),
        );
        let result: IEmployee[] = [];
        resultPartitions.forEach((partition) => (result = result.concat(partition)));
        return result;
    }

    private static async _getEmployeesByAliasesOrIds(
        authContext: IAuthContext,
        aliasesOrIds: string[],
        url: string,
    ): Promise<IEmployee[]> {
        const cacheResult: IEmployee[] = [];
        let fetchResult: IEmployee[] = [];
        const fetchThese: string[] = [];

        aliasesOrIds.forEach((aliasOrId) => {
            const aliasOrIdLower = aliasOrId.toLowerCase();
            if (EmployeeDataCache.getEmployeeCache[aliasOrIdLower])
                cacheResult.push(EmployeeDataCache.getEmployeeCache[aliasOrIdLower]);
            else if (aliasOrIdLower !== '')
                // It happened! Don't ask me why.
                fetchThese.push(aliasOrIdLower);
        });

        if (fetchThese.length > 0) {
            const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
            httpOptions.method = 'POST';
            const results = await Promise.all(
                partitionArray(fetchThese, MaxBasicEmployeeQuery).map(async (aliasPartition) => {
                    httpOptions.body = JSON.stringify(aliasPartition);
                    return await ClientUtils.doHttpRequest<IEmployee[]>(url, httpOptions);
                }),
            );
            let employeeArray: IEmployee[] = [];
            results.forEach((partition) => (employeeArray = employeeArray.concat(partition)));
            employeeArray.forEach((result) => {
                if (result.alias) {
                    EmployeeDataCache.getEmployeeCache[result.alias?.toLowerCase()] = result;
                }
                EmployeeDataCache.getEmployeeCache[result.id] = result;
            });
            fetchResult = employeeArray;
        }

        const returnResult = cacheResult.concat(fetchResult);
        return returnResult;
    }

    static async getPositionById(
        authContext: IAuthContext,
        id: string,
    ): Promise<IEmployeePosition> {
        if (id in EmployeeDataCache.getPositionCache) {
            return Promise.resolve(EmployeeDataCache.getPositionCache[id]);
        }
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const url = employeeConfig.baseUrl + employeeConfig.positionsEndpoint + id;
        const res = await fetch(url, httpOptions);

        if (res.status === 200) {
            const ret = await res.json();
            EmployeeDataCache.getPositionCache[id] = ret;
            updateSessionCache('getPositionCache');
            return ret;
        } else {
            throw res;
        }
    }

    static async getEditableEmployeeDataByIdOrAliasOrGUID(
        authContext: IAuthContext,
        id: string,
        secureFields: string[],
    ): Promise<IEmployeeEditableResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);

        const urlParams = new URLSearchParams();
        secureFields.forEach((x) => urlParams.append('q', x));
        const url =
            employeeConfig.baseUrl +
            employeeConfig.employeeEditableRecordEndpoint.replace('{idOrAliasOrGuid}', id) +
            `?${urlParams.toString()}`;

        return ClientUtils.doHttpRequest<IEmployeeEditableResponse>(url, httpOptions);
    }
    // query multiple editable employee data by Guid, id or alias
    static async getMultipleEditableEmployeeDataByIdOrAliasOrGUID(
        authContext: IAuthContext,
        ids: string[],
        secureFields?: string[],
    ): Promise<IEmployeeEditableResponse[]> {
        if (ids.length === 0) {
            return [];
        }
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const fields = secureFields ?? Object.values(PublicEmployeeEditableFields);
        let results: IEmployeeEditableResponse[] = [];
        const urlParams = new URLSearchParams();

        fields.forEach((x) => urlParams.append('q', x));
        const url =
            employeeConfig.baseUrl +
            employeeConfig.multiEmployeeEditableRecordLookUpsEndpoint +
            `?${urlParams.toString()}`;
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(ids);
        const res = await fetch(url, httpOptions);

        if (res.status === 200) {
            results = await res.json();
            return results;
        } else {
            console.error(`Error grabbing Multiple editable employees by Guid.`);
            throw results;
        }
    }

    static async getEmployeeRecordByBadgeSerialNumber(
        authContext: IAuthContext,
        badgeSerialNumber: string,
        signal?: AbortSignal,
    ): Promise<IEmployee> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        httpOptions.signal = signal;
        const url =
            employeeConfig.baseUrl +
            employeeConfig.employeeBadgeSerialNumberEndpoint +
            badgeSerialNumber;

        const res = await fetch(url, httpOptions);
        if (res.status === 200) {
            const employee = (await res.json()) as IEmployee;
            EmployeeDataCache.getEmployeeCache[employee.alias] = employee;
            return employee;
        } else {
            throw res;
        }
    }

    static async convertEditableEmployeeData(
        authContext: IAuthContext,
        request: IConvertEditableRequest,
    ): Promise<IEmployeeEditableResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        httpOptions.method = 'POST';
        httpOptions.body = JSON.stringify(request);
        const url = employeeConfig.baseUrl + employeeConfig.convertEditableRecordEndpoint;

        return ClientUtils.doHttpRequest<IEmployeeEditableResponse>(url, httpOptions);
    }

    static async createEditableEmployeeData(
        authContext: IAuthContext,
        request: IEmployeeEditableRequest,
    ): Promise<IEmployeeEditableResponse> {
        return this._upsertEditableEmployeeData(authContext, request, 'POST');
    }

    static async updateEditableEmployeeData(
        authContext: IAuthContext,
        request: IEmployeeEditableRequest,
    ): Promise<IEmployeeEditableResponse> {
        return this._upsertEditableEmployeeData(authContext, request, 'PUT');
    }

    static async getEmployeeHierarchy(
        authContext: IAuthContext,
        alias: string,
    ): Promise<IEmployee[]> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const url =
            employeeConfig.baseUrl +
            employeeConfig.employeeEndpoint +
            alias +
            employeeConfig.employeeHierarchyEndpoint;
        const result = await fetch(url, httpOptions);
        if (result.status === 200) {
            return result.json();
        } else {
            throw result;
        }
    }

    private static async _upsertEditableEmployeeData(
        authContext: IAuthContext,
        request: IEmployeeEditableRequest,
        httpMethod: string,
    ): Promise<IEmployeeEditableResponse> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        httpOptions.method = httpMethod;
        httpOptions.body = JSON.stringify(request);
        const url = employeeConfig.baseUrl + employeeConfig.upsertEmployeeEditableRecordEndpoint;

        return ClientUtils.doHttpRequest<IEmployeeEditableResponse>(url, httpOptions);
    }

    private static async _searchEmployees(
        input: string,
        authContext: IAuthContext,
        searchEmployeeStatus: SearchEmployeeStatus,
    ): Promise<IEmployee[]> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const url = employeeConfig.baseUrl + employeeConfig.searchRecordsEndpoint;
        httpOptions.method = 'POST';
        const request: IEmployeeSearchRequest = {
            employeePrefix: input,
            resultCount: typeAheadMaxResults,
            employeeStatus: searchEmployeeStatus,
        };
        httpOptions.body = JSON.stringify(request);
        return ClientUtils.doHttpRequest<IEmployee[]>(url, httpOptions);
    }

    static async searchEmployeeMetadata(
        metaDataType: EmployeeMetadataType,
        authContext: IAuthContext,
        searchTerm: string,
        maxResultCount: number,
    ): Promise<IEmployeeMetadata[]> {
        const { baseUrl, employeeMetadata, aadScopes } = config.employeeServiceConfig;
        const httpOptions = {
            ...(await GetAadHttpOptions(authContext, aadScopes)),
        };
        const url =
            baseUrl +
            employeeMetadata +
            `/${metaDataType}?searchTerm=${searchTerm}` +
            `&maxItems=${maxResultCount}`;
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static async isSSNAvailable(input: string, authContext: IAuthContext): Promise<boolean> {
        const httpOptions = await GetAadHttpOptions(authContext, employeeConfig.aadScopes);
        const url = employeeConfig.baseUrl + employeeConfig.searchRecordsEndpoint + 'ssnAvailable';
        httpOptions.method = 'POST';
        const request: IEmployeeSearchRequest = {
            employeePrefix: input,
            resultCount: typeAheadMaxResults,
            employeeStatus: SearchEmployeeStatus.prehire,
        };
        httpOptions.body = JSON.stringify(request);
        const response = await fetch(url, httpOptions);
        if (response.status === 200) {
            return response.json();
        } else {
            throw response;
        }
    }

    static getHierarchyLevel(employee: IEmployee, level: number): number {
        switch (level) {
            case 1:
                return employee.hierarchyLvl1;
            case 2:
                return employee.hierarchyLvl2;
            case 3:
                return employee.hierarchyLvl3;
            case 4:
                return employee.hierarchyLvl4;
            case 5:
                return employee.hierarchyLvl5;
            case 6:
                return employee.hierarchyLvl6;
            case 7:
                return employee.hierarchyLvl7;
            case 8:
                return employee.hierarchyLvl8;
            case 9:
                return employee.hierarchyLvl9;
            case 10:
                return employee.hierarchyLvl10;
            case 11:
                return employee.hierarchyLvl11;
            case 12:
                return employee.hierarchyLvl12;
            case 13:
                return employee.hierarchyLvl13;
            case 14:
                return employee.hierarchyLvl14;
            case 15:
                return employee.hierarchyLvl15;
            case 16:
                return employee.hierarchyLvl16;
            case 17:
                return employee.hierarchyLvl17;
            default:
                return -1;
        }
    }
}

export default EmployeeClient;

export interface IEmployee {
    id: string;
    alias: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullName: string;
    locationAreaCity: string;
    locationAreaCode: string;
    locationAreaDetail: string;
    country: string;
    companyCode: string;
    costCenterCode: string;
    positionNumber: number;
    reportsToEmailName: string | null; // null comes for boss of all bosses.
    addressBookTitleDesc: string;
    standardTitle: string;
    profession: string;
    discipline: string;
    csCompany: string;
    csPersonnelNumber: number;
    isFTE: boolean;
    terminationDate: string;
    employeeLvl: number;
    hierarchyLvl1: number;
    hierarchyLvl2: number;
    hierarchyLvl3: number;
    hierarchyLvl4: number;
    hierarchyLvl5: number;
    hierarchyLvl6: number;
    hierarchyLvl7: number;
    hierarchyLvl8: number;
    hierarchyLvl9: number;
    hierarchyLvl10: number;
    hierarchyLvl11: number;
    hierarchyLvl12: number;
    hierarchyLvl13: number;
    hierarchyLvl14: number;
    hierarchyLvl15: number;
    hierarchyLvl16: number;
    hierarchyLvl17: number;
    oid: string;
    preferredFirstName: string;
    preferredLastName: string;
    displayName: string;
    email: string;
    userPrincipalName: string;
    jobTitle: string;
    department: string;
    companyName: string;
    officeLocation: string;
    businessPhones: string[];
    accountEnabled: boolean;
    onPremisesSamAccountName: string;
    onPremisesDomainName: string;
    hasGraphRecord: boolean;
    isActiveEmployee: boolean;
    startDate: string;
    managerName?: string;
    cloudScreenExpireDate?: string;
    cloudScreenCompletionDate?: string;
    isUSCitizen?: boolean;
    CJIS?: boolean;
}

export interface IBasicEmployee {
    id: string;
    oid: string;
    givenName: string;
    middleName: string;
    surname: string;
    displayName: string;
    mail: string;
    userPrincipalName: string;
    jobTitle: string;
    department: string;
    onPremisesSamAccountName: string;
    isActiveEmployee: boolean;
    reportsToEmailName: string | null; // null comes for boss of all bosses.
    managerName: string | null; // Ditto
    onPremisesDomainName: string;
    officeLocation?: string;
    managerAlias?: string;
}

interface ICacheProperty {
    getEmployeeCache: { [key: string]: IEmployee };
    getEmployeesCache: { [key: string]: IEmployee[] };
    getBasicEmployeesCache: { [key: string]: IBasicEmployee[] };
    getPositionCache: { [key: string]: IEmployeePosition };
}

interface IEmployeeSearchRequest {
    employeePrefix: string;
    resultCount?: number;
    employeeStatus?: SearchEmployeeStatus;
}

interface IEmployeePosition {
    id: string;
    positionNumber: number;
    isOpen: boolean;
    title: string;
    parentPositionNumber: number;
}

export interface IEmployeeEditableInfo {
    nationalIdNumber?: string;
    birthDate?: Date;
    birthPlace?: string;
    passportId?: string;
    passportIssueDate?: Date;
    passportExpirationDate?: Date;
    middleName?: string;
    suffix?: string;
    maidenName?: string;
    homeAddress?: string;
    homeCity?: string;
    homeState?: string;
    homeZip?: string;
    homeCountry?: string;
    homePhone?: string;
    workPhone?: string;
    personalCellPhone?: string;
    personalEmail?: string;
    workEmail?: string;
    employeeType?: string;
    isKmp?: boolean;
    firstName?: string;
    lastName?: string;
    microsoftId?: string;
    isUSCitizen?: boolean;
    companyCode?: string;
    costCenterCode?: string;
    positionNumber?: number;
    startDate?: string;
    terminationDate?: string;
    profession?: string;
    discipline?: string;
    standardTitle?: string;
    locationCity?: string;
    locationAreaDetail?: string;
    locationCountry?: string;
    reportsToEmailName?: string | null; // null comes for CEO.
}

export interface IEmployeeEditableRequest {
    id?: string;
    alias?: string;
    requestBy: string;
    employeeStatus: SearchEmployeeStatus;
    employeeEditableInfo: IEmployeeEditableInfo;
}

export interface IEmployeeEditableResponse {
    id: string;
    alias?: string;
    employeeId?: string;
    employeeStatus: SearchEmployeeStatus;
    employeeEditableInfo: IEmployeeEditableInfo;
    editablePropertiesProvidedByHR: string[];
    isRestrictedProfile: boolean;
}

export const defaultIEmployeeEditableResponse: IEmployeeEditableResponse = {
    id: '',
    employeeStatus: SearchEmployeeStatus.active,
    employeeEditableInfo: {},
    editablePropertiesProvidedByHR: [],
    isRestrictedProfile: false,
};

export interface IEmployeeWithEditableData {
    data: IEmployee & IEmployeeEditableInfo;
    employeeStatus: SearchEmployeeStatus;
    editablePropertiesProvidedByHR: string[];
}

export interface IConvertEditableRequest {
    fromPersonnelId: string;
    toPersonnelId: string;
    requestBy: string;
}

export const MAX_SEARCH_COUNT = 5;

export interface IEmployeeMetadata {
    documentType: EmployeeMetadataType;
    value: string;
}

/**
 * @deprecated
 * not to be used with PrincipalCore as these are Attributes in principalCore and need there ids
 */
export enum EmployeeMetadataType {
    CostCenterCode = 'CostCenterCode',
    CompanyCode = 'CompanyCode',
    CountryCode = 'CountryCode',
}

export const createEmptyEmployeeRecord = (): IEmployee => ({
    id: '',
    alias: '',
    firstName: '',
    middleName: '',
    lastName: '',
    fullName: '',
    locationAreaCity: '',
    locationAreaCode: '',
    locationAreaDetail: '',
    country: '',
    companyCode: '',
    costCenterCode: '',
    positionNumber: 0,
    reportsToEmailName: null,
    addressBookTitleDesc: '',
    standardTitle: '',
    profession: '',
    discipline: '',
    csCompany: '',
    csPersonnelNumber: 0,
    isFTE: false,
    terminationDate: '',
    employeeLvl: 0,
    hierarchyLvl1: 0,
    hierarchyLvl2: 0,
    hierarchyLvl3: 0,
    hierarchyLvl4: 0,
    hierarchyLvl5: 0,
    hierarchyLvl6: 0,
    hierarchyLvl7: 0,
    hierarchyLvl8: 0,
    hierarchyLvl9: 0,
    hierarchyLvl10: 0,
    hierarchyLvl11: 0,
    hierarchyLvl12: 0,
    hierarchyLvl13: 0,
    hierarchyLvl14: 0,
    hierarchyLvl15: 0,
    hierarchyLvl16: 0,
    hierarchyLvl17: 0,
    oid: '',
    preferredFirstName: '',
    preferredLastName: '',
    displayName: '',
    email: '',
    userPrincipalName: '',
    jobTitle: '',
    department: '',
    companyName: '',
    officeLocation: '',
    businessPhones: [],
    accountEnabled: false,
    onPremisesSamAccountName: '',
    onPremisesDomainName: '',
    hasGraphRecord: false,
    isActiveEmployee: false,
    startDate: '',
});
