import React, { useContext, useState, ReactNode, createContext } from 'react';
import VisitorClient, { ISearchVisitorRequest, IVisitorProfile } from 'clients/visitor-client';
import { AuthContext } from 'contexts/auth-context';
import { IPagedResults } from 'clients/http-options';
import { VisitorsFilterContext } from 'components/visitors/visitors-filter-context';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { SetStateFunc } from 'types/global-types';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { PrincipalGetByIdsRequest, PrincipalGetByIdsResult } from 'personnel-core-clients';

interface IContextValue {
    visitors: IVisitorProfile[];
    isLoading: boolean;
    continuationToken: string | undefined;
    warningLoadingVisitor: string | undefined;
    hasErrorLoadingVisitors: boolean;
    setVisitors: SetStateFunc<IVisitorProfile[]>;
    fetchVisitors: (isMounted: () => boolean, params?: IFetchVisitors) => Promise<void>;
    setTheAddedVisitorsSet: SetStateFunc<Set<string>>;
    setWarningLoadingVisitor: SetStateFunc<string | undefined>;
    setHasErrorLoadingVisitors: SetStateFunc<boolean>;
}

interface IFetchVisitors {
    replaceTable?: boolean; // Defalut: false
    withClearFilters?: boolean; // Default: false
}

export const VisitorsDataContext = createContext<IContextValue>(null!);

export interface IVisitorsProviderProps {
    children: ReactNode;
}

const initVisitorSearchFields = (): ISearchVisitorRequest => ({
    id: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    company: '',
    title: '',
    sponsor: '',
});

export default function VisitorsFetchContextProvider(props: IVisitorsProviderProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const filterContext = useContext(VisitorsFilterContext);
    const isCoreGuestsEnabled = useFeatureFlag(FeatureFlagKeys.guestsCore).enabled;

    const [visitors, setVisitors] = useState<IVisitorProfile[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [continuationToken, setContinuationToken] = useState<string>();
    const [theAddedVisitorsSet, setTheAddedVisitorsSet] = useState<Set<string>>(new Set());
    const [warningLoadingVisitor, setWarningLoadingVisitor] = useState<string | undefined>();
    const [hasErrorLoadingVisitors, setHasErrorLoadingVisitors] = useState<boolean>(false);

    const fetchVisitors = async (
        isMounted: () => boolean,
        params?: IFetchVisitors,
    ): Promise<void> => {
        if (!isMounted() || isLoading) return;

        setWarningLoadingVisitor('');
        try {
            setIsLoading(true);

            let visitorsResults: IPagedResults<IVisitorProfile>;

            const fetchGuests = async (query: string) => {
                const principalsClient = new CorePrincipalsClient(authContext);
                const guests = await principalsClient.search(null, query, null, 500, null, false);

                let guestAssignments: PrincipalGetByIdsResult[] = [];

                if (guests.results && guests.results.length > 0) {
                    guestAssignments = await principalsClient.getByIds(
                        new PrincipalGetByIdsRequest({
                            infoMaxRequestedAttributes: 4,
                            infoMaxRequestedIds: 500,
                            attributeIds: [
                                'GuestProfile_JobTitle',
                                'GuestProfile_Company',
                                'GuestProfile_Sponsor',
                                'GuestProfile_MiddleName',
                            ],
                            principalIds: guests.results.map((guest) => guest.id),
                        }),
                    );
                }

                return guests.results
                    ? guests.results.map((guest) => {
                          const assignments =
                              guestAssignments.find((assignment) => assignment.id === guest.id)
                                  ?.assignments ?? [];
                          return {
                              id: guest.externalId,
                              firstName: guest.firstName,
                              middleName:
                                  assignments.find(
                                      (assignment) =>
                                          assignment.attributeId === 'GuestProfile_MiddleName',
                                  )?.value ?? '',
                              lastName: guest.lastName,
                              email: guest.upn,
                              company:
                                  assignments.find(
                                      (assignment) =>
                                          assignment.attributeId === 'GuestProfile_Company',
                                  )?.value ?? '',
                              title:
                                  assignments.find(
                                      (assignment) =>
                                          assignment.attributeId === 'GuestProfile_JobTitle',
                                  )?.value ?? '',
                              sponsor:
                                  assignments.find(
                                      (assignment) =>
                                          assignment.attributeId === 'GuestProfile_Sponsor',
                                  )?.value ?? '',
                              principalId: guest.id,
                          } as IVisitorProfile;
                      })
                    : [];
            };

            if (!!params?.withClearFilters) {
                if (isCoreGuestsEnabled) {
                    const query = `@Principal[Microsoft.Personnel/Principals/Id:principalType] EnumEquals 'Guest' AND @Principal[Microsoft.Directory/CustomSecurityAttributes/Id:GuestProfile_JobTitle] StringContainsCaseInsensitive ''`;
                    const guests = await fetchGuests(query);
                    visitorsResults = {
                        results: guests,
                        continuationToken: undefined,
                    };
                } else {
                    visitorsResults = await VisitorClient.getVisitorSearchResults(
                        authContext,
                        initVisitorSearchFields(),
                        '',
                    );
                }
            } else {
                if (isCoreGuestsEnabled) {
                    let query = `@Principal[Microsoft.Personnel/Principals/Id:principalType] EnumEquals 'Guest' AND @Principal[Microsoft.Directory/CustomSecurityAttributes/Id:GuestProfile_JobTitle] StringContainsCaseInsensitive ''`;

                    if (filterContext.visitorId) {
                        query += ` AND @Principal[Microsoft.Personnel/Principals/Id:externalId] StringContainsCaseInsensitive '${filterContext.visitorId}'`;
                    }
                    if (filterContext.firstName) {
                        query += ` AND @Principal[Microsoft.Personnel/Principals/Id:firstName] StringContainsCaseInsensitive '${filterContext.firstName}'`;
                    }
                    if (filterContext.middleName) {
                        query += ` AND @Principal[Microsoft.Directory/CustomSecurityAttributes/Id:GuestProfile_MiddleName] StringContainsCaseInsensitive '${filterContext.middleName}'`;
                    }
                    if (filterContext.lastName) {
                        query += ` AND @Principal[Microsoft.Personnel/Principals/Id:lastName] StringContainsCaseInsensitive '${filterContext.lastName}'`;
                    }
                    if (filterContext.email) {
                        query += ` AND @Principal[Microsoft.Personnel/Principals/Id:upn] StringContainsCaseInsensitive '${filterContext.email}'`;
                    }
                    if (filterContext.company) {
                        query += ` AND @Principal[Microsoft.Directory/CustomSecurityAttributes/Id:GuestProfile_Company] StringContainsCaseInsensitive '${filterContext.company}'`;
                    }
                    if (filterContext.title) {
                        query += ` AND @Principal[Microsoft.Directory/CustomSecurityAttributes/Id:GuestProfile_JobTitle] StringContainsCaseInsensitive '${filterContext.title}'`;
                    }
                    if (filterContext.sponsor?.id) {
                        query += ` AND @Principal[Microsoft.Directory/CustomSecurityAttributes/Id:GuestProfile_Sponsor] StringEquals '${filterContext.sponsor.id}'`;
                    }
                    const guests = await fetchGuests(query);
                    visitorsResults = {
                        results: guests,
                        continuationToken: undefined,
                    };
                } else {
                    visitorsResults = await VisitorClient.getVisitorSearchResults(
                        authContext,
                        {
                            id: filterContext.visitorId,
                            firstName: filterContext.firstName,
                            middleName: filterContext.middleName,
                            lastName: filterContext.lastName,
                            email: filterContext.email,
                            company: filterContext.company,
                            title: filterContext.title,
                            sponsor: filterContext.sponsor?.id ?? '',
                        },
                        params?.replaceTable ? '' : continuationToken,
                    );
                }
            }

            if (isMounted()) {
                if (!!params?.replaceTable) {
                    setVisitors(visitorsResults.results);
                    setTheAddedVisitorsSet(new Set());
                    if (visitorsResults.results?.length === 0) {
                        setWarningLoadingVisitor('No matching visitor found');
                    }
                } else {
                    const { results } = visitorsResults;
                    const filtered = results.filter(
                        (visitor) => !theAddedVisitorsSet.has(visitor.id),
                    );
                    setVisitors((currentValue) => [...currentValue].concat(filtered));
                }
                setContinuationToken(visitorsResults.continuationToken);
            }
        } catch (e) {
            setHasErrorLoadingVisitors(true);
        } finally {
            if (isMounted()) {
                setIsLoading(false);
            }
        }
    };

    return (
        <VisitorsDataContext.Provider
            value={{
                visitors,
                isLoading,
                continuationToken,
                warningLoadingVisitor,
                hasErrorLoadingVisitors,
                setVisitors,
                fetchVisitors,
                setTheAddedVisitorsSet,
                setWarningLoadingVisitor,
                setHasErrorLoadingVisitors,
            }}>
            {props.children}
        </VisitorsDataContext.Provider>
    );
}

export function VisitorLoadWarningMsgBar(): JSX.Element {
    const dataContext = useContext(VisitorsDataContext);
    return !!dataContext.warningLoadingVisitor ? (
        <MessageBar
            onDismiss={(): void => dataContext.setWarningLoadingVisitor(undefined)}
            messageBarType={MessageBarType.warning}
            dismissButtonAriaLabel='Close'>
            {dataContext.warningLoadingVisitor}
        </MessageBar>
    ) : (
        <></>
    );
}

export function VisitorLoadErrorMsgBar(): JSX.Element {
    const dataContext = useContext(VisitorsDataContext);
    return dataContext.hasErrorLoadingVisitors ? (
        <MessageBar
            onDismiss={(): void => dataContext.setHasErrorLoadingVisitors(false)}
            messageBarType={MessageBarType.error}
            dismissButtonAriaLabel='Close'>
            {'Encountered error loading visitor data'}
        </MessageBar>
    ) : (
        <></>
    );
}
