import {
    DatePicker,
    ActionButton,
    Checkbox,
    Dropdown,
    IDropdownOption,
    IPersonaProps,
    IStackItemStyles,
    Label,
    MessageBar,
    MessageBarType,
    Stack,
    TextField,
} from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IClearanceRecord } from 'clients/clearance-client';
import {
    BasicDetailsModal,
    ButtonParams,
    EditParams,
    SaveParams,
} from 'components/personnel-profile/common/basic-details-modal';
import {
    agencyDropdownOptions,
    clearanceDropdownOptions,
    clearanceStatusSpan,
    clearanceStatusDropdownOptions,
    getModalPixelWidthValueBasedOnTextLength,
    getAgencyEnumValueFromKey,
    findClearanceLevel,
    clearanceTypeDropdownOptions,
    ClearanceStatuses,
    continuousEvaluationTypeDropdownOptions,
    ContinuousEvaluationTypes,
} from 'components/personnel-profile/clearance/profile-clearance-constants';
import { labelStackItem } from 'components/personnel-profile/common/common-types';
import { dateToLocalDate, getDate } from 'utils/time-utils';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import { toTitleCase } from 'utils/string-utils';
import ContractPicker from 'components/common/contract-picker';
import { transformContractToPersona } from 'utils/internal-persona-utils';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { displayRequiredFieldsErrors } from 'utils/error-display-utils';
import EllipsisText from 'components/common/ellipsis-text';
import { Dictionary } from 'assets/constants/global-constants';
import { ContractStatus, IContract } from 'components/screening/us-gov/IContract';
import { nameof } from 'utils/object-utils';

// 'contractId' and its required indicator removed because of card - https://msazure.visualstudio.com/Microsoft%20Personnel/_boards/board/t/Screening/Stories/?workitem=10073448
const validFields: string[] = ['level', 'status', 'agency', 'personnelId'];
const statusEditFieldName = 'status';

enum Mode {
    Edit,
    View,
}

export interface ClearanceDetailsProps {
    clearance?: IClearanceRecord;
    employee?: IEmployeeWithEditableData;
    buttonText: string;
    buttonIcon: string;
    modalTitle: string;
    modalSubTitle?: string;
    upsertRecordFunction: (record: IClearanceRecord) => Promise<boolean>;
    deleteRecordFunction?: (id: string) => Promise<boolean>;
    contracts: IContract[];
    isClearedWorkforceView?: boolean;
}

export default function ClearanceDetails(props: ClearanceDetailsProps): JSX.Element {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [currentMode, setMode] = useState<Mode>(Mode.View);
    const [basicDetailsModalEditButton, setBasicDetailsModalEditButton] = useState<ButtonParams>(
        props.clearance ? EditParams : SaveParams,
    );
    const [basicDetailsModalCloseButtonTitle, setBasicDetailsModalCloseButtonTitle] = useState<
        'Close' | 'Cancel'
    >('Close');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [errorMsg, setErrorMsg] = useState<JSX.Element>();

    const [saveObject, setSaveObject] = useState<IClearanceRecord | undefined>();

    const [isDeleteDialogAutoClosed, setDeleteDialogAutoClosed] = useState<boolean>(false);

    const [longestContractIdLength, setLongestContractIdLength] = useState<number>(0);

    const [editDisableDictionary, setEditDisableDictionary] = useState<Dictionary<boolean>>({});

    const updateEditDisableDictionary = useCallback((key: string, value: boolean): void => {
        setEditDisableDictionary((currentValue) => ({
            ...currentValue,
            [key]: value,
        }));
    }, []);

    const checkboxLabelStackItem: IStackItemStyles = {
        root: {
            padding: 5,
            width: '260px',
        },
    };

    const valueStackItem: IStackItemStyles = {
        root: {
            padding: 5,
            flex: 1,
        },
    };

    const checkboxStackItem: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    useEffect(() => {
        if (props.clearance) {
            setMode(Mode.View);
        } else {
            setMode(Mode.Edit);
        }
    }, [props.clearance]);

    useEffect(() => {
        if (props.contracts && props.contracts.length > 0) {
            const longestContract = props.contracts.reduce((a, b) =>
                a.id.length > b.id.length ? a : b,
            );
            setLongestContractIdLength(longestContract.id.length);
        }
    }, [props.contracts]);

    useEffect(() => {
        if (
            props.clearance &&
            props.clearance?.debriefDateUTCMilliseconds &&
            props.clearance?.debriefDateUTCMilliseconds > 0
        ) {
            updateEditDisableDictionary(statusEditFieldName, true);
        }
    }, [props.clearance, currentMode]);

    function onEditButtonPress(): void {
        if (currentMode === Mode.View) {
            setMode(Mode.Edit);
        } else {
            handleStatusAdjustOnDebrief(saveObject);
            if (saveObject && validateClearance(saveObject)) {
                setIsDisabled(true);
                props.upsertRecordFunction(saveObject).then((result: boolean) => {
                    setIsDisabled(false);
                    if (result) {
                        setModalOpen(false);
                    } else {
                        setErrorMsg(
                            <span>
                                Personnel is unable to save this record, please reach out to
                                Personnel for support.
                            </span>,
                        );
                    }
                });
            }
        }
    }

    function handleStatusAdjustOnDebrief(clearanceRecord: IClearanceRecord | undefined): void {
        if (
            clearanceRecord &&
            clearanceRecord.debriefDateUTCMilliseconds &&
            clearanceRecord.debriefDateUTCMilliseconds > 0
        ) {
            clearanceRecord.status = ClearanceStatuses.Inactive;
        }
    }

    function validateClearance(clearanceRecord: IClearanceRecord | undefined): boolean {
        if (clearanceRecord) {
            const foundValid: string[] = [];
            for (const [key, value] of Object.entries(clearanceRecord)) {
                const foundField = validFields.find((x) => x === key);
                if (foundField && value) {
                    foundValid.push(foundField);
                }
            }

            let isDebriefValid = true;
            if (
                clearanceRecord.debriefDateUTCMilliseconds &&
                clearanceRecord.debriefDateUTCMilliseconds > 0
            ) {
                isDebriefValid = clearanceRecord.status === ClearanceStatuses.Inactive;
            }

            // Validate Continuous Evaluation Type selection against Enrollment and Unenrollment dates.
            let continuousEvaluationField = '';
            let iscontinuousEvaluationValid = true;
            if (clearanceRecord.continuousEvaluationType) {
                if (
                    (clearanceRecord.continuousEvaluationType ===
                        nameof<typeof ContinuousEvaluationTypes>('Deferred') ||
                        clearanceRecord.continuousEvaluationType ===
                            nameof<typeof ContinuousEvaluationTypes>('Other')) &&
                    !clearanceRecord.continuousEvaluationEnrollmentDateUTCMilliseconds
                ) {
                    continuousEvaluationField = 'CE enrollment date';
                }
                if (
                    clearanceRecord.continuousEvaluationType ===
                        nameof<typeof ContinuousEvaluationTypes>('Unenrolled') &&
                    !clearanceRecord.continuousEvaluationUnenrollmentDateUTCMilliseconds
                ) {
                    continuousEvaluationField = 'CE unenrollment date';
                }
                iscontinuousEvaluationValid = !continuousEvaluationField;
            } else {
                clearanceRecord.continuousEvaluationType = undefined;
                clearanceRecord.continuousEvaluationEnrollmentDateUTCMilliseconds = undefined;
                clearanceRecord.continuousEvaluationUnenrollmentDateUTCMilliseconds = undefined;
            }

            if (
                foundValid.length === validFields.length &&
                isDebriefValid &&
                iscontinuousEvaluationValid
            ) {
                return true;
            }
            const invalidFields = validFields
                .filter((x) => foundValid.findIndex((y) => y === x) === -1)
                .map((x) => {
                    if (x === 'contractId') {
                        return 'Contract';
                    } else if (x === 'level') {
                        return 'Clearance Level';
                    }
                    return toTitleCase(x);
                });

            if (!isDebriefValid) {
                invalidFields.push('Debrief On');
            }

            if (!iscontinuousEvaluationValid) {
                invalidFields.push(continuousEvaluationField);
            }

            setErrorMsg(displayRequiredFieldsErrors(invalidFields));
        }

        return false;
    }

    function onDeleteButtonPress(): void {
        if (props.deleteRecordFunction && saveObject && saveObject.id) {
            setIsDisabled(true);
            props.deleteRecordFunction(saveObject.id).then((result: boolean) => {
                setIsDisabled(false);
                if (result) {
                    setModalOpen(false);
                } else {
                    setDeleteDialogAutoClosed(!isDeleteDialogAutoClosed);
                    setErrorMsg(<span>There was an error deleting.</span>);
                }
            });
        }
    }

    function onCloseButtonPress(): void {
        if (currentMode === Mode.View || props.clearance === undefined) {
            setModalOpen(false);
        } else {
            setMode(Mode.View);
        }
    }

    useEffect(() => {
        if (isModalOpen) {
            setErrorMsg(undefined);
            setSaveObject(
                props.clearance
                    ? { ...props.clearance }
                    : ({ personnelId: props.employee?.data?.id } as IClearanceRecord),
            );
            if (currentMode === Mode.Edit) {
                setBasicDetailsModalEditButton(SaveParams);
                setBasicDetailsModalCloseButtonTitle('Cancel');
            } else {
                setBasicDetailsModalEditButton(EditParams);
                setBasicDetailsModalCloseButtonTitle('Close');
            }
        }
    }, [isModalOpen, currentMode]);

    function canEdit(): boolean {
        return currentMode === Mode.Edit;
    }

    function canDelete(): boolean {
        return (
            !props.isClearedWorkforceView &&
            props.deleteRecordFunction !== undefined &&
            currentMode === Mode.View
        );
    }

    function changeValue(property: string, value: string | number | boolean | undefined): void {
        // Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        newSaveObject[`${property}`] = value;
        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    function changeValues(propertyDict: Dictionary<string | number | boolean | undefined>): void {
        // Need any to perform [] reference on its properties
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newSaveObject: any = { ...saveObject };
        Object.keys(propertyDict).forEach((x) => {
            newSaveObject[`${x}`] = propertyDict[x];
        });
        setSaveObject(newSaveObject);
        setErrorMsg(undefined);
    }

    const activeUsGovContracts = useMemo(
        () =>
            props.contracts.filter(
                (c) =>
                    c.contractType === 'USGovScreening' &&
                    c.contractStatus === ContractStatus.Active,
            ),
        [props.contracts],
    );

    return (
        <Stack>
            <ActionButton
                onClick={(): void => {
                    setModalOpen(true);
                }}
                iconProps={{ iconName: props.buttonIcon }}
                text={props.buttonText}
                styles={{ root: { maxHeight: contentMaxHeight } }}
            />

            <BasicDetailsModal
                title={props.modalTitle}
                subTitle={props.modalSubTitle}
                editButton={basicDetailsModalEditButton}
                closeTitle={basicDetailsModalCloseButtonTitle}
                isOpen={isModalOpen}
                onEditClick={props.isClearedWorkforceView ? undefined : onEditButtonPress}
                onCloseClick={onCloseButtonPress}
                onDeleteClick={canDelete() ? onDeleteButtonPress : undefined}
                deleteDialogProps={{
                    title: 'Delete Confirmation',
                    subText:
                        'Are you sure you want to permanently delete this clearance record along with all associated special access and polygraph records?',
                    confirmButtonText: 'Confirm',
                    cancelButtonText: 'Cancel',
                    autoClosed: isDeleteDialogAutoClosed,
                }}
                actionButtonDisabled={isDisabled}>
                <Stack>
                    <ActionButton
                        // The purpose of this button is to catch the initial propagated onClick event
                        // when the 'add clearance' button is clicked to open up this modal.
                        // Otherwise the first stack item's onChange event will trigger e.g. in this case
                        // the dropdown will select and display the top item from the dropdown selection
                        style={{ maxHeight: 0, maxWidth: 0 }}
                        hidden={true}
                        onClick={(event): void => {
                            event.stopPropagation();
                        }}
                    />
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Clearance Level</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>{findClearanceLevel(props.clearance?.level)?.longHand}</>
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('level', option?.key);
                                        }}
                                        placeholder='Select clearance level'
                                        options={clearanceDropdownOptions(
                                            findClearanceLevel(props.clearance?.level),
                                        )}
                                        ariaLabel='Clearance Level'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Agency</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>{getAgencyEnumValueFromKey(props.clearance?.agency)}</>
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('agency', option?.key);
                                        }}
                                        placeholder='Select agency'
                                        options={agencyDropdownOptions(props.clearance?.agency)}
                                        ariaLabel='Agency'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label required={canEdit()}>Status</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() &&
                                    clearanceStatusSpan(
                                        props.clearance,
                                        props.clearance?.status.length,
                                    )}
                                {canEdit() && (
                                    <Dropdown
                                        disabled={editDisableDictionary[statusEditFieldName]}
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue(statusEditFieldName, option?.key);
                                        }}
                                        placeholder='Select status'
                                        options={clearanceStatusDropdownOptions(
                                            props.clearance?.status,
                                        )}
                                        ariaLabel='Status'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Clearance Type</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.clearance?.clearanceType}
                                    />
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValue('clearanceType', option?.key);
                                        }}
                                        placeholder='Select clearance type'
                                        options={clearanceTypeDropdownOptions(
                                            props.clearance?.clearanceType,
                                        )}
                                        ariaLabel='Clearance Type'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Clearance Basis</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.clearance?.clearanceBasis}
                                    />
                                )}
                                {canEdit() && (
                                    <TextField
                                        ariaLabel='Clearance Basis'
                                        defaultValue={props.clearance?.clearanceBasis}
                                        onChange={(
                                            event: React.FormEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >,
                                            newValue?: string | undefined,
                                        ): void => {
                                            changeValue('clearanceBasis', newValue);
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Investigated On</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.clearance?.investigationDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        placeholder='Select investigated date'
                                        allowTextInput={true}
                                        value={
                                            saveObject &&
                                            saveObject.investigationDateUTCMilliseconds
                                                ? new Date(
                                                      saveObject.investigationDateUTCMilliseconds,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'investigationDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                        ariaLabel='Investigated On'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Granted On</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(props.clearance?.grantDateUTCMilliseconds)}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Granted Date'
                                        placeholder='Select granted date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.grantDateUTCMilliseconds
                                                ? new Date(saveObject.grantDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'grantDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Briefed On</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(props.clearance?.briefDateUTCMilliseconds)}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Briefed Date'
                                        placeholder='Select briefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.briefDateUTCMilliseconds
                                                ? new Date(saveObject.briefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'briefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Next Briefed On</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.clearance?.nextBriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Next Briefed Date'
                                        placeholder='Select next briefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.nextBriefDateUTCMilliseconds
                                                ? new Date(saveObject.nextBriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'nextBriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>SF312 Briefed On</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.clearance?.sF312BriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='SF312 Briefed Date'
                                        placeholder='Select SF312 briefed date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.sF312BriefDateUTCMilliseconds
                                                ? new Date(saveObject.sF312BriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'sF312BriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Debriefed On</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.clearance?.debriefDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='Debrief Date'
                                        placeholder='Select debrief Date'
                                        allowTextInput={true}
                                        value={
                                            saveObject && saveObject.debriefDateUTCMilliseconds
                                                ? new Date(saveObject.debriefDateUTCMilliseconds)
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                updateEditDisableDictionary(
                                                    statusEditFieldName,
                                                    newDate !== null && newDate !== undefined,
                                                );
                                                changeValue(
                                                    'debriefDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Debrief Justification</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <p
                                        style={{
                                            whiteSpace: 'pre-wrap',
                                            maxHeight: 260,
                                            overflowY: 'auto',
                                        }}>
                                        {props.clearance?.debriefJustification}
                                    </p>
                                )}
                                {canEdit() && (
                                    <TextField
                                        multiline
                                        rows={4}
                                        defaultValue={props.clearance?.debriefJustification}
                                        onChange={(
                                            event: React.FormEvent<
                                                HTMLInputElement | HTMLTextAreaElement
                                            >,
                                            newValue?: string | undefined,
                                        ): void => {
                                            changeValue('debriefJustification', newValue);
                                        }}
                                        ariaLabel='Debrief Justification'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Contract</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && <>{props.clearance?.contractId}</>}
                                {canEdit() && (
                                    <>
                                        <ContractPicker
                                            placeHolder='Select valid and active contract'
                                            contracts={activeUsGovContracts}
                                            selectedPersona={transformContractToPersona(
                                                props.contracts.find((x) => {
                                                    return props.clearance?.contractId === x.id;
                                                }),
                                            )}
                                            onContractSelected={(persona?: IPersonaProps): void => {
                                                if (persona && persona.itemProp) {
                                                    changeValue(
                                                        'contractId',
                                                        JSON.parse(persona.itemProp).id,
                                                    );
                                                } else {
                                                    changeValue('contractId', undefined);
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={checkboxLabelStackItem}>
                                <Label>JPAS Update Ineligible</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={checkboxStackItem}>
                                {!canEdit() && (
                                    <Checkbox
                                        disabled={true}
                                        checked={props.clearance?.jpasUpdateIneligible ?? false}
                                    />
                                )}
                                {canEdit() && (
                                    <Checkbox
                                        defaultChecked={props.clearance?.jpasUpdateIneligible}
                                        onChange={(
                                            ev?: React.FormEvent<HTMLElement>,
                                            isChecked?: boolean,
                                        ): void => changeValue('jpasUpdateIneligible', isChecked)}
                                        ariaLabel='JPAS Update Ineligible'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label>Continuous Evaluation type</Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <EllipsisText
                                        textLengthBeforeEllipsis={20}
                                        text={props.clearance?.continuousEvaluationType}
                                    />
                                )}
                                {canEdit() && (
                                    <Dropdown
                                        onChange={(
                                            event: React.FormEvent<HTMLDivElement>,
                                            option?: IDropdownOption,
                                            index?: number,
                                        ): void => {
                                            changeValues({
                                                'continuousEvaluationType': option?.key,
                                                'continuousEvaluationEnrollmentDateUTCMilliseconds':
                                                    option?.key ===
                                                        nameof<typeof ContinuousEvaluationTypes>(
                                                            'Deferred',
                                                        ) ||
                                                    option?.key ===
                                                        nameof<typeof ContinuousEvaluationTypes>(
                                                            'Other',
                                                        )
                                                        ? getDate().Today().getTime()
                                                        : undefined,
                                                'continuousEvaluationUnenrollmentDateUTCMilliseconds':
                                                    option?.key ===
                                                    nameof<typeof ContinuousEvaluationTypes>(
                                                        'Unenrolled',
                                                    )
                                                        ? getDate().Today().getTime()
                                                        : undefined,
                                            });
                                        }}
                                        placeholder='Select CE type'
                                        options={continuousEvaluationTypeDropdownOptions(
                                            props.clearance?.continuousEvaluationType,
                                        )}
                                        ariaLabel='Continuous Evaluation Type'
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>

                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label
                                    required={
                                        canEdit() &&
                                        (saveObject?.continuousEvaluationType ===
                                            nameof<typeof ContinuousEvaluationTypes>('Deferred') ||
                                            saveObject?.continuousEvaluationType ===
                                                nameof<typeof ContinuousEvaluationTypes>('Other'))
                                    }>
                                    CE enrollment date
                                </Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.clearance
                                                ?.continuousEvaluationEnrollmentDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='CE Enrollment Date'
                                        placeholder='Select CE enrollment date'
                                        allowTextInput={true}
                                        disabled={
                                            saveObject?.continuousEvaluationType !==
                                                nameof<typeof ContinuousEvaluationTypes>(
                                                    'Deferred',
                                                ) &&
                                            saveObject?.continuousEvaluationType !==
                                                nameof<typeof ContinuousEvaluationTypes>('Other')
                                        }
                                        value={
                                            saveObject &&
                                            saveObject.continuousEvaluationEnrollmentDateUTCMilliseconds
                                                ? new Date(
                                                      saveObject.continuousEvaluationEnrollmentDateUTCMilliseconds,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'continuousEvaluationEnrollmentDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item align='center' styles={labelStackItem}>
                                <Label
                                    required={
                                        canEdit() &&
                                        saveObject?.continuousEvaluationType ===
                                            nameof<typeof ContinuousEvaluationTypes>('Unenrolled')
                                    }>
                                    CE unenrollment date
                                </Label>
                            </Stack.Item>
                            <Stack.Item align='center' styles={valueStackItem}>
                                {!canEdit() && (
                                    <>
                                        {dateToLocalDate(
                                            props.clearance
                                                ?.continuousEvaluationUnenrollmentDateUTCMilliseconds,
                                        )}
                                    </>
                                )}
                                {canEdit() && (
                                    <DatePicker
                                        ariaLabel='CE Unenrollment Date'
                                        placeholder='Select CE unenrollment date'
                                        allowTextInput={true}
                                        disabled={
                                            saveObject?.continuousEvaluationType !==
                                            nameof<typeof ContinuousEvaluationTypes>('Unenrolled')
                                        }
                                        value={
                                            saveObject &&
                                            saveObject.continuousEvaluationUnenrollmentDateUTCMilliseconds
                                                ? new Date(
                                                      saveObject.continuousEvaluationUnenrollmentDateUTCMilliseconds,
                                                  )
                                                : undefined
                                        }
                                        onSelectDate={(newDate?: Date | null): void => {
                                            {
                                                changeValue(
                                                    'continuousEvaluationUnenrollmentDateUTCMilliseconds',
                                                    newDate?.getTime(),
                                                );
                                            }
                                        }}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>

                {errorMsg && (
                    <Stack.Item>
                        <MessageBar
                            messageBarType={MessageBarType.error}
                            isMultiline={false}
                            dismissButtonAriaLabel='Close'>
                            {errorMsg}
                        </MessageBar>
                    </Stack.Item>
                )}
            </BasicDetailsModal>
        </Stack>
    );
}
