import React, { useState, useContext, useEffect, ReactNode } from 'react';
import {
    mergeStyleSets,
    FontSizes,
    FontWeights,
    ChoiceGroup,
    IChoiceGroupOption,
    PrimaryButton,
    DefaultButton,
    ActionButton,
    MessageBarType,
    ProgressIndicator,
    MessageBar,
    Panel,
    PanelType,
} from '@fluentui/react';
import { IAuthContext, AuthContext } from 'contexts/auth-context';
import UsGovScreeningClient from 'clients/screening/us-gov-screening-client';
import { AcceptanceOptions } from 'components/screening/us-gov/IScreening';
import { IContract } from 'components/screening/us-gov/IContract';
import { CandidateColumnContent } from 'components/screening/common/candidate-column-content';
import CandidateNominationPersonalDetailsForm from 'components/screening/common/employee/candidate-nomination-personal-details-form';
import EmployeeClient, {
    AllEmployeeEditableFields,
    EmployeeEditableFieldsValidationFunctions,
    IEmployeeEditableInfo,
    IEmployeeEditableRequest,
    SearchEmployeeStatus,
} from 'clients/employee-client';
import { UserContext } from 'contexts/user-context';
import PageLoadingSpinner from 'components/common/page-loading-spinner';
import {
    AcceptanceChoice,
    ScreeningPaths,
    ScreeningRequestTypesLabels,
} from 'components/screening/common/common-constants';
import PublicTrustScreeningClient from 'clients/screening/public-trust-screening-client';
import { useIsMounted } from 'utils/misc-hooks';
import { separateWordsByCapitalLetter } from 'utils/string-utils';
import {
    convertIPublicTrustToCommonScreening,
    convertIScreeningToCommonScreening,
    ICommonScreening,
} from 'components/screening/common/ICommonScreening';
import { determineContractType } from 'components/screening/screening-utils';
import { SuitabilityLevels } from 'components/personnel-profile/suitability/profile-suitability-types';
import { getAgencyEnumValueFromKey } from 'components/personnel-profile/suitability/profile-suitability-utils';
import { PANEL_WIDTH, panelStyles } from 'components/screening/common/nominee-accept';

const ACCEPT_KEY = 'ACCEPT';
const DECLINE_KEY = 'DECLINE';
const RESTRICTED_PROFILE_PLACEHOLDER = 'Restricted';

export interface AcceptDeclineNominationProps {
    isInitialEmployeeBatchLoaded: boolean;
    onBack: () => void;
    onClose: () => void;
    onSubmit(screen: ICommonScreening): void;
    screeningPath: ScreeningPaths;
    screening: ICommonScreening;
}

export default function AcceptDeclineNomination(props: AcceptDeclineNominationProps): JSX.Element {
    const strings = {
        acceptLabel: '',
        declineLabel: '',
        nominationHeader: '',
        NDA: '',
    };

    if (props.screeningPath === ScreeningPaths.UsGov) {
        strings.acceptLabel = 'I have read and agree to the terms of security clearance screening.';
        strings.declineLabel = 'I decline to be screened for this security clearance nomination.';
        strings.nominationHeader = 'Nomination for clearance screening';
        strings.NDA =
            'By agreeing to a clearance screening you understand, and agree, that the United States (U.S.) Government will conduct a background investigation based on your position at Microsoft, which requires you to have access to classified information as defined in 5 CFR 732 and Executive Order 12968. [As set forth in Standard Form 86 Questionnaire for National Security Positions,] the information obtained by the government during a background investigation may be used as the basis for future investigations, eligibility determinations for access to classified information, or to hold a sensitive position, suitability or fitness for Federal employment, fitness for contract employment, or eligibility for physical and logical access to federally controlled facilities or information systems. Providing information for a clearance is voluntary. However, if you do not provide each item of requested information, your investigation may not be completed, which could adversely affect your eligibility for access to classified information or logical or physical access. Withholding, misrepresenting, or falsifying information may affect your eligibility for access to classified information, eligibility for a sensitive position, your eligibility for physical and logical access to federally controlled facilities or information systems, your employment prospects or job status.';
    } else {
        // public trust
        strings.acceptLabel =
            'I have read and agree to the terms of the Public Trust Suitability screening.';
        strings.declineLabel =
            'I decline to be screened for this Public Trust Suitability nomination.';
        strings.nominationHeader = 'Nomination for suitability screening';
        strings.NDA =
            'By agreeing to a Public Trust suitability screening you understand, and agree, that the United States (US) Government will conduct a background investigation based on your position at Microsoft. The information obtained by the government during a background investigation may be used as the basis for future investigations, or to hold a sensitive position, suitability or fitness for Federal employment, fitness for contract employment, or eligibility for physical and logical access to federally controlled facilities or information systems. Providing information for a Public Trust suitability is voluntary. However, if you do not provide each item of requested information, your investigation may not be completed, which could adversely affect your eligibility for access to sensitive information or logical or physical access. Withholding, misrepresenting, or falsifying information may affect your eligibility for access to sensitive information, eligibility for a sensitive position, your eligibility for physical and logical access to federally controlled facilities or information systems, your employment prospects or job status.';
    }

    const CHOICES: IChoiceGroupOption[] = [
        {
            key: ACCEPT_KEY,
            text: strings.acceptLabel,
            value: ACCEPT_KEY,
        },
        {
            key: DECLINE_KEY,
            text: strings.declineLabel,
            value: DECLINE_KEY,
        },
    ];

    const isMounted = useIsMounted();

    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);

    const [acceptanceChoice, setAcceptanceChoice] = useState<AcceptanceChoice>(
        AcceptanceChoice.UNSET,
    );
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [contract, setContract] = useState<IContract>();

    const [employee, setEmployee] = useState<IEmployeeEditableInfo>();
    const [isRestrictedProfile, setIsRestrictedProfile] = useState<boolean>(false);
    const [isEmployeeDataFetched, setIsEmployeeDataFetched] = useState<boolean>(false);

    const [showFirstPage, setShowFirstPage] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>();

    const nonActiveContractErrorMessage = (contract?: string): ReactNode => {
        const contractType =
            props.screeningPath === ScreeningPaths.UsGov ? 'US Gov' : 'Public Trust';
        return (
            <>
                <div>Correct the following error(s) and try again:</div>
                <ul>
                    <li>
                        <strong>{contract ?? 'contractName'}</strong> is not a valid and active{' '}
                        <strong>{contractType}</strong> contract. Nominees cannot accept or decline
                        nominations against non-active contracts. Contact the{' '}
                        <a href='mailto:myclearance@microsoft.com'>National Security Team (NST)</a>{' '}
                        with the contract ID, project name, and customer to determine nomination
                        next steps.
                    </li>
                </ul>
            </>
        );
    };

    useEffect(() => {
        const getEmployeeData = async (): Promise<void> => {
            setIsEmployeeDataFetched(false);
            try {
                const employee = await EmployeeClient.getEditableEmployeeDataByIdOrAliasOrGUID(
                    authContext,
                    userContext.employeeRecord.id,
                    Object.values(AllEmployeeEditableFields),
                );

                setEmployee(employee.employeeEditableInfo);
                setIsRestrictedProfile(employee.isRestrictedProfile);
                setIsEmployeeDataFetched(true);
            } catch (e) {
                if (isMounted()) {
                    setErrorMessage('Unable to get Employee data');
                }
            }
        };

        async function loadContracts(): Promise<void> {
            try {
                const contracts = await UsGovScreeningClient.getContracts(authContext, [
                    determineContractType(props.screeningPath),
                ]);
                const contractId = props.screening.contractId;
                setContract(contracts.results.filter((contract) => contract.id === contractId)[0]);
            } catch (e) {
                if (isMounted()) {
                    setErrorMessage('Unable to load Contracts list');
                }
            }
        }

        loadContracts();
        getEmployeeData();
    }, []);

    function renderScreeningDetails(screeningPath: ScreeningPaths): JSX.Element {
        if (screeningPath === ScreeningPaths.UsGov) {
            return (
                <>
                    <div className={styles.row}>
                        <div className={styles.key}>Request type</div>
                        <div className={styles.value}>
                            {ScreeningRequestTypesLabels[
                                props.screening
                                    .requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? separateWordsByCapitalLetter(props.screening.requestType)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Clearance level</div>
                        <div className={styles.value}>{props.screening.clearance}</div>
                    </div>
                </>
            );
        } else if (screeningPath === ScreeningPaths.PublicTrust) {
            return (
                <>
                    <div className={styles.row}>
                        <div className={styles.key}>Request type</div>
                        <div className={styles.value}>
                            {ScreeningRequestTypesLabels[
                                props.screening
                                    .requestType as keyof typeof ScreeningRequestTypesLabels
                            ] ?? separateWordsByCapitalLetter(props.screening.requestType)}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Request agency</div>
                        <div className={styles.value}>
                            {props.screening.publicTrustAgency
                                ? getAgencyEnumValueFromKey(
                                      props.screening?.publicTrustAgency,
                                      true,
                                  )
                                : ''}
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Suitability level</div>
                        <div className={styles.value}>
                            {props.screening.suitabilityLevel
                                ? SuitabilityLevels[
                                      props.screening
                                          .suitabilityLevel as keyof typeof SuitabilityLevels
                                  ]
                                : ''}
                        </div>
                    </div>
                </>
            );
        }

        return <>Path {props.screeningPath} is not supported.</>;
    }

    return (
        <Panel
            headerText={strings.nominationHeader}
            isOpen={true}
            type={PanelType.custom}
            customWidth={PANEL_WIDTH}
            closeButtonAriaLabel='Cancel'
            onDismiss={props.onClose}
            isFooterAtBottom={showFirstPage}
            styles={{ footerInner: { background: 'white' } }}
            onRenderFooterContent={
                !isEmployeeDataFetched
                    ? undefined
                    : (): JSX.Element => {
                          return <Buttons />;
                      }
            }
            isLightDismiss>
            <div>
                <div>
                    <ul>
                        <li>Select agree or decline to the terms and conditions</li>
                        <li>Complete the personal information form</li>
                    </ul>
                </div>
                <div>
                    <div className={styles.row}>
                        <ActionButton
                            // The purpose of this button is to catch the initial propagated click event
                            // that caused to modal to open. Without this, the first interactive item's
                            // onHover event will trigger, which is, in this case, the employee hover card,
                            // making it assume the mouse is hovering on it and causing it to open.
                            aria-hidden={true}
                            style={{ maxHeight: 0, maxWidth: 0 }}
                            hidden={true}
                            onClick={(event): void => {
                                event.stopPropagation();
                            }}
                        />
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Nominated by</div>
                        <div className={styles.value}>
                            <CandidateColumnContent
                                personnelId={props.screening.nominatedBy}
                                isInitialEmployeeBatchLoaded={props.isInitialEmployeeBatchLoaded}
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.key}>Contract</div>
                        <div className={styles.value}>
                            {`${contract?.id} - ${contract?.project} - ${contract?.customer}`}
                        </div>
                    </div>
                    {renderScreeningDetails(props.screeningPath)}
                </div>
                {showFirstPage && (
                    <>
                        <div className={styles.sectionHeader}>Terms and conditions</div>
                        <div className={styles.termsAndConditionsWrapper}>
                            <div className={styles.termsAndConditionsContent}>
                                <p>{strings.NDA}</p>
                            </div>
                        </div>
                        <ChoiceGroup options={CHOICES} onChange={onChange} required={true} />
                    </>
                )}
                {!showFirstPage && (
                    <PageLoadingSpinner
                        isLoading={!employee}
                        label='Fetching Employee Data...'
                        ariaLive='assertive'
                        labelPosition='bottom'>
                        <div className={styles.sectionHeader}>Personal Information</div>
                        <CandidateNominationPersonalDetailsForm
                            employee={employee!}
                            onEmployeeChange={setEmployee}
                        />
                    </PageLoadingSpinner>
                )}
            </div>
            {!!errorMessage && (
                <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>
            )}
            {isSubmitting && (
                <ProgressIndicator
                    styles={{ itemProgress: { padding: 0 } }}
                    ariaValueText='Submitting...'
                />
            )}
        </Panel>
    );

    function Buttons(): JSX.Element {
        return (
            <div className={panelStyles.buttonContainer}>
                <div>
                    {showFirstPage && acceptanceChoice !== AcceptanceChoice.DECLINE && (
                        <PrimaryButton
                            className={panelStyles.oneButton}
                            disabled={acceptanceChoice === AcceptanceChoice.UNSET}
                            onClick={
                                isRestrictedProfile
                                    ? (): Promise<void> => onSubmit(authContext, props.screening.id)
                                    : (): void => setShowFirstPage(false)
                            }
                            text={isRestrictedProfile ? 'Agree and submit' : 'Next'}
                            allowDisabledFocus
                        />
                    )}
                    {showFirstPage && acceptanceChoice === AcceptanceChoice.DECLINE && (
                        <PrimaryButton
                            disabled={isSubmitting === true}
                            className={panelStyles.oneButton}
                            onClick={(): Promise<void> => onSubmit(authContext, props.screening.id)}
                            text={isRestrictedProfile ? 'Decline and submit' : 'Decline'}
                            allowDisabledFocus
                        />
                    )}
                    {!showFirstPage && (
                        <PrimaryButton
                            disabled={
                                acceptanceChoice === AcceptanceChoice.UNSET ||
                                !isRequiredPersonalContactInfoValid() ||
                                isSubmitting === true
                            }
                            className={panelStyles.oneButton}
                            onClick={(): Promise<void> => onSubmit(authContext, props.screening.id)}
                            text='Submit'
                            allowDisabledFocus
                        />
                    )}
                    {!isRestrictedProfile && (
                        <DefaultButton
                            className={panelStyles.oneButton}
                            text='Back'
                            onClick={(): void => {
                                setShowFirstPage(true);
                                setAcceptanceChoice(AcceptanceChoice.UNSET);
                                if (showFirstPage) {
                                    props.onBack();
                                }
                            }}
                            allowDisabledFocus
                        />
                    )}
                </div>
                <div>
                    <DefaultButton
                        disabled={isSubmitting === true}
                        className={panelStyles.oneButton}
                        text='Cancel'
                        onClick={props.onClose}
                        allowDisabledFocus
                    />
                </div>
            </div>
        );
    }

    async function onSubmit(authContext: IAuthContext, id: string): Promise<void> {
        if (props.screeningPath === ScreeningPaths.UsGov) {
            if (
                acceptanceChoice !== AcceptanceChoice.UNSET &&
                (isRestrictedProfile || isRequiredPersonalContactInfoValid()) &&
                employee
            ) {
                setIsSubmitting(true);
                try {
                    if (!isRestrictedProfile) {
                        // Move editable employee upsert to beginning, otherwise approve will fail
                        // since it will be missing
                        // https://dev.azure.com/msazure/Microsoft%20Personnel/_boards/board/t/Personnel%20Apps/Stories/?workitem=27176448
                        const request: IEmployeeEditableRequest = {
                            employeeEditableInfo: employee,
                            employeeStatus: SearchEmployeeStatus.active,
                            requestBy: userContext.employeeRecord.id,
                            id: userContext.employeeRecord.id,
                            alias: userContext.employeeRecord.alias,
                        };

                        const updateEditable = await EmployeeClient.updateEditableEmployeeData(
                            authContext,
                            request,
                        );
                        if (!updateEditable) {
                            throw new Error(
                                'Personnel is unable to save personal information because user was not found in HR data. If this user account was recently created, please try again later.',
                            );
                        }
                    }

                    const approvedScreening = await UsGovScreeningClient.approveDeny(
                        authContext,
                        id,
                        acceptanceChoice === AcceptanceChoice.ACCEPT
                            ? AcceptanceOptions.Accept
                            : AcceptanceOptions.Decline,
                    );
                    if (!approvedScreening) {
                        throw new Error();
                    }

                    const contact = isRestrictedProfile
                        ? {
                              homeAddress: RESTRICTED_PROFILE_PLACEHOLDER,
                              homeEmail: RESTRICTED_PROFILE_PLACEHOLDER,
                              homePhone: RESTRICTED_PROFILE_PLACEHOLDER,
                          }
                        : {
                              homeAddress: `${employee.homeAddress!} ${employee.homeCity!}, ${employee.homeState!} ${employee.homeZip!}`,
                              homeEmail: employee.personalEmail!,
                              homePhone: employee.homePhone || employee.personalCellPhone!,
                          };

                    const updatedScreening = await UsGovScreeningClient.updateScreeningContact(
                        authContext,
                        id,
                        contact,
                    );

                    if (updatedScreening) {
                        updatedScreening.screeningView = 'ViewScreening';
                        props.onSubmit(convertIScreeningToCommonScreening(updatedScreening));
                    }
                } catch (error) {
                    if (isMounted()) {
                        if (typeof error === 'string') {
                            if (error.includes('contract is not active')) {
                                setErrorMessage(
                                    nonActiveContractErrorMessage(props.screening.contractId),
                                );
                            } else {
                                // remove double quotes from error message
                                error = error.replace(/"/g, '');
                                setErrorMessage(error);
                            }
                        } else {
                            setErrorMessage('An error occurred. Please contact the administrator.');
                        }
                    }
                }
                setIsSubmitting(false);
            } else if (acceptanceChoice === AcceptanceChoice.DECLINE) {
                setIsSubmitting(true);
                try {
                    const approvedScreening = await UsGovScreeningClient.approveDeny(
                        authContext,
                        id,
                        AcceptanceOptions.Decline,
                    );

                    if (!approvedScreening) {
                        throw new Error();
                    }

                    const updatedScreening = await UsGovScreeningClient.updateScreening(
                        authContext,
                        approvedScreening,
                    );

                    if (updatedScreening) {
                        updatedScreening.screeningView = 'ViewScreening';
                        props.onSubmit(convertIScreeningToCommonScreening(updatedScreening));
                    }
                } catch (error) {
                    if (isMounted()) {
                        if (typeof error === 'string') {
                            if (error.includes('contract is not active')) {
                                setErrorMessage(
                                    nonActiveContractErrorMessage(props.screening.contractId),
                                );
                            } else {
                                setErrorMessage(error);
                            }
                        } else {
                            setErrorMessage('An error occurred. Please contact the administrator.');
                        }
                    }
                }
                setIsSubmitting(false);
            } else {
                if (isMounted()) {
                    setErrorMessage(
                        'Please approve or deny the nomination and add personal contact information.',
                    );
                }
            }
        } else {
            if (
                acceptanceChoice !== AcceptanceChoice.UNSET &&
                (isRestrictedProfile || isRequiredPersonalContactInfoValid()) &&
                employee
            ) {
                setIsSubmitting(true);
                try {
                    if (!isRestrictedProfile) {
                        // Move editable employee upsert to beginning, otherwise approve will fail
                        // since it will be missing
                        // https://dev.azure.com/msazure/Microsoft%20Personnel/_boards/board/t/Personnel%20Apps/Stories/?workitem=27176448

                        const request: IEmployeeEditableRequest = {
                            employeeEditableInfo: employee,
                            employeeStatus: SearchEmployeeStatus.active,
                            requestBy: userContext.employeeRecord.id,
                            id: userContext.employeeRecord.id,
                            alias: userContext.employeeRecord.alias,
                        };

                        const updateEditable = await EmployeeClient.updateEditableEmployeeData(
                            authContext,
                            request,
                        );
                        if (!updateEditable) {
                            throw new Error(
                                'Personnel is unable to save personal information because user was not found in HR data. If this user account was recently created, please try again later.',
                            );
                        }
                    }

                    const approvedScreening = await PublicTrustScreeningClient.nomineeApproveRejectNomination(
                        authContext,
                        id,
                        acceptanceChoice === AcceptanceChoice.ACCEPT,
                    );
                    if (!approvedScreening) {
                        throw new Error();
                    }

                    const convertedScreening = convertIPublicTrustToCommonScreening(
                        approvedScreening,
                    );
                    if (approvedScreening) {
                        props.onSubmit(convertedScreening);
                    }
                } catch (error) {
                    if (isMounted()) {
                        if (typeof error === 'string') {
                            if (error.includes('contract is not active')) {
                                setErrorMessage(
                                    nonActiveContractErrorMessage(props.screening.contractId),
                                );
                            } else {
                                // remove double quotes from error message
                                error = error.replace(/"/g, '');
                                setErrorMessage(error);
                            }
                        } else {
                            setErrorMessage('An error occurred. Please contact the administrator.');
                        }
                    }
                }
                setIsSubmitting(false);
            } else if (acceptanceChoice === AcceptanceChoice.DECLINE) {
                setIsSubmitting(true);
                try {
                    const approvedScreening = await PublicTrustScreeningClient.nomineeApproveRejectNomination(
                        authContext,
                        id,
                        false,
                    );
                    if (!approvedScreening) {
                        throw new Error();
                    }
                    const convertedScreening = convertIPublicTrustToCommonScreening(
                        approvedScreening,
                    );
                    if (approvedScreening) {
                        props.onSubmit(convertedScreening);
                    }
                } catch (error) {
                    if (isMounted()) {
                        if (typeof error === 'string') {
                            if (error.includes('contract is not active')) {
                                setErrorMessage(
                                    nonActiveContractErrorMessage(props.screening.contractId),
                                );
                            } else {
                                setErrorMessage(error);
                            }
                        } else {
                            setErrorMessage('An error occurred. Please contact the administrator.');
                        }
                    }
                }
                setIsSubmitting(false);
            } else {
                if (isMounted()) {
                    setErrorMessage(
                        'Please approve or deny the nomination and add personal contact information.',
                    );
                }
            }
        }
    }

    function onChange(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption,
    ): void {
        setAcceptanceChoice(
            option?.value === ACCEPT_KEY ? AcceptanceChoice.ACCEPT : AcceptanceChoice.DECLINE,
        );
    }

    function isRequiredPersonalContactInfoValid(): boolean {
        if (!employee) return false;
        return (
            EmployeeEditableFieldsValidationFunctions.birthDate(employee.birthDate) &&
            EmployeeEditableFieldsValidationFunctions.birthPlace(employee.birthPlace) &&
            EmployeeEditableFieldsValidationFunctions.employeeType(employee.employeeType) &&
            EmployeeEditableFieldsValidationFunctions.homeAddress(employee.homeAddress) &&
            EmployeeEditableFieldsValidationFunctions.homeCity(employee.homeCity) &&
            EmployeeEditableFieldsValidationFunctions.homeCountry(employee.homeCountry) &&
            EmployeeEditableFieldsValidationFunctions.homeState(employee.homeState) &&
            (EmployeeEditableFieldsValidationFunctions.homePhone(employee.homePhone) ||
                !employee.homePhone) &&
            EmployeeEditableFieldsValidationFunctions.homeZip(employee.homeZip) &&
            EmployeeEditableFieldsValidationFunctions.nationalIdNumber(employee.nationalIdNumber) &&
            EmployeeEditableFieldsValidationFunctions.personalCellPhone(
                employee.personalCellPhone,
            ) &&
            EmployeeEditableFieldsValidationFunctions.personalEmail(employee.personalEmail) &&
            EmployeeEditableFieldsValidationFunctions.workEmail(employee.workEmail)
        );
    }
}

const styles = mergeStyleSets({
    sectionHeader: {
        display: 'flex',
        fontSize: FontSizes.large,
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        marginTop: 25,
    },
    row: {
        display: 'flex',
        padding: '5px 0',
    },
    termsAndConditionsWrapper: {
        position: 'relative',
        maxHeight: 'inherit',
        marginBottom: 20,
    },
    termsAndConditionsContent: {
        padding: '0 5px',
    },
    key: {
        width: 140,
        fontWeight: '600',
    },
    value: {
        width: 'calc(100% - 140px)',
    },
});
