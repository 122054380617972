import React, { useContext, useEffect, useState } from 'react';
import { ActionButton, mergeStyleSets, TextField } from '@fluentui/react';
import { contentMaxHeight } from 'assets/styles/list-styles';
import { IEmployeeEditableResponse, IEmployeeWithEditableData } from 'clients/employee-client';
import { AuthContext } from 'contexts/auth-context';
import ActivitiesClient, {
    IActivity,
    IActivityPost,
    IActivityReadRequest,
    IActivityValue,
} from 'clients/activities-client';
import Event, { ActivityEventBin, binSortEvents } from 'components/common/event';
import { dateToLocalLongDateFormat } from 'utils/time-utils';
import { UserContext } from 'contexts/user-context';
import { isGUID } from 'utils/string-utils';
import { ScreeningPaths } from 'components/screening/common/common-constants';

export interface PersonnelProfileNotesProps {
    employee: IEmployeeWithEditableData | undefined;
    editableEmployee?: IEmployeeEditableResponse;
    deletionNote: string;
    uploadNote: string;
    documentIdWorkaround: string;
}

export default function PersonnelProfileNotes(props: PersonnelProfileNotesProps): JSX.Element {
    const authContext = useContext(AuthContext);
    const userContext = useContext(UserContext);
    const [profileNotes, setProfileNotes] = useState<IActivity[]>();
    const [notesInput, setNotesInput] = useState<string>('');
    const [profileEventBins, setProfileEventBins] = useState<ActivityEventBin[]>();
    const [isRefreshActivitiesLocked, setRefreshActivitiesLocked] = useState<boolean>(false);
    const [refreshActivityCallDelayMs, setRefreshActivityCallDelayMs] = useState<number>(0);
    const REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS = 1000;
    const notedEventName = 'noted';

    useEffect(() => {
        async function getProfileEventData(): Promise<void> {
            if (props.employee?.data?.id) {
                const activitiesToken = await ActivitiesClient.getProfileActivityToken(authContext);

                // Ensure that we get both the personnel id and the editable record id if if they are different
                const referenceIds = [props.employee.data.id];
                if (
                    props.editableEmployee?.id &&
                    props.editableEmployee?.id !== props.employee.data.id
                ) {
                    referenceIds.push(props.editableEmployee.id);
                }

                try {
                    const activityReadRequest: IActivityReadRequest = {
                        referenceIds: referenceIds,
                        events: [notedEventName],
                    };

                    const activities = await ActivitiesClient.getProfileActivities(
                        authContext,
                        activityReadRequest,
                        activitiesToken,
                    );
                    setProfileNotes(activities);
                    const sortedBins = binSortEvents(activities);
                    setProfileEventBins(sortedBins);
                } catch (e) {
                    console.error('Error with getting profile activities', e);
                } finally {
                    setRefreshActivitiesLocked(false);
                }
            }
        }

        if (!isRefreshActivitiesLocked && props.employee && props.documentIdWorkaround === '') {
            setRefreshActivitiesLocked(true);
            setTimeout(() => {
                getProfileEventData();
            }, refreshActivityCallDelayMs);
            setRefreshActivityCallDelayMs(REFRESH_ACTIVITY_DELAY_TIME_AFTER_INIT_MS);
        }
    }, [props.employee, props.documentIdWorkaround]);

    useEffect(() => {
        function updateNotes(): void {
            if (props.deletionNote !== '') {
                submitNotes(props.deletionNote);
            }
        }
        updateNotes();
    }, [props.deletionNote]);

    useEffect(() => {
        function updateNotes(): void {
            if (props.uploadNote !== '') {
                submitNotes(props.uploadNote);
            }
        }
        updateNotes();
    }, [props.uploadNote]);

    function submitNotes(note: string): void {
        if (note) {
            const subjectData: IActivityValue = {
                type: 'personId',
                value: userContext.employeeRecord.id,
            };

            const directObjectData: IActivityValue = {
                type: 'personId',
                value: props.employee!.data?.id,
            };

            const activityPostData: IActivityPost = {
                event: notedEventName,
                eventTimestampUTC: new Date().getTime(),
                referenceId: props.employee!.data?.id,
                securityIds: [ActivitiesClient.AdminSecurityId],
                subject: subjectData,
                directObject: directObjectData,
                message: note,
            };

            if (props.employee?.data?.id) {
                ActivitiesClient.getProfileActivityToken(authContext, ['write']).then(
                    async (token) => {
                        const res = await ActivitiesClient.postProfileActivity(
                            authContext,
                            activityPostData,
                            token,
                        );

                        if (res) {
                            // clear textarea input after activity got posted
                            setNotesInput('');

                            // append to existing personnel profile notes and re-render with new notes
                            const newState = [...(profileNotes ?? []), res];
                            setProfileNotes(newState);
                            const sortedBins = binSortEvents(newState);
                            setProfileEventBins(sortedBins);
                        }
                    },
                );
            }
        }
    }

    function onNotesInputChange(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ): void {
        event.preventDefault();
        setNotesInput(newValue!);
    }

    return (
        <>
            {props.employee && (
                <div>
                    <span
                        className={styles.noteInputBox}
                        style={
                            profileNotes === undefined || profileNotes.length === 0
                                ? { padding: '0px' }
                                : {}
                        }>
                        <TextField
                            className={styles.startAtSecondColumn}
                            value={notesInput}
                            placeholder='Add a note'
                            onChange={onNotesInputChange}
                            multiline={true}
                            resizable={false}
                        />
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <ActionButton
                            styles={{ root: { maxHeight: contentMaxHeight } }}
                            title='Submit Notes'
                            iconProps={{ iconName: 'Send' }}
                            onClick={() => submitNotes(notesInput)}
                        />
                    </div>
                </div>
            )}
            {profileEventBins &&
                profileEventBins
                    .map(
                        (obj: ActivityEventBin): JSX.Element => {
                            return (
                                <div key={`activities_${obj.key}`}>
                                    <div
                                        key={`eventDay_${obj.key}`}
                                        className={styles.eventDateRow}>
                                        <div className={styles.eventLine}></div>
                                        <div className={styles.eventRowGap}></div>
                                        <div>{dateToLocalLongDateFormat(obj.key)}</div>
                                    </div>
                                    {obj.activities
                                        .map(
                                            (activity: IActivity): JSX.Element => (
                                                <Event
                                                    screeningPath={ScreeningPaths.UsGov}
                                                    key={`event_${activity.id}`}
                                                    activity={activity}
                                                    provideDocumentsNameForDocumentAppActivities={
                                                        false
                                                    }
                                                    screeningId={props.employee?.data?.id || ''}
                                                />
                                            ),
                                        )
                                        .reverse()}
                                </div>
                            );
                        },
                    )
                    .reverse()}
        </>
    );
}

const styles = mergeStyleSets({
    eventLine: {
        height: '1px',
        marginTop: '10px',
        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
        flexGrow: 1,
    },
    eventDateRow: {
        marginTop: '10px',
        fontWeight: '500',
        display: 'flex',
        flexDirection: 'row',
    },
    eventRowGap: {
        width: '10px',
    },
    event: {
        marginTop: '1rem',
    },
    noteInputBox: {
        paddingTop: '10px',
        display: 'grid',
        gridTemplateColumns: '2rem auto',
    },
    startAtSecondColumn: {
        gridColumnStart: '2',
    },
});
