import { IPersonaProps, IStyle } from '@fluentui/react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IPrincipalRecord } from 'clients/core/IPrincipalRecord';
import {
    CorePrincipalsClient,
    obtainPrincipalRecords,
} from 'clients/core/personnel-core-client-wrappers';
import GraphClient, { IGraphPrincipal } from 'clients/graph-client';
import EmployeeListPicker, {
    EmployeeListPickerUnlimited,
} from 'components/common/employee-list-picker';
import { fetchOrUseCachePictures } from 'components/common/employee/employee-utils';
import { useDebounceGetGraphPersonas } from 'components/common/graph-utils';
import {
    PersonaItemIdType,
    useDebounceGetPrincipalPersonas,
} from 'components/common/principal/principal-utils';
import { AuthContext } from 'contexts/auth-context';
import { CacheContext } from 'contexts/cache-context';
import { GetPrincipalByFieldResult } from 'personnel-core-clients';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
    createUnknownPersona,
    getGraphPrincipalFromPersona,
    getPrincipalRecordFromPersona,
    transformGraphPrincipalToPersona,
    transformPrincipalRecordToPersona,
} from 'utils/persona-utils';
import { getAppInsights } from 'utils/telemetry-utils';

export interface IBaseEmployeePickerTypeaheadSearchPropsNoItems {
    label?: string;
    ariaLabel?: string;
    disabled?: boolean;
    placeHolder?: string;
    required?: boolean;
    styles?: {
        textBoxStyle?: IStyle;
        inputStyle?: IStyle;
        labelStyle?: IStyle;
    };
}

interface IPrincipalPickerTypeaheadSearchNoItemsProps
    extends IBaseEmployeePickerTypeaheadSearchPropsNoItems {
    restrictionsQuery?: string;
}

interface IMultiPrincipalPickerTypeaheadSearchProps<ItemType, ItemAltType = void>
    extends IPrincipalPickerTypeaheadSearchNoItemsProps {
    selectedItems?: ItemType[];
    itemLimit?: number; // Default: unlimited
    onChange?: (items?: ItemType[], itemsAlt?: ItemAltType[]) => void;
    personaItemIdType?: PersonaItemIdType; // If undefined, the oid field will be used by default
}

interface ISinglePrincipalPickerTypeaheadSearchProps<ItemType, ItemAltType = void>
    extends IPrincipalPickerTypeaheadSearchNoItemsProps {
    selectedItem?: ItemType;
    onChange?: (item?: ItemType, itemAlt?: ItemAltType) => void;
    personaItemIdType?: PersonaItemIdType; // If undefined, the oid field will be used by default
}

export function CoreMultiPrincipalPersonaPickerTypeaheadSearch(
    props: IMultiPrincipalPickerTypeaheadSearchProps<IPersonaProps>,
): JSX.Element {
    const debouncedGetPrincipalPersonas = useDebounceGetPrincipalPersonas(
        props.restrictionsQuery,
        props.personaItemIdType,
    );

    const { itemLimit = EmployeeListPickerUnlimited } = props;

    return (
        <EmployeeListPicker
            label={props.label}
            ariaLabel={props.ariaLabel}
            disabled={props.disabled}
            required={props.required}
            itemLimit={itemLimit}
            placeHolder={props.placeHolder}
            selectedItems={props?.selectedItems}
            onMultipleCandidatesSelected={props.onChange}
            loadCandidatesOnFilterChange={debouncedGetPrincipalPersonas}
            styles={{
                textBoxStyle: props.styles?.textBoxStyle,
                inputStyle: props.styles?.inputStyle,
            }}
        />
    );
}

export function CoreSinglePrincipalPersonaPickerTypeaheadSearch(
    props: ISinglePrincipalPickerTypeaheadSearchProps<IPersonaProps>,
): JSX.Element {
    const debouncedGetPrincipalPersonas = useDebounceGetPrincipalPersonas(
        props.restrictionsQuery,
        props.personaItemIdType,
    );

    return (
        <EmployeeListPicker
            label={props.label}
            ariaLabel={props.ariaLabel}
            disabled={props.disabled}
            required={props.required}
            itemLimit={1}
            placeHolder={props.placeHolder}
            selectedPersona={props?.selectedItem}
            selectedItems={props.selectedItem ? [props.selectedItem] : []} // TODO, this is kind of confusing (setting selectedPersona after the initial render does not work)
            onCandidateSelected={props.onChange}
            loadCandidatesOnFilterChange={debouncedGetPrincipalPersonas}
            styles={{
                textBoxStyle: props.styles?.textBoxStyle,
                inputStyle: props.styles?.inputStyle,
                labelStyle: props.styles?.labelStyle,
            }}
        />
    );
}

export function CoreSinglePrincipalRecordPickerTypeaheadSearch(
    props: ISinglePrincipalPickerTypeaheadSearchProps<IPrincipalRecord>,
): JSX.Element {
    const cacheContext = useContext(CacheContext);

    const selectedItem = useMemo(
        () =>
            props.selectedItem === undefined
                ? undefined
                : transformPrincipalRecordToPersona(props.selectedItem, undefined, cacheContext),
        [cacheContext, props.selectedItem],
    );
    const onChange = useCallback(
        (item?: IPersonaProps): void => {
            if (props.onChange) {
                props.onChange(getPrincipalRecordFromPersona(item));
            }
        },
        [props],
    );

    return (
        <CoreSinglePrincipalPersonaPickerTypeaheadSearch
            {...props}
            selectedItem={selectedItem}
            onChange={onChange}
        />
    );
}

interface ISingleIdPickerTypeaheadSearchProps<ItemType>
    extends ISinglePrincipalPickerTypeaheadSearchProps<ItemType, IPrincipalRecord> {
    onResolveId: (item: ItemType) => Promise<IPrincipalRecord>;
}

export function CoreSingleIdPickerTypeaheadSearch(
    props: ISingleIdPickerTypeaheadSearchProps<string>,
): JSX.Element {
    const [selectedItem, setSelectedItem] = useState<IPersonaProps>();
    const cacheContext = useContext(CacheContext);
    const authContext = useContext(AuthContext);

    const { onResolveId } = props;

    useEffect(() => {
        (async (): Promise<void> => {
            if (props.selectedItem !== selectedItem?.itemID) {
                if (props.selectedItem === undefined) {
                    setSelectedItem(undefined);
                } else {
                    try {
                        const principalRecord = await onResolveId(props.selectedItem);
                        await fetchOrUseCachePictures(authContext, [principalRecord], cacheContext);
                        const persona = transformPrincipalRecordToPersona(
                            principalRecord,
                            props.personaItemIdType,
                            cacheContext,
                        );
                        setSelectedItem(persona);
                    } catch (e) {
                        getAppInsights()?.trackException({
                            exception: e,
                            severityLevel: SeverityLevel.Error,
                        });
                        setSelectedItem(createUnknownPersona(props.selectedItem));
                    }
                }
            }
        })();
    }, [
        authContext,
        cacheContext,
        onResolveId,
        props.personaItemIdType,
        props.selectedItem,
        selectedItem?.itemID,
    ]);

    const onChange = useCallback(
        (item?: IPersonaProps): void => {
            if (props.onChange) {
                const principalRecord = getPrincipalRecordFromPersona(item);
                props.onChange(item?.itemID, principalRecord);
            }
        },
        [props],
    );

    return (
        <CoreSinglePrincipalPersonaPickerTypeaheadSearch
            {...props}
            selectedItem={selectedItem}
            onChange={onChange}
        />
    );
}

export function CoreSinglePrincipalIdPickerTypeaheadSearch(
    props: ISinglePrincipalPickerTypeaheadSearchProps<string, IPrincipalRecord>,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const resolver = useCallback(
        async (principalId: string) => {
            const principalClient = new CorePrincipalsClient(authContext);
            return (await principalClient.getById(principalId)) as IPrincipalRecord;
        },
        [authContext],
    );

    return (
        <CoreSingleIdPickerTypeaheadSearch
            {...props}
            onResolveId={resolver}
            personaItemIdType='id'
        />
    );
}

export function CoreSingleUpnPickerTypeaheadSearch(
    props: ISinglePrincipalPickerTypeaheadSearchProps<string, IPrincipalRecord>,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const resolver = useCallback(
        async (upn: string) => {
            const principalClient = new CorePrincipalsClient(authContext);
            return (await principalClient.getByField(null, null, upn)) as IPrincipalRecord;
        },
        [authContext],
    );

    return (
        <CoreSingleIdPickerTypeaheadSearch
            {...props}
            onResolveId={resolver}
            personaItemIdType='upn'
        />
    );
}

export function CoreSingleExternalIdPickerTypeaheadSearch(
    props: ISinglePrincipalPickerTypeaheadSearchProps<string, IPrincipalRecord>,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const resolver = useCallback(
        async (externalId: string) => {
            const principalClient = new CorePrincipalsClient(authContext);
            return (await principalClient.getByField(externalId, null, null)) as IPrincipalRecord;
        },
        [authContext],
    );

    return (
        <CoreSingleIdPickerTypeaheadSearch
            {...props}
            onResolveId={resolver}
            personaItemIdType='externalId'
        />
    );
}

export function CoreSingleOidPickerTypeaheadSearch(
    props: ISinglePrincipalPickerTypeaheadSearchProps<string, IPrincipalRecord>,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const resolver = useCallback(
        async (oid: string) => {
            const principalClient = new CorePrincipalsClient(authContext);
            return (await principalClient.getByField(null, oid, null)) as IPrincipalRecord;
        },
        [authContext],
    );

    return (
        <CoreSingleIdPickerTypeaheadSearch
            {...props}
            onResolveId={resolver}
            personaItemIdType='oid'
        />
    );
}

interface IMultiIdPickerTypeaheadSearchProps<ItemType>
    extends IMultiPrincipalPickerTypeaheadSearchProps<ItemType, IPrincipalRecord> {
    onResolveIds: (items: ItemType[]) => Promise<(IPrincipalRecord | ItemType)[]>;
}

export function CoreMultiIdPickerTypeaheadSearch(
    props: IMultiIdPickerTypeaheadSearchProps<string>,
): JSX.Element {
    const [selectedItems, setSelectedItems] = useState<IPersonaProps[]>();
    const cacheContext = useContext(CacheContext);
    const authContext = useContext(AuthContext);

    const { onResolveIds } = props;

    useEffect(() => {
        (async (): Promise<void> => {
            if (props.selectedItems === undefined) {
                setSelectedItems(undefined);
            } else {
                try {
                    const principalRecords = await onResolveIds(props.selectedItems);
                    await fetchOrUseCachePictures(
                        authContext,
                        principalRecords.filter(
                            (result): result is IPrincipalRecord => result !== null,
                        ),
                        cacheContext,
                    );
                    const personas = principalRecords.map((x) =>
                        typeof x === 'string'
                            ? createUnknownPersona(x)
                            : transformPrincipalRecordToPersona(
                                  x,
                                  props.personaItemIdType,
                                  cacheContext,
                              ),
                    );
                    setSelectedItems(personas);
                } catch (e) {
                    getAppInsights()?.trackException({
                        exception: e,
                        severityLevel: SeverityLevel.Error,
                    });
                    setSelectedItems(props.selectedItems.map(createUnknownPersona));
                }
            }
        })();
    }, [authContext, cacheContext, onResolveIds, props.personaItemIdType, props.selectedItems]);

    const onChange = useCallback(
        (items?: IPersonaProps[]): void => {
            if (props.onChange) {
                const principalIds: string[] = [];
                const principalRecords: IPrincipalRecord[] = [];

                // Ensure that the number of principal ids matches the number of principal records returned
                items?.forEach((persona: IPersonaProps) => {
                    const record = getPrincipalRecordFromPersona(persona);
                    if (record !== undefined && persona.itemID !== undefined) {
                        principalIds.push(persona.itemID);
                        principalRecords.push(record);
                    }
                });

                props.onChange(principalIds, principalRecords);
            }
        },
        [props.onChange],
    );

    return (
        <CoreMultiPrincipalPersonaPickerTypeaheadSearch
            {...props}
            selectedItems={selectedItems}
            onChange={onChange}
        />
    );
}

export function CoreMultiPrincipalIdPickerTypeaheadSearch(
    props: IMultiPrincipalPickerTypeaheadSearchProps<string, IPrincipalRecord>,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const resolver = useCallback(
        async (principalIds: string[]) => {
            const principalClient = new CorePrincipalsClient(authContext);
            return obtainPrincipalRecords({
                principalClient: principalClient,
                principalIds: principalIds,
            });
        },
        [authContext],
    );

    return (
        <CoreMultiIdPickerTypeaheadSearch
            {...props}
            onResolveIds={resolver}
            personaItemIdType='id'
        />
    );
}

export function CoreMultiOidPickerTypeaheadSearch(
    props: IMultiPrincipalPickerTypeaheadSearchProps<string, IPrincipalRecord>,
): JSX.Element {
    const authContext = useContext(AuthContext);

    const resolver = useCallback(
        async (oids: string[]): Promise<(GetPrincipalByFieldResult | string)[]> => {
            const principalClient = new CorePrincipalsClient(authContext);

            const getPersonaPromises = oids.map(async (id) => {
                try {
                    return await principalClient.getByField(null, id, null);
                } catch (e) {
                    return id;
                }
            });

            return await Promise.all(getPersonaPromises);
        },
        [authContext],
    );

    return (
        <CoreMultiIdPickerTypeaheadSearch
            {...props}
            onResolveIds={resolver}
            personaItemIdType='oid'
        />
    );
}

export function CoreMultiOidFromGraphPickerTypeaheadSearch(
    props: IMultiPrincipalPickerTypeaheadSearchProps<string, IGraphPrincipal>,
): JSX.Element {
    const [selectedItems, setSelectedItems] = useState<IPersonaProps[]>();
    const cacheContext = useContext(CacheContext);
    const authContext = useContext(AuthContext);
    const debouncedGetGraphPersonas = useDebounceGetGraphPersonas();

    const { itemLimit = EmployeeListPickerUnlimited } = props;

    useEffect(() => {
        (async (): Promise<void> => {
            if (props.selectedItems === undefined) {
                setSelectedItems(undefined);
            } else {
                try {
                    const resolvedPrincipals = await GraphClient.getGraphPrincipalsByOids(
                        authContext,
                        props.selectedItems,
                    );

                    // For any unresolved principals, create a dummy graph principal
                    let unresolvedPrincipals: IGraphPrincipal[] = [];
                    if (resolvedPrincipals.length !== props.selectedItems.length) {
                        unresolvedPrincipals = props.selectedItems
                            .filter((x) => !resolvedPrincipals.some((p) => p.id === x))
                            .map((id) => ({
                                id: id,
                                displayName: id,
                                servicePrincipalType: 'Unknown',
                            }));
                    }

                    const results = [...resolvedPrincipals, ...unresolvedPrincipals];

                    await fetchOrUseCachePictures(authContext, results, cacheContext);

                    const personas = results.map((x) =>
                        transformGraphPrincipalToPersona(x, cacheContext),
                    );

                    setSelectedItems(personas);
                } catch (e) {
                    getAppInsights()?.trackException({
                        exception: e,
                        severityLevel: SeverityLevel.Error,
                    });
                    setSelectedItems(props.selectedItems.map(createUnknownPersona));
                }
            }
        })();
    }, [authContext, cacheContext, props.selectedItems]);

    const onChange = useCallback(
        (items?: IPersonaProps[]): void => {
            if (props.onChange) {
                const oids: string[] = [];
                const graphPrincipals: IGraphPrincipal[] = [];

                items?.forEach((persona: IPersonaProps) => {
                    const graphPrincipal = getGraphPrincipalFromPersona(persona);
                    if (persona.itemID !== undefined && graphPrincipal !== undefined) {
                        oids.push(persona.itemID);
                        graphPrincipals.push(graphPrincipal);
                    }
                });

                props.onChange(oids, graphPrincipals);
            }
        },
        [props.onChange],
    );

    return (
        <EmployeeListPicker
            label={props.label}
            ariaLabel={props.ariaLabel}
            disabled={props.disabled}
            required={props.required}
            itemLimit={itemLimit}
            placeHolder={props.placeHolder}
            selectedItems={selectedItems}
            onMultipleCandidatesSelected={onChange}
            loadCandidatesOnFilterChange={debouncedGetGraphPersonas}
            styles={{
                textBoxStyle: props.styles?.textBoxStyle,
                inputStyle: props.styles?.inputStyle,
            }}
        />
    );
}
