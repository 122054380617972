import React, { useEffect, useMemo, useState } from 'react';
import {
    IPersonaProps,
    mergeStyleSets,
    IBasePickerSuggestionsProps,
    NormalPeoplePicker,
    ValidationState,
    Label,
    IStyle,
    PeoplePickerItemSuggestion,
    ISuggestionItemProps,
    mergeStyles,
} from '@fluentui/react';
import { REGEX_EMAIL, REGEX_PCN, REGEX_SSN } from 'utils/misc-utils';
import { Dictionary } from 'assets/constants/global-constants';

export const EmployeeListPickerUnlimited = -1;

const REGEX_SEARCH_EMPLOYEE = new RegExp(/^[a-zA-Z0-9-_]+$/);
export interface EmployeeListPickerProps {
    label?: string;
    ariaLabel?: string; // we may want the aria label set but are handling the label ourselves
    disabled?: boolean;
    required?: boolean;
    itemLimit?: number; // Default: 1. Specify EmployeeListPickerUnlimited for unlimited.
    placeHolder?: string;
    selectedItems?: IPersonaProps[];
    selectedPersona?: IPersonaProps;
    personaChoiceList?: IPersonaProps[];
    // If you want to select 0 or 1 person, use the prop onCandidateSelected.
    onCandidateSelected?: (persona?: IPersonaProps) => void;
    // If you want to select 0, 1 or more persons, use the prop onMultipleCandidatesSelected.
    onMultipleCandidatesSelected?: (persona?: IPersonaProps[]) => void; // Use this when itemLimit is -1 or >1
    loadCandidatesOnFilterChange?: (filterText: string) => Promise<IPersonaProps[]>;
    styles?: {
        textBoxStyle?: IStyle;
        inputStyle?: IStyle;
        labelStyle?: IStyle;
    };
}

export const employeePickerSuggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested Employees',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading employees...',
    showRemoveButtons: false,
    suggestionsAvailableAlertText: 'Employees Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested Employees',
};

// This ensures the suggestion personas are taking up the full width of the dropdown
const onRenderSuggestionsItem = (
    personaProps: IPersonaProps,
    suggestionsProps: ISuggestionItemProps<IPersonaProps>,
) => (
    <PeoplePickerItemSuggestion
        personaProps={personaProps}
        suggestionsProps={suggestionsProps}
        styles={{ personaWrapper: { width: '100%' } }}
    />
);

const labelStyle: IStyle = {
    fontWeight: 600,
    marginBottom: 5,
    display: 'inline-block',
};

const requiredStyle = {
    color: 'rgb(164, 38, 44)',
    paddingRight: '12px',
};

export default function EmployeeListPicker(props: EmployeeListPickerProps): JSX.Element {
    const defaultSelectedPersonas = props.selectedPersona ? [props.selectedPersona] : [];
    const [personaChoiceList, setPersonaChoiceList] = useState<IPersonaProps[]>(
        props.personaChoiceList ?? [],
    );

    useEffect(() => {
        if (props.personaChoiceList) {
            setPersonaChoiceList(props.personaChoiceList);
        }
    }, [props.personaChoiceList]);

    const placeholder =
        props.placeHolder && props.selectedPersona === undefined ? props.placeHolder : '';

    // In the following undefined means user can select an unlimited number of persons.
    const itemLimit =
        props.itemLimit === EmployeeListPickerUnlimited ? undefined : props.itemLimit ?? 1;

    const ariaLabel = `${props.ariaLabel ?? props.label ?? 'Employee Picker'}${
        props.required ? ' *' : ''
    }`;

    const textBoxStyle = useMemo(() => {
        return {
            ...(props.styles?.textBoxStyle && typeof props.styles.textBoxStyle === 'object'
                ? props.styles.textBoxStyle
                : {}),
            paddingRight: '21px',
        };
    }, [props.styles?.textBoxStyle]);

    return (
        <div className={style.body}>
            {props.label && (
                <Label className={mergeStyles(labelStyle, props.styles?.labelStyle)}>
                    {props.label}
                    {props.required ? <span style={requiredStyle}> *</span> : ''}
                </Label>
            )}
            {defaultSelectedPersonas && (
                <NormalPeoplePicker
                    aria-label={ariaLabel}
                    disabled={props.disabled}
                    searchingText='Searching Employees'
                    pickerSuggestionsProps={employeePickerSuggestionProps}
                    onValidateInput={validateInput}
                    itemLimit={itemLimit}
                    defaultSelectedItems={defaultSelectedPersonas}
                    onResolveSuggestions={(filterText: string): Promise<IPersonaProps[]> =>
                        onFilterChanged(filterText)
                    }
                    className={'ms-PeoplePicker'}
                    onItemSelected={onItemSelected}
                    onChange={onItemsChange}
                    inputProps={{ placeholder }}
                    selectedItems={props?.selectedItems}
                    removeButtonAriaLabel={'Remove'}
                    styles={{
                        text: textBoxStyle,
                        input: props.styles?.inputStyle,
                    }}
                    onRenderSuggestionsItem={onRenderSuggestionsItem}
                />
            )}
        </div>
    );
    function onItemsChange(items?: IPersonaProps[]): void {
        if (itemLimit === 1) {
            if ((!items || items.length === 0) && props.onCandidateSelected) {
                props.onCandidateSelected(undefined);
            }
        } else if (props.onMultipleCandidatesSelected) {
            props.onMultipleCandidatesSelected(items);
        }
    }

    function onItemSelected(selectedItem?: IPersonaProps): IPersonaProps | null {
        if (selectedItem) {
            if (!!props.onCandidateSelected) {
                props.onCandidateSelected(selectedItem);
            }
            return selectedItem;
        } else {
            return null;
        }
    }

    async function onFilterChanged(filterText: string): Promise<IPersonaProps[]> {
        if (filterText && personaChoiceList) {
            let listToFilter = personaChoiceList;
            if (props.loadCandidatesOnFilterChange) {
                listToFilter = await props.loadCandidatesOnFilterChange(filterText);
                setPersonaChoiceList(listToFilter);
            }
            return filterPersonaList(filterText, listToFilter);
        } else {
            return [];
        }
    }
}

function filterPersonaList(
    filterText: string,
    personaChoiceList: IPersonaProps[],
): IPersonaProps[] {
    try {
        return personaChoiceList.filter((persona: IPersonaProps) => {
            const personaSearchableText = `${persona.text || ''},
            ${persona.secondaryText || ''},
            ${persona.tertiaryText || ''}`;
            // for prehire lookup, it needs to not filter out ssn/email/pcn inputs
            // and since prehire fields are coming back encrypted, just return true
            if (
                REGEX_SSN.test(filterText) ||
                REGEX_EMAIL.test(filterText) ||
                REGEX_PCN.test(filterText)
            )
                return true;
            const filterRegex = new RegExp(filterText, 'i');
            return filterRegex.test(personaSearchableText);
        });
    } catch {
        return [];
    }
}

function validateInput(input: string): ValidationState {
    if (REGEX_SEARCH_EMPLOYEE.test(input)) {
        return ValidationState.valid;
    } else {
        return ValidationState.invalid;
    }
}

const style = mergeStyleSets({
    body: {
        flexGrow: 1,
    },
});

export function distinctPersonaArray(personas: IPersonaProps[] | undefined): IPersonaProps[] {
    if (!personas) {
        return [];
    }
    const result: Dictionary<IPersonaProps> = {};
    personas.forEach((persona) => {
        const employee = JSON.parse(persona.itemProp ?? '{}');
        if (!employee.id) {
            return;
        }
        result[employee.id] = persona;
    });
    return Object.values(result);
}
