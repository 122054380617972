export enum HeaderSize {
    small,
    large,
}

export type HeaderItem = {
    path: string;
    title: string;
    description: string;
    additionalText?: string[];
    size: HeaderSize;
};

export const headerBannerData: HeaderItem[] = [
    {
        path: '/',
        title: 'Reimagine Personnel management with Microsoft',
        description: 'Get started with your secure personnel journey below',
        size: HeaderSize.large,
    },
    {
        path: '/home',
        title: 'Reimagine Personnel management with Microsoft',
        description: 'Get started with your secure personnel journey below',
        size: HeaderSize.large,
    },
    {
        path: '/support',
        title: 'Support',
        description: 'Find the right team or resources for help',
        size: HeaderSize.small,
    },
    {
        path: '/profile/user/me/info',
        title: 'My info',
        description: 'All about you',
        size: HeaderSize.small,
    },
    {
        path: '/profile/user/search',
        title: 'Assignments',
        description: 'Manage workforce access',
        size: HeaderSize.small,
    },
    {
        path: '/profile/visitor',
        title: 'Visitors',
        description: 'Manage visitor access',
        size: HeaderSize.small,
    },
    {
        path: '/profile/us-gov',
        title: 'US Gov',
        description: 'Profile dashboard',
        size: HeaderSize.small,
    },
    {
        path: '/screening/us-gov/my-cleared-info',
        title: 'US Gov Screening',
        description: 'My cleared info',
        size: HeaderSize.small,
    },
    {
        path: '/screening/public-trust/my-cleared-info',
        title: 'Public Trust Screening',
        description: 'My cleared info',
        size: HeaderSize.small,
    },
    {
        path: '/profile/manage/attributes',
        title: 'Manage',
        description: 'Manage attributes and eligibilities',
        size: HeaderSize.small,
    },
    {
        path: '/profile/attributes',
        title: 'Attributes',
        description: 'Manage workforce access',
        size: HeaderSize.small,
    },
    {
        path: '/profile/attributesets',
        title: 'Attribute Sets',
        description: 'Manage workforce access',
        size: HeaderSize.small,
    },
    {
        path: '/profile/attributes/user',
        title: 'User Assignments',
        description: 'Manage workforce access',
        size: HeaderSize.small,
    },
    {
        path: '/screening/cloud',
        title: 'Cloud Screening',
        description: 'Understand your screening lifecycle',
        size: HeaderSize.small,
    },
    {
        path: '/screening/cloud/my-screenings',
        title: 'Cloud Screening',
        description: 'Understand your screening lifecycle',
        size: HeaderSize.small,
    },
    {
        path: '/screening/cloud/my-org',
        title: 'Cloud Screening',
        description: "Understand your team's screening status",
        size: HeaderSize.small,
    },
    {
        path: '/screening/us-gov/my-screenings',
        title: 'US Gov Screening',
        description: 'Understand your screening lifecycle',
        size: HeaderSize.small,
    },
    {
        path: '/screening/us-gov/my-org',
        title: 'US Gov Screening',
        description: "Understand your team's screening status",
        size: HeaderSize.small,
    },
    {
        path: '/screening/us-gov/my-nominees',
        title: 'US Gov Screening',
        description: "Understand your nominee's screening status",
        size: HeaderSize.small,
    },
    {
        path: '/screening/us-gov/clearance-records',
        title: 'US Gov Screening',
        description: 'Clearance records',
        size: HeaderSize.small,
    },
    {
        path: '/screening/us-gov/manage',
        title: 'US Gov Screening',
        description: 'Manage screenings',
        size: HeaderSize.small,
    },
    {
        path: '/screening/public-trust/my-screenings',
        title: 'Public Trust Screening',
        description: 'Understand your screening lifecycle',
        size: HeaderSize.small,
    },
    {
        path: '/screening/public-trust/my-org',
        title: 'Public Trust Screening',
        description: "Understand your team's screening status",
        size: HeaderSize.small,
    },
    {
        path: '/screening/public-trust/my-nominees',
        title: 'Public Trust Screening',
        description: "Understand your nominee's screening status",
        size: HeaderSize.small,
    },
    {
        path: '/screening/public-trust/suitability-records',
        title: 'Public Trust Screening',
        description: 'Suitability Records',
        size: HeaderSize.small,
    },
    {
        path: '/screening/public-trust/manage',
        title: 'Public Trust Screening',
        description: 'Manage screenings',
        size: HeaderSize.small,
    },
    {
        path: '/screening/contracts',
        title: 'Contracts',
        description: 'Find contracts',
        size: HeaderSize.small,
    },
    {
        path: '/facilities',
        title: 'Facility Reservations',
        description: 'Secure your seat',
        size: HeaderSize.small,
    },
    {
        path: '/facilities/reservations/new',
        title: 'Facility Reservations',
        description: 'Secure your seat',
        size: HeaderSize.small,
    },
    {
        path: '/facilities/reservations/my',
        title: 'Facility Reservations',
        description: 'Secure your seat',
        size: HeaderSize.small,
    },
    {
        path: '/emails',
        title: 'Emails',
        description: 'Administer platform communications',
        size: HeaderSize.small,
    },
    {
        path: '/forms/available-forms',
        title: 'Forms',
        description: 'Find, start, and submit a workflow today',
        size: HeaderSize.small,
    },
    {
        path: '/forms/my-forms',
        title: 'Forms',
        description: 'Find, start, and submit a workflow today',
        size: HeaderSize.small,
    },
    {
        path: '/forms/manage',
        title: 'Forms',
        description: 'Find, start, and submit a workflow today',
        size: HeaderSize.small,
    },
    {
        path: '/sca',
        title: 'SCA',
        description: 'Understand your security clearance allowance',
        size: HeaderSize.small,
    },
    {
        path: '/sca/my-history',
        title: 'SCA',
        description: 'Understand your security clearance allowance',
        size: HeaderSize.small,
    },
    {
        path: '/sca/my-org',
        title: 'SCA',
        description: "Understand your team's security clearance allowance",
        size: HeaderSize.small,
    },
    {
        path: '/staffing',
        title: 'Staffing',
        description: 'Understand staffing metrics',
        size: HeaderSize.small,
    },
    {
        path: '/readiness',
        title: 'Readiness',
        description: 'Understand readiness metrics',
        size: HeaderSize.small,
    },
    {
        path: '/groups',
        title: 'Groups',
        description: 'Maintain your group compliance',
        size: HeaderSize.small,
    },
];
