import React, { useContext } from 'react';
import { VisitorsFilterContext } from 'components/visitors/visitors-filter-context';
import { TextField, Separator, Stack, ActionButton, IPersonaProps } from '@fluentui/react';
import { globalFilterSeparatorStyles } from 'assets/styles/global-styles';
import ClearFiltersActionButton from 'components/common/buttons/clear-filters-action-button';
import Spacer from 'components/common/spacer';
import { IconNames } from 'assets/constants/global-constants';
import EmployeePickerTypeaheadSearch from 'components/common/employee-picker-typeahead-search';
import { getEmployeeFromPersona } from 'utils/internal-persona-utils';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { AuthContext } from 'contexts/auth-context';
import { FeatureFlagKeys, useFeatureFlag } from 'utils/use-feature-flags';

interface IVisitorsFiltersProps {
    onSearchClick: () => void;
    onResetTableClick?: () => void;
}

export default function VisitorsFilters(props: IVisitorsFiltersProps): JSX.Element {
    const filterContext = useContext(VisitorsFilterContext);
    const authContext = useContext(AuthContext);
    const isCoreGuestsEnabled = useFeatureFlag(FeatureFlagKeys.guestsCore).enabled;

    const onSponsorSelected = async (info?: IPersonaProps): Promise<void> => {
        const employeeVar = getEmployeeFromPersona(info);
        if (isCoreGuestsEnabled && employeeVar?.id) {
            const client = new CorePrincipalsClient(authContext);
            const response = await client.getByField(employeeVar?.id, null, null);
            filterContext.setItem('sponsor', response);
        } else {
            filterContext.setItem('sponsor', employeeVar);
        }
    };

    return (
        <div>
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Visitor Id</span>
            </Separator>
            <TextField
                value={filterContext.visitorId}
                ariaLabel='Enter Visitor Id'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('visitorId', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>First Name</span>
            </Separator>
            <TextField
                value={filterContext.firstName}
                ariaLabel='Enter First Name'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('firstName', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Middle Name</span>
            </Separator>
            <TextField
                value={filterContext.middleName}
                ariaLabel='Enter Middle Name'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('middleName', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Last Name</span>
            </Separator>
            <TextField
                value={filterContext.lastName}
                ariaLabel='Enter Last Name'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('lastName', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Email</span>
            </Separator>
            <TextField
                value={filterContext.email}
                ariaLabel='Enter Email'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('email', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Company</span>
            </Separator>
            <TextField
                value={filterContext.company}
                ariaLabel='Enter Company'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('company', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Title</span>
            </Separator>
            <TextField
                value={filterContext.title}
                ariaLabel='Enter Title'
                onChange={(e, value?: string): void => {
                    filterContext.setItem('title', value);
                }}
            />
            <Separator styles={globalFilterSeparatorStyles} alignContent='start'>
                <span>Sponsor</span>
            </Separator>
            <EmployeePickerTypeaheadSearch
                ariaLabel='Sponsor'
                placeHolder='Employee Name or Alias'
                onCandidateSelected={onSponsorSelected}
            />
            <Spacer marginTop={10} />
            <Stack horizontal>
                <Stack.Item grow={100}>
                    {!!props.onResetTableClick && (
                        <ClearFiltersActionButton
                            clearFunc={props.onResetTableClick}
                            text={'Reset Table'}
                        />
                    )}
                </Stack.Item>
                <Stack.Item>
                    <ActionButton
                        text='Search'
                        onClick={props.onSearchClick}
                        iconProps={{
                            iconName: IconNames.Search,
                            style: { transform: 'scaleX(-1)' }, // show mirror image of the search icon
                        }}
                    />
                </Stack.Item>
            </Stack>
        </div>
    );
}
