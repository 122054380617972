import { NeutralColors } from '@fluentui/theme';
import React from 'react';

export interface DataFieldPlaceholderProps {
    backgroundColor?: string;
    width?: string;
    height?: string;
}

export default function DataFieldPlaceholder(props: DataFieldPlaceholderProps): JSX.Element {
    const DEFAULT_BACKGROUND_COLOR = NeutralColors.gray20;
    const DEFAULT_WIDTH = '100%';
    const DEFAULT_HEIGHT = '16px';

    return (
        <div
            style={{
                backgroundColor: props.backgroundColor ?? DEFAULT_BACKGROUND_COLOR,
                width: props.width ?? DEFAULT_WIDTH,
                height: props.height ?? DEFAULT_HEIGHT,
            }}></div>
    );
}
