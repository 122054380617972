import { ActionButton, IColumn, mergeStyleSets } from '@fluentui/react';
import React, { useContext, useEffect, useState } from 'react';
import { IEmployeeWithEditableData } from 'clients/employee-client';
import ScaClient, { IScaRecord } from 'clients/sca-client';
import { AuthContext } from 'contexts/auth-context';
import ContainerWithEtiquettes from 'components/common/container-with-etiquettes';
import { EmployeeHoverCard } from 'components/common/employee/employee-hover-card';
import {
    EmployeeNameResolverAlias,
    EmployeeNameResolverId,
} from 'components/common/employee/employee-name-resolver';
import { Table, TableCell } from 'components/common/table';
import {
    firstColumnTableCellPadding,
    firstColumnTitlePadding,
} from 'components/personnel-profile/common/common-constants';
import { getEmployeeReviewStateBackground } from 'components/sca/sca-constants';
import {
    actionButtonStyles,
    commonPersonnelPersonnelStyles,
} from 'components/personnel-profile/common-personnel-profile-styles';
import { isNumeric } from 'utils/string-utils';
import { getDefaultColumnWidths } from 'utils/table-utils';
import { UserContext } from 'contexts/user-context';
import { SCAUserEducationMessage } from 'components/personnel-profile/common/profile-user-education';

interface IScaDisplayData {
    id: string;
    period: string;
    status: string;
    rate: number;
    reviewer: string;
}

export interface PersonnelProfileScaHistoryTableProps {
    employee: IEmployeeWithEditableData | undefined;
    resultSize: number;
    isClearedWorkforceView?: boolean;
}

export default function PersonnelProfileScaHistoryTable(
    props: PersonnelProfileScaHistoryTableProps,
): JSX.Element {
    const authContext = useContext(AuthContext);
    const [scaDisplayData, setScaDisplayData] = useState<IScaDisplayData[]>([]);

    useEffect(() => {
        let isMounted = true;
        async function getScaRecord(personnelId: string | undefined | null): Promise<void> {
            let personnelScaData: IScaRecord[] | undefined = undefined;

            if (personnelId) {
                personnelScaData = await ScaClient.getTopScaReviewsForPersonnelWithSortingCriteria(
                    authContext,
                    personnelId,
                    props.resultSize,
                );
            }

            if (isMounted && personnelScaData) {
                const scaDataList: IScaDisplayData[] = personnelScaData.map((ScaItem) => ({
                    id: ScaItem.id,
                    period: ScaItem.reviewPeriodId,
                    rate: ScaItem.rate,
                    reviewer: ScaItem.reviewedBy
                        ? ScaItem.reviewedBy.replace(/@.*/, '').toLowerCase()
                        : '',
                    status: ScaItem.reviewState,
                }));

                if (scaDataList && scaDataList.length > 0) {
                    setScaDisplayData(scaDataList);
                }
            }
        }
        getScaRecord(props.employee?.data?.id);
        return () => {
            isMounted = false;
        };
    }, [props.employee, props.resultSize]);

    return (
        <>
            {props.employee && (
                <ContainerWithEtiquettes
                    leftEtiquetteLabel='Security Compensation Allowance (SCA) history'
                    bottomLeftInfo={props.isClearedWorkforceView && <SCAUserEducationMessage />}
                    bottomInfo={
                        scaDisplayData.length > 0 && (
                            <ActionButton
                                styles={actionButtonStyles}
                                title='View All SCA Submissions'
                                iconProps={{ iconName: 'Money' }}
                                href={`/sca/employee-history/${props.employee.data?.id}`}>
                                View all
                            </ActionButton>
                        )
                    }>
                    {scaDisplayData.length === 0 ? (
                        <p className={commonPersonnelPersonnelStyles.noDataParagraph}>
                            No SCA history to display
                        </p>
                    ) : (
                        <Table<IScaDisplayData>
                            rows={scaDisplayData}
                            isFetchingData={false}
                            tableColumns={columns}
                            tableName='Security Compensation Allowance Records'
                        />
                    )}
                </ContainerWithEtiquettes>
            )}
        </>
    );
}

const columnWidths = getDefaultColumnWidths(4, false);

const columns: IColumn[] = [
    {
        key: 'Period',
        name: 'Period',
        ariaLabel: 'Period',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        styles: { cellTitle: { paddingLeft: firstColumnTitlePadding } }, // DetailsList applies this to the header cell.
        onRender: (row: IScaDisplayData): JSX.Element => (
            <TableCell style={{ paddingLeft: firstColumnTableCellPadding }}>{row.period}</TableCell>
        ),
    },
    {
        key: 'Status',
        name: 'Status',
        ariaLabel: 'Status',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IScaDisplayData): JSX.Element => (
            <TableCell>
                <span
                    className={styles.status}
                    style={{ backgroundColor: getEmployeeReviewStateBackground(row.status) }}>
                    {row.status.replace('_', ' ')}
                </span>
            </TableCell>
        ),
    },
    {
        key: 'Rate',
        name: 'Rate',
        ariaLabel: 'Rate',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IScaDisplayData): JSX.Element => (
            <TableCell>{`${row.rate.toFixed(2)}%`}</TableCell>
        ),
    },
    {
        key: 'Reviewer',
        name: 'Reviewer',
        ariaLabel: 'Reviewer',
        minWidth: columnWidths.defaultColumnWidth,
        maxWidth: columnWidths.defaultColumnWidth,
        isRowHeader: true,
        onRender: (row: IScaDisplayData): JSX.Element => (
            <TableCell>
                {!isNumeric(row.reviewer) ? (
                    <>
                        <EmployeeHoverCard personnelAlias={row.reviewer}>
                            <span className={styles.strong}>
                                <EmployeeNameResolverAlias alias={row.reviewer} />
                            </span>
                        </EmployeeHoverCard>
                    </>
                ) : (
                    <>
                        <EmployeeHoverCard personnelId={row.reviewer}>
                            <span className={styles.strong}>
                                <EmployeeNameResolverId personnelId={row.reviewer} />
                            </span>
                        </EmployeeHoverCard>
                    </>
                )}
            </TableCell>
        ),
    },
];

const styles = mergeStyleSets({
    strong: {
        fontWeight: '500',
    },
    status: {
        verticalAlign: 'middle',
        display: 'table-cell',
        padding: '2px 7px',
    },
});
