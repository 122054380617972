/* eslint-disable @typescript-eslint/naming-convention */
import {
    DefaultButton,
    Dialog,
    DialogFooter,
    Dropdown,
    IDropdownOption,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    TextField,
} from '@fluentui/react';
import GroupClient, { ICANDAMetadata } from 'clients/group-client';
import { AuthContext } from 'contexts/auth-context';
import DOMPurify from 'dompurify';
import React, { useContext, useEffect, useState } from 'react';
import { IconNames } from 'assets/constants/global-constants';
import { CorePrincipalsClient } from 'clients/core/personnel-core-client-wrappers';
import { PrincipalGetByIdsRequest, PrincipalGetByIdsResult } from 'personnel-core-clients';
import { upperCaseFirstLetter } from 'utils/string-utils';

export enum ApproveOrDenyUarModalMode {
    NoResponse,
    Approve = 'approve',
    ApproveBlocked = 'approveBlocked',
    Deny = 'deny',
    DenyBlocked = 'denyBlocked',
}

const enum JustificationDropdownOptionKeys {
    NoLongerRequired,
    DoesNotMeetRequirements,
    Other,
}

export interface IApproveOrDenyUarModalProps {
    onDismiss: () => void;
    onSuccess: () => void;
    approveOrDenyMode: ApproveOrDenyUarModalMode;
    principalName?: string;
    agreementMetadata: ICANDAMetadata[];
    onError: (approveOrDeny: string) => void;
    show?: boolean;
    principalId: string;
}

export function ApproveOrDenyUarModal(props: IApproveOrDenyUarModalProps): JSX.Element {
    const authContext = useContext(AuthContext);

    const [message, setMessage] = useState<string>(
        `<div>Are you sure you want to deny this UAR for <strong>${props.principalName}?</strong> <br /> This may result in the member's removal from the group.</div>`,
    );
    const [messageType, setMessageType] = useState<MessageBarType>(MessageBarType.success);
    const [showJustificationInput, setShowJustificationInput] = useState<boolean>(false);
    const [justificationMessage, setJustificationMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState(props.show);
    const successIconProps = {
        iconName: IconNames.CompletedSolid,
        styles: { root: { marginTop: `auto`, marginBottom: `auto` } },
    };
    const blockedIconProps = {
        iconName: IconNames.IncidentTriangle,
        styles: { root: { marginTop: `auto`, marginBottom: `auto`, color: 'rgb(164, 38, 44)' } },
    };
    const [uarUsers, setUarUsers] = useState<PrincipalGetByIdsResult[]>([]);

    useEffect(() => {
        const getUarUsers = async () => {
            try {
                const principalsClient = new CorePrincipalsClient(authContext);
                const result = await principalsClient.getByIds({
                    principalIds: props.agreementMetadata.map((uar) => uar.personnelId),
                } as PrincipalGetByIdsRequest);
                setUarUsers(result);
            } catch (e) {
                console.error('Error getting provided user information', e);
                throw 'Error getting provided user information';
            }
        };

        getUarUsers();
    }, [props.agreementMetadata]);

    const justificationDropdownOptions: IDropdownOption[] = [
        {
            key: JustificationDropdownOptionKeys.NoLongerRequired,
            text: 'No longer required for role',
        },
        {
            key: JustificationDropdownOptionKeys.DoesNotMeetRequirements,
            text: 'Member does not meet requirements',
        },
        { key: JustificationDropdownOptionKeys.Other, text: 'Other' },
    ];

    const onClose = (): void => {
        setIsLoading(false);
        props.onDismiss();
        setJustificationMessage('');
    };

    const onSuccess = (): void => {
        setIsLoading(false);
        props.onDismiss();
        setJustificationMessage('');
        props.onSuccess();
    };

    const onSubmit = async (): Promise<void> => {
        if (props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny) {
            try {
                setIsLoading(true);
                const promises = props.agreementMetadata.map((uar) => {
                    return GroupClient.denyAgreement(
                        authContext,
                        uar.groupId,
                        uar.ruleId,
                        justificationMessage,
                        uar.personnelId,
                    );
                });
                await Promise.all(promises);
                onSuccess();
            } catch {
                onClose();
                props.onError('deny');
            }
        } else {
            try {
                setIsLoading(true);
                const promises = props.agreementMetadata.map((uar) => {
                    return GroupClient.signAgreement(
                        authContext,
                        uar.groupId,
                        uar.ruleId,
                        uar.personnelId,
                    );
                });
                await Promise.all(promises);
                onSuccess();
            } catch {
                onClose();
                props.onError('approve');
            }
        }
    };

    const onJustificationDropdownChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption,
    ): void => {
        if (option?.key === JustificationDropdownOptionKeys.Other) {
            setJustificationMessage('');
            setShowJustificationInput(true);
        } else {
            setShowJustificationInput(false);
            setJustificationMessage(option?.text ?? '');
        }
    };

    const getTitleFromMode = (mode: ApproveOrDenyUarModalMode): string => {
        switch (props.approveOrDenyMode) {
            case ApproveOrDenyUarModalMode.DenyBlocked:
            case ApproveOrDenyUarModalMode.Deny:
                return `Deny UAR confirmation`;
            case ApproveOrDenyUarModalMode.ApproveBlocked:
            case ApproveOrDenyUarModalMode.Approve:
                return `Approve UAR confirmation`;
            default:
                return `UAR confirmation`;
        }
    };

    const getApproveDenyMessageBar = (): JSX.Element => {
        return props.principalName ? (
            <MessageBar
                styles={{
                    root: {
                        borderRadius: '4px',
                        border: '1px solid var(--Status-Success-Stroke-1-Rest, #9FD89F)',
                        background: 'var(--Status-Success-Background-1-Rest, #F1FAF1)',
                    },
                    iconContainer: {
                        alignItems: 'center',
                    },
                }}
                messageBarType={messageType}>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
            </MessageBar>
        ) : (
            <MessageBar
                messageBarType={MessageBarType.success}
                messageBarIconProps={successIconProps}
                styles={{
                    root: {
                        borderRadius: '4px',
                        border: '1px solid var(--Status-Success-Stroke-1-Rest, #9FD89F)',
                        background: 'var(--Status-Success-Background-1-Rest, #F1FAF1)',
                    },
                    iconContainer: {
                        alignItems: 'center',
                    },
                }}>
                {`You have ${
                    props.approveOrDenyMode.toString() === 'approve' ? 'approved' : 'denied'
                } the following users:`}
                <ul>
                    {uarUsers.map((uar) => (
                        <li key={uar.id}>
                            <b>{uar.displayName}</b> ({uar.upn})
                        </li>
                    ))}
                </ul>
            </MessageBar>
        );
    };

    const getBlockedMessageBar = (): JSX.Element => {
        return (
            <MessageBar
                messageBarIconProps={blockedIconProps}
                styles={{
                    root: {
                        borderRadius: '4px',
                        border: '1px solid var(--Status-Success-Stroke-1-Rest, #FDCFB4)',
                        background: 'var(--Status-Success-Background-1-Rest, #FFF9F5)',
                    },
                    iconContainer: {
                        alignItems: 'center',
                    },
                }}>
                {`The following users may not be bulked ${
                    props.approveOrDenyMode.toString().includes('approve') ? 'approved' : 'denied'
                }. Please view their individual UAR page for approval.`}
                <ul>
                    {uarUsers.map((uar) => (
                        <li key={uar.id}>
                            <b>{uar.displayName}</b> ({uar.upn})
                        </li>
                    ))}
                </ul>
            </MessageBar>
        );
    };

    return (
        <Dialog
            hidden={!isVisible}
            onDismiss={onClose}
            dialogContentProps={{
                onDismiss: onClose,
                title: getTitleFromMode(props.approveOrDenyMode),
                showCloseButton: true,
            }}
            modalProps={{
                styles: {
                    main: {
                        display: 'flex',
                        flexDirection: 'column',
                        width: '600px !important',
                        maxWidth: '600px !important',
                        borderRadius: '8px',
                        background: 'var(--Light-Background-Background-1, #FFF)',
                        boxShadow:
                            '0px 32px 64px 0px rgba(0, 0, 0, 0.24), 0px 0px 8px 0px rgba(0, 0, 0, 0.20)',
                    },
                },
                className: `${style.dialogTitleStyle}`,
            }}>
            {(props.approveOrDenyMode === ApproveOrDenyUarModalMode.Approve ||
                props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny) &&
                getApproveDenyMessageBar()}
            {!(
                props.approveOrDenyMode === ApproveOrDenyUarModalMode.Approve ||
                props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny
            ) && getBlockedMessageBar()}
            {(props.approveOrDenyMode === ApproveOrDenyUarModalMode.Approve ||
                props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny) &&
                props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny && (
                    <Dropdown
                        className={style.dropdownStyle}
                        styles={{
                            dropdownOptionText: {
                                color: 'rgba(66, 66, 66, 1)',
                            },
                            dropdownItem: {
                                paddingLeft: '10px',
                            },
                            title: {
                                borderRadius: '4px',
                                border: '1px solid rgba(209, 209, 209, 1)',
                            },
                            dropdown: {
                                marginTop: '8px',
                            },
                        }}
                        placeholder='Select justification'
                        options={justificationDropdownOptions}
                        onChange={onJustificationDropdownChange}
                    />
                )}
            {(props.approveOrDenyMode === ApproveOrDenyUarModalMode.Approve ||
                props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny) &&
                showJustificationInput && (
                    <>
                        <TextField
                            styles={{
                                root: { marginTop: '8px' },
                                fieldGroup: {
                                    border: '1px solid rgba(209, 209, 209, 1)',
                                    borderRadius: '4px',
                                },
                            }}
                            type='text'
                            onChange={(ev, val): void => {
                                setJustificationMessage(val!);
                            }}
                            placeholder='Add justification'
                        />

                        <p style={{ marginTop: '10px' }}>
                            Are you sure you want to {props.approveOrDenyMode.toString()}?
                        </p>
                    </>
                )}
            <DialogFooter
                styles={{
                    actions: {
                        marginTop: '8px',
                    },
                }}>
                {(props.approveOrDenyMode === ApproveOrDenyUarModalMode.Approve ||
                    props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny) && (
                    <PrimaryButton
                        disabled={
                            props.approveOrDenyMode === ApproveOrDenyUarModalMode.Deny &&
                            (justificationMessage === '' || isLoading)
                        }
                        text={upperCaseFirstLetter(props.approveOrDenyMode.toString())}
                        styles={{
                            root: {
                                height: '32px',
                                width: '60px',
                                minWidth: '0px',
                                borderRadius: '4px',
                            },
                        }}
                        onClick={onSubmit}
                    />
                )}
                <DefaultButton
                    text='Cancel'
                    styles={{
                        root: {
                            height: '32px',
                            width: '66px',
                            minWidth: '0px',
                            borderRadius: '4px',
                            border: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                            background: '1px solid var(--Light-Stroke-Stroke-1, #D1D1D1)',
                        },
                    }}
                    onClick={onClose}
                />
            </DialogFooter>
        </Dialog>
    );
}

const style = mergeStyleSets({
    dropdownStyle: {
        '.ms-Callout-main': {
            borderRadius: '4px !important',
        },
    },
    dialogTitleStyle: {
        '.ms-Dialog-title': {
            padding: '24px 46px 8px 24px',
        },
    },
});
